import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend, ChartDataLabels);

// ** Reactstrap Imports
import {
    Row,
    Col,
    CardTitle,
    CardText,
    Form,
    Label,
    Input,
    Button
} from "reactstrap"
import { Circle, CheckCircle } from 'react-feather'
import { Toaster, toast } from 'react-hot-toast';
import PieChart from '../CustomChart/PieChart'
import PieChartLegends from '../CustomChart/PieChartLegends'
// Utility-Service
import { getToken, getUser } from '../../../utility/AuthService'
//API
import { dimensionListService } from '../../../services/dimensionService';
import { getOverallDimensionData } from '../../../services/analyticsServices/overAllDimensionData';
//Redux
import { useSelector } from 'react-redux';
import { selectLanguageUse } from '../../../redux/slices/language.Slice'


const Analytics = (props) => {
    const token = getToken()
    const user = getUser()
    const [isLoading, setLoading] = useState(false)
    const [parcentOrDigit, setParcentOrDigit] = useState('digit')
    const [environmentData, setEnvironmentData] = useState([])
    const [environmentCharData, setEnvironmentChartData] = useState()
    const [environmentParcentVal, setEnvironmentParcentVal] = useState()
    const [socialData, setSocialData] = useState([])
    const [socialtParcentVal, setSocialParcentVal] = useState()
    const [governanceData, setGovernanceData] = useState([])
    const [governancetParcentVal, setGovernanceParcentVal] = useState()
    const languageUse = useSelector(selectLanguageUse || user?.language)


    const dimensionLabelList = [

        {
            value: 'Advanced',
            color: '#69BA6C'
        },
        {
            value: 'Beginner',
            color: '#FBC04F'
        },
        {
            value: 'Intermediate',
            color: '#ED61AE'
        },
        {
            value: 'Laggard',
            color: '#62AADF'
        },
        {
            value: 'Leader',
            color: '#EC6258'
        },

    ];

    const dimensionChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let percentage = 0
                    percentage = parcentOrDigit === 'digit' ? `${value}` : `${value}%`;
                    // percentage = `${ctx.chart.data.labels[ctx.dataIndex]}\n${value}%`;
                    return percentage;
                },
                color: 'white',
                font: {
                    weight: 'bold',
                    size: 12
                }
            }
        }
    }


    /**
     * @function handleParcent
     * @param ()
     * @description to show data in parcent
     */
    const handleParcent = async () => {
        if (environmentData && socialData && governanceData) {
            setParcentOrDigit('parcent')
            const sumEnv = environmentData.reduce(function (a, b) {
                return a + b;
            });
            const parcentValEnv = environmentData.map(function (a) {
                return Math.floor((a / sumEnv) * 100);
            });
            setEnvironmentParcentVal(parcentValEnv)

            const sumSocial = socialData.reduce(function (a, b) {
                return a + b;
            });
            const parcentValSocial = socialData.map(function (a) {
                return Math.floor((a / sumSocial) * 100);
            });
            setSocialParcentVal(parcentValSocial)

            const sumGovernance = governanceData.reduce(function (a, b) {
                return a + b;
            });
            const parcentValGovernance = governanceData.map(function (a) {
                return Math.floor((a / sumGovernance) * 100);
            });
            setGovernanceParcentVal(parcentValGovernance)
        }
    }

    /**
     * @function handleDigit
     * @param ()
     * @description to show data in digit
     */
    const handleDigit = async () => {
        setParcentOrDigit('digit')
    }

    /**
     * @function getEnvironmentDataList
     * @param ()
     * @description to handle get environment data List
     */
    const getEnvironmentDataList = async () => {
        let result = []
        try {
            result = await getOverallDimensionData({
                authToken: token,
                id: props?.dimensionList?.find(item => item?.name === 'Environment')?._id,
                userId: user?._id
            });

            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setEnvironmentData(dimensionLabelList.map(item => result?.data?.data[item.value]))
            } else {
                setLoading(false);
                setEnvironmentData([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false);
            setEnvironmentData([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    /**
     * @function getSocialDataList
     * @param ()
     * @description to handle get social data List
     */
    const getSocialDataList = async () => {
        let result = []
        try {
            result = await getOverallDimensionData({
                authToken: token,
                id: props?.dimensionList?.find(item => item?.name === 'Social')?._id,
                userId: user?._id
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setSocialData(dimensionLabelList.map(item => result?.data?.data[item.value]))
            } else {
                setLoading(false);
                setSocialData([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false);
            setSocialData([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    /**
     * @function getGovernanceDataList
     * @param ()
     * @description to handle get social data List
     */
    const getGovernanceDataList = async () => {
        let result = []
        try {
            result = await getOverallDimensionData({
                authToken: token,
                id: props?.dimensionList?.find(item => item?.name === 'Governance')?._id,
                userId: user?._id
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setGovernanceData(dimensionLabelList.map(item => result?.data?.data[item.value]))
            } else {
                setLoading(false);
                setGovernanceData([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false);
            setGovernanceData([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    useEffect(() => {
        if (props?.dimensionList?.length > 0) {
            getEnvironmentDataList()
            getSocialDataList()
            getGovernanceDataList()
        }
    }, [props?.dimensionList]);

    const EnvironmentDimensionData = {
        labels: dimensionLabelList.map(item => item.value),
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? environmentData : environmentParcentVal,
                backgroundColor: dimensionLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    const SocialDimensionData = {
        labels: dimensionLabelList.map(item => item.value),
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? socialData : socialtParcentVal,
                backgroundColor: dimensionLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    const GovernanceDimensionData = {
        labels: dimensionLabelList.map(item => item.value),
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? governanceData : governancetParcentVal,
                backgroundColor: dimensionLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    const testData = {
        labels: dimensionLabelList.map(item => item.value),
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? governanceData : governancetParcentVal,
                backgroundColor: dimensionLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    return (
        <>
            {/* <div>
                <Toaster />
            </div> */}
            <div className='d-flex justify-content-between align-items-center py-2 flex-column flex-md-row'>
                <h4 className=''>Level of Dimension</h4>
                {/* <div className='d-flex align-items-start rounded-pill bg-btn-analytics px-1'>
                    <Button className={parcentOrDigit === 'parcent' ? "float-start analyticsConvertButtonClicked login-btn-min-width mb-4" : "float-start analyticsConvertButton login-btn-min-width mb-4"} variant='outline-primary' onClick={handleParcent}>
                        {isLoading ? <Spinner variant='dark' size='sm' /> : 'Percentage'}
                    </Button>
                    
                    <Button className={parcentOrDigit === 'digit' ? "float-start analyticsConvertButtonClicked login-btn-min-width mb-4" : "float-start analyticsConvertButton login-btn-min-width mb-4"} variant='outline-primary' onClick={handleDigit}>
                        {isLoading ? <Spinner variant='dark' size='sm' /> : 'Digit number'}
                    </Button>
                </div> */}

                <div className='d-flex align-items-center rounded-pill bg-btn-analytics px-2 py-2'>
                    <Button color='' className={parcentOrDigit === 'parcent' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleParcent}>
                        Percentage
                    </Button>
                    <Button color='' className={parcentOrDigit === 'digit' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleDigit}>
                        Digit number
                    </Button>
                </div>

            </div>

            <Row className='border p-2 mb-5'>
                {environmentData && socialData && governanceData ? <Row className='d-flex flex-row justify-content-between px-5 py-2'>
                    <Col md={3} sm={12} className='d-flex flex-column align-items-center'>
                        <h5 className='mb-2'>{languageUse === 'English' ? props?.dimensionList[1]?.name : props?.dimensionList[1]?.name_Malay}</h5>
                        {environmentData.every(item => item === 0) ? <>
                            <p className='my-5 text-danger'>No Data available</p>
                        </> : <>
                            <div className='d-flex flex-column  align-items-center'>
                                <PieChart options={dimensionChartOptions} data={EnvironmentDimensionData} width={220} height={220} />
                            </div>
                        </>}
                    </Col>
                    <Col md={3} sm={12} className='d-flex flex-column align-items-center'>
                        <h5 className='mb-2'>{languageUse === 'English' ? props?.dimensionList[2]?.name : props?.dimensionList[2]?.name_Malay}</h5>
                        {socialData.every(item => item === 0) ? <>
                            <p className='my-5 text-danger'>No Data available</p>
                        </> : <>
                            <div className='d-flex flex-column  align-items-center'>
                                <PieChart options={dimensionChartOptions} data={SocialDimensionData} width={220} height={220} />
                            </div>
                        </>}
                    </Col>
                    <Col md={3} sm={12} className='d-flex flex-column align-items-center'>
                        <h5 className='mb-2'>{languageUse === 'English' ? props?.dimensionList[3]?.name : props?.dimensionList[3]?.name_Malay}</h5>
                        {governanceData.every(item => item === 0) ? <>
                            <p className='my-5 text-danger'>No Data available</p>
                        </> : <>
                            <div className='d-flex flex-column  align-items-center'>
                                <PieChart options={dimensionChartOptions} data={GovernanceDimensionData} width={220} height={220} />
                            </div>
                        </>}
                    </Col>
                </Row> : <div className='my-5 text-center text-primary fw-bold'>
                    <p>Loading...</p>
                </div>}
                <div>
                    <PieChartLegends />
                </div>

            </Row>
        </>
    )
};

export default Analytics