import axios from 'axios'
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function getUnderTakingService
* @param { token, id }
* @description This function is used to get under taking data
*/
export const getUnderTakingService = async ({ token, id }) => {
    const result = axios.get(`${baseUrl}users/get/undertaking/actions?id=${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    return result;
};
