import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function uploadProfileImageService
* @param { authToken, file }
* @description This function is used to upload a profile image
*/
export const uploadProfileImageService = async ({ authToken, file, id }) => {
    const result = axios.patch(`${baseUrl}users/upload/${id}`,
        file, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data'
        }
    })
    return result;
};