
import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function getIndustry
    * @param ({ filter, offset })
    * @description api to get industry list
*/
export const getIndustry = async ({ filter, offset }) => {
    const result = await axios.get(`${baseUrl}industry?offset=${offset}&filter=${filter}`)
    return result;
}

/**
    * @function getAllIndustryListService
    * @param ({ countPerPage, page, authToken })
    * @description call API to get the dimension list
*/
export const getAllIndustryListService = async ({ authToken }) => {
    const result = await axios.get(`${baseUrl}industry?filter=Active&getAll=true`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}