import { useFieldArray } from 'react-hook-form';
import { AiOutlinePlus } from "react-icons/ai";
import { GrDocumentPdf } from "react-icons/gr";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// Redux-Functions
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// Utility-Service
import { getUser } from "../../../utility/AuthService";

const SingleUploadDocumentCard = ({ nestIndex, allWatchedValues, control, setValue, errors }) => {
    const user = getUser()
    const languageUse = useSelector(selectLanguageUse || user?.language)
    
    const { fields, remove, append } = useFieldArray({
        control,
        name: `inputs[${nestIndex}].uploadPDF`
    });

    /**
        * @function PDFup
        * @param e, index, status
        * @description append multiple pdf
     */
    const PDFup = (e, index, status) => {
        setValue(`inputs[${nestIndex}].uploadPDF[${index}].files`, e?.target?.files)
        if (index < 3 && status === 'add') {
            append({ files: null })
        }
    }

    /**
        * @function handleRemoveField
        * @param (index)
        * @description remove pdf
    */
    const handleRemoveField = (index) => {
        remove(index)
        if (allWatchedValues?.inputs[nestIndex]?.uploadPDF?.length === 0) {
            append({ files: null })
        }
    }

    return <div className="mb-3">
        <p className="">
            {languageUse === 'English' ? 'Documents (PDF)' : 'Dokumen (PDF)'}
        </p>
        <div className='d-flex flex-wrap align-items-center'>
            {fields?.length > 0 && fields.map((item, index) => {
                return <div className="position-relative">
                    {allWatchedValues?.inputs[nestIndex]?.uploadPDF[index]?.files?.length > 0 && <div role='button' className='position-absolute top-0 end-0 pdfCross me-1' onClick={() => handleRemoveField(index)}>
                        <RxCrossCircled size={15} />
                    </div>}
                    <div className={classNames('ms-2 mb-3')}>
                        <label htmlFor={`uploadPDF[${nestIndex}][${index}]`} className="p-3 addPdf rounded" >
                            {allWatchedValues?.inputs[nestIndex]?.uploadPDF[index]?.files?.length > 0 ? <GrDocumentPdf size={65} /> : <div role='button'>
                                <AiOutlinePlus className='ms-4' />
                                <p>{languageUse === 'English' ? 'Add More' : 'Tambah Lagi'}</p>
                            </div>}
                        </label>
                        <input
                            id={`uploadPDF[${nestIndex}][${index}]`}
                            type="file"
                            accept="application/pdf"
                            className={`${errors.uploadPDF ? 'is-invalid' : 'd-none'}`}
                            name={`inputs[${nestIndex}].uploadPDF[${index}].files`}
                            onChange={(e) => {
                                if (allWatchedValues?.inputs[nestIndex]?.uploadPDF[index]?.files) {
                                    PDFup(e, index, 'edit')
                                } else {
                                    PDFup(e, index, 'add')
                                }
                            }}
                        />
                    </div>
                    {allWatchedValues?.inputs[nestIndex]?.uploadPDF?.length === 0 && <p className='pt-1 text-danger'>{languageUse === 'English' ? 'Atleast one pdf is required' : 'Sekurang-kurangnya satu pdf diperlukan'}</p>}
                </div>
            })}
        </div>
    </div>
};

export default SingleUploadDocumentCard