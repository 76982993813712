import React, { useEffect, useState, forwardRef, useRef } from 'react'
// ** Reactstrap Imports
import {
    Row,
    Col,
    Card,
    Input,
    Label,
    Button,
    CardTitle,
    CardHeader,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Toast,
    Spinner,
    Badge,
    UncontrolledButtonDropdown
} from 'reactstrap'

import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import classNames from 'classnames';
import moment from 'moment';

const CustomDatePicker = (props) => {
    const ref = useRef(null);

    /**
    * @function onChange
    * @param ()
    * @description to set chagne date
    */
    const onChange = (dates) => {
        const [start, end] = dates;
        props?.setStartDate(start);
        props?.setEndDate(end);
    };

    /**
    * @function clearSelectedDate
    * @param ()
    * @description to clear selected date
    */
    const clearSelectedDate = () => {
        props?.setStartDate(null);
        props?.setEndDate(null);
    };

    const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className={classNames('bg-btn-analytics py-1 rounded-2', { "ps-1 pe-4": value, " px-1": !value })}>
            <AiOutlineCalendar role='button' size={20} className="text-primary" onClick={onClick} ref={ref} />
            <button className={classNames("bg-btn-analytics text-primary p-0", { "p-1": value })} onClick={onClick} ref={ref}>
                {value}
            </button>

        </div>

    ));

    const FooterCustomInput = ({ date, value, onChange }) => (
        <div className='d-flex justify-content-between'>

            <div className='py-2'>
                {props?.startDate && <span className='p-2 border rounded-2'>{moment(props?.startDate).format("MMM DD, yyyy")}</span>} - {props?.endDate && <span className='p-2 border rounded-2'>{moment(props?.endDate).format("MMM DD, yyyy")}</span>}
            </div>
            <div className='d-flex'>
                <Button className='me-1' color="danger" onClick={() => clearSelectedDate()}>Clear</Button>
                <Button className='me-1' outline color="primary" onClick={() => {
                    ref.current?.setOpen(false);
                }}>
                    Cancel
                </Button>
                <Button className='' color="primary" disabled={!(props?.startDate && props?.endDate)} onClick={() => {
                    ref.current?.setOpen(false);
                }}>
                    Apply
                </Button>
            </div>
        </div>
    );

    return (
        <>
            <DatePicker
                ref={ref}
                selected={props?.startDate}
                onChange={(date) => onChange(date)}
                startDate={props?.startDate}
                endDate={props?.endDate}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                selectsRange
                monthsShown={2}
                withPortal
                showTimeInput
                customInput={<DateCustomInput />}
                customTimeInput={<FooterCustomInput />}
                isClearable
            />
        </>
    )
};

export default CustomDatePicker