import { useState, useEffect } from 'react'
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, RadialLinearScale, PointElement, LineElement, Filler } from "chart.js";
import toast from 'react-hot-toast'
import { Card, Button, CardTitle, CardHeader, Spinner } from 'reactstrap';
import moment from 'moment';
import classNames from 'classnames';
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels, RadialLinearScale, PointElement, LineElement, Filler);

import AvgClimateActionMaturity from './AvgClimateActionMaturity';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import Selection from '../../Selection/Selection';
import { getToken } from '../../../../utility/AuthService';
import { getAllIndustryListService } from '../../../../services/industryService';
import { demographicsListService } from '../../../../services/demographicsService';
import { filterProgressMaturityIndustryAndStateService, getAdvanceProgressMaturityDataService } from '../../../../services/analyticsServices/advanceAnalyticsService/advanceAnalyticsService';
import { useForm } from 'react-hook-form';

const Multilevel = () => {
    const token = getToken()
    const [isLoading, setLoading] = useState(false)
    const [countryName, setCountryName] = useState()
    const [stateName, setStateName] = useState()
    const [industryName, setIndustryName] = useState()
    const [companySizeName, setCompanySizeName] = useState()
    const [allIndustryList, setAllIndustryList] = useState([])
    const [charLabel, setCharLabel] = useState()

    const [advanceData, setadvanceData] = useState()
    const [LeaderData, setLeaderData] = useState()
    const [LaggardData, setLaggardData] = useState()
    const [IntermediateData, setIntermediateData] = useState()
    const [BeginnerData, setBeginnerData] = useState()

    const [advanceToParData, setadvanceToParData] = useState()
    const [LeaderToParData, setLeaderToParData] = useState()
    const [LaggardToParData, setLaggardToParData] = useState()
    const [IntermediateToParData, setIntermediateToParData] = useState()
    const [BeginnerToParData, setBeginnerToParData] = useState()

    const [parcentOrDigit, setParcentOrDigit] = useState('digit')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);

    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        setValue
    } = useForm();

    const watchAll = watch()
    const watchCountry = watch("Country")
    const watchState = watch("State")

    const companySizeList = [
        {
            value: "Less than RM 300,000",
            label: "Less than RM 300,000"
        },
        {
            value: "RM 300,000 - 3 mil",
            label: "RM 300,000 - 3 mil"
        },
        {
            value: "RM 3 mil - 20 mil",
            label: "RM 3 mil - 20 mil"
        },
        {
            value: "RM 20 mil - 50 mil",
            label: "RM 20 mil - 50 mil"
        },
        {
            value: "RM 50 mil - 100 mil",
            label: "RM 50 mil - 100 mil"
        },
        {
            value: "RM 100 mil +",
            label: "RM 100 mil +"
        }
    ]

    /**
        * @function getCountryList
        * @param
        * @description get country list using api
    */
    const getCountryList = async () => {
        try {
            const result = await demographicsListService({
                type: 'Country',
                typeId: ""
            });
            if (result?.data?.statusCode === 200) {
                setCountryList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setCountryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function getStateList
        * @param
        * @description get state list using api
    */
    const getStateList = async () => {
        try {
            const result = await demographicsListService({
                type: 'State',
                typeId: watchCountry?.value
            });
            if (result?.data?.statusCode === 200) {
                setStateList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setStateList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getCountryList()
    }, []);

    useEffect(() => {
        setStateName()
        setValue("State", null)
        if (watchCountry?.value) {
            getStateList()
        }
    }, [watchCountry]);

    /**
        * @function fetchIndustryData
        * @param
        * @description to get all indistry list
    */
    const fetchIndustryData = async () => {
        try {
            const result = await getAllIndustryListService({
                token,
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setAllIndustryList(result?.data?.data?.list?.map((item) => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            } else {
                setLoading(false)
                setAllIndustryList([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }

        } catch (error) {
            setLoading(false)
            setAllIndustryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    /**
        * @function getAdvanceMaturityData
        * @param ()
        * @description to get chart data
    */
    const getAdvanceMaturityData = async () => {
        try {
            setLoading(true)
            const result = await getAdvanceProgressMaturityDataService({
                token,
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setCharLabel(Object.keys(result?.data?.data?.dimensionLevelCounts))
                setadvanceData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Advanced))
                setBeginnerData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Beginner))
                setIntermediateData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Intermediate))
                setLaggardData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Laggard))
                setLeaderData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Leader))

                setadvanceToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Advanced))
                setLeaderToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Leader))
                setLaggardToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Laggard))
                setIntermediateToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Intermediate))
                setBeginnerToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Beginner))
            } else {
                setLoading(false)
                setCharLabel()
                setadvanceData()
                setBeginnerData()
                setIntermediateData()
                setLaggardData()
                setLeaderData()
                setadvanceToParData()
                setLeaderToParData()
                setLaggardToParData()
                setIntermediateToParData()
                setBeginnerToParData()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setCharLabel()
            setadvanceData()
            setBeginnerData()
            setIntermediateData()
            setLaggardData()
            setLeaderData()
            setadvanceToParData()
            setLeaderToParData()
            setLaggardToParData()
            setIntermediateToParData()
            setBeginnerToParData()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        fetchIndustryData()
    }, []);

    /**
        * @function filterStateAndIndustryMaturityData
        * @param
        * @description to get filter data
    */
    const filterStateAndIndustryMaturityData = async () => {
        try {
            setLoading(true)
            const result = await filterProgressMaturityIndustryAndStateService({
                token,
                country: watchCountry?.label || '',
                state: watchState?.label || '',
                industry: industryName?.value || '',
                companySize: companySizeName?.value || '',
                fromDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
                toDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setCharLabel(Object.keys(result?.data?.data?.dimensionLevelCounts))
                setadvanceData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Advanced))
                setLeaderData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Leader))
                setLaggardData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Laggard))
                setBeginnerData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Beginner))
                setIntermediateData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Intermediate))

                setadvanceToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Advanced))
                setLeaderToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Leader))
                setLaggardToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Laggard))
                setIntermediateToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Intermediate))
                setBeginnerToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Beginner))
            } else {
                setLoading(false)
                setCharLabel()
                setadvanceData()
                setBeginnerData()
                setIntermediateData()
                setLaggardData()
                setLeaderData()
                setadvanceToParData()
                setLeaderToParData()
                setLaggardToParData()
                setIntermediateToParData()
                setBeginnerToParData()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setCharLabel()
            setadvanceData()
            setBeginnerData()
            setIntermediateData()
            setLaggardData()
            setLeaderData()
            setadvanceToParData()
            setLeaderToParData()
            setLaggardToParData()
            setIntermediateToParData()
            setBeginnerToParData()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        if (watchCountry || watchState || industryName || companySizeName || startDate || endDate) {
            filterStateAndIndustryMaturityData()
        } else {
            getAdvanceMaturityData()
        }
    }, [watchCountry, watchState, industryName, companySizeName, startDate, endDate]);

    /**
        * @function handleDigit
        * @param
        * @description to show data in digit
    */
    const handleDigit = async () => {
        setParcentOrDigit('digit')
    };

    /**
   * @function handleParcent
   * @param ()
   * @description to show data in parcent
   */
    const handleParcent = async () => {
        setParcentOrDigit('parcent')
    };

    const horizontalStackData = {
        labels: charLabel,
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? LeaderData : LeaderToParData,
                backgroundColor: "#38761D",
                hoverBackgroundColor: "#38761D",
                label: ''
            }, {
                data: parcentOrDigit === 'digit' ? advanceData : advanceToParData,
                backgroundColor: "#93C47D",
                hoverBackgroundColor: "#93C47D",
                label: ''
            }, {
                data: parcentOrDigit === 'digit' ? IntermediateData : IntermediateToParData,
                backgroundColor: "#B6D7A8",
                hoverBackgroundColor: "#B6D7A8",
                label: ''
            }, {
                data: parcentOrDigit === 'digit' ? BeginnerData : BeginnerToParData,
                backgroundColor: "#FF9900",
                hoverBackgroundColor: "#FF9900",
                label: ''
            }, {
                data: parcentOrDigit === 'digit' ? LaggardData : LaggardToParData,
                backgroundColor: "#FF0000",
                hoverBackgroundColor: "#FF0000",
                label: ''
            }
        ]
    };

    const horizontalStackOptions = {
        responsive: true,
        indexAxis: 'y',
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    display: false,
                    precision: 0
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            xAxes: [
                {
                    scaleLabel: {
                        display: false
                    },
                    gridLines: {
                        display: false

                    },
                    stacked: true
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    stacked: true
                }
            ]
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                color: "#000",
                formatter: function (value, context) {
                    return parcentOrDigit === 'digit' ? value : `${value.toFixed(2)}%`;
                }
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItem, data) => {
                        if (tooltipItem[0]?.datasetIndex === 0) {
                            return 'Leader'
                        }
                        if (tooltipItem[0]?.datasetIndex === 1) {
                            return 'Advanced'
                        }
                        if (tooltipItem[0]?.datasetIndex === 2) {
                            return 'Intermediate'
                        }
                        if (tooltipItem[0]?.datasetIndex === 3) {
                            return 'Beginner'
                        }
                        if (tooltipItem[0]?.datasetIndex === 4) {
                            return 'Laggard'
                        }
                    }
                }
            }
        },

        pointLabelFontFamily: "Quadon Extra Bold",
        scaleFontFamily: "Quadon Extra Bold",
    };

    return (
        <>
            <Card className="border-0">
                <div className='bg-white px-2 mt-3'>
                    <h3 className='mt-1 px-2'><strong>Number & Percentage of Companies Corresponding to Each Maturity Level</strong></h3>
                    <div className='px-2 py-1'>
                        <div className='d-flex flex-md-wrap flex-column flex-md-row justify-content-between align-items-center'>
                            <div className=''>
                                <Selection className={'w-100'} options={countryList} title={'Country'} name={'Country'} setWatchValue={setCountryName} control={control} styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '250px'
                                    }),
                                }} />
                            </div>
                            {(countryName?.value || watchCountry?.value) &&
                                <div className=''>
                                    <Selection className={'w-100'} options={stateList} title={'State'} name={'State'} setWatchValue={setStateName} control={control} styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: '250px'
                                        }),
                                    }} />
                                </div>
                            }
                            <div className=''>
                                <Selection className={'w-100'} options={allIndustryList} title={'Industry'} name={'Industry'} setWatchValue={setIndustryName} styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '250px'
                                    }),
                                }} />
                            </div>
                            <div className=''>
                                <Selection className={'w-100'} options={companySizeList} title={'Company Size'} name={'CompanySize'} setWatchValue={setCompanySizeName} styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '250px'
                                    }),
                                }} />
                            </div>

                            <div className='d-flex flex-column flex-md-row align-items-center'>
                                <div className='me-2 mb-2 mb-md-0'>
                                    <CustomDatePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                                </div>

                                <div className='d-flex align-items-center rounded-pill bg-btn-analytics px-1 py-1'>
                                    <Button color='' className={parcentOrDigit === 'parcent' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleParcent}>
                                        Percentage
                                    </Button>
                                    <Button color='' className={parcentOrDigit === 'digit' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleDigit}>
                                        Digit number
                                    </Button>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div>
                        <div className='d-flex flex-sm-row flex-column align-items-center mb-2'>
                            <div className='pieLegends rounded-circle ms-2 me-1'
                                style={{
                                    backgroundColor: '#38761D'
                                }}>
                            </div>
                            <span>Leader</span>

                            <div className='pieLegends rounded-circle ms-2 me-1'
                                style={{
                                    backgroundColor: '#93C47D'
                                }}>
                            </div>
                            <span>Advanced</span>

                            <div className='pieLegends rounded-circle ms-2 me-1'
                                style={{
                                    backgroundColor: '#B6D7A8'
                                }}>
                            </div>
                            <span>Intermediate</span>

                            <div className='pieLegends rounded-circle ms-2 me-1'
                                style={{
                                    backgroundColor: '#FF9900'
                                }}>
                            </div>
                            <span>Beginner</span>

                            <div className='pieLegends rounded-circle ms-2 me-1'
                                style={{
                                    backgroundColor: '#FF0000'
                                }}>
                            </div>
                            <span>Laggard</span>
                        </div>
                    </div>
                    {isLoading && <div className='text-center h-100'><Spinner></Spinner></div>}
                    {!isLoading && <>
                        <div className='mb-3 p-1'>
                            <Bar data={horizontalStackData} options={horizontalStackOptions}></Bar>
                        </div>
                    </>}
                </div>
            </Card>
        </>
    )
};

export default Multilevel