import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

// Redux-Functions
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// Utility Service
import { getToken, getUser } from "../../../utility/AuthService";

// API service
import { endSubmissionService, endProgressSubmissionService } from '../../../services/submissionService';

const SubmissionEndModal = (props) => {
    const authToken = getToken()
    const location = useLocation()
    const navigate = useNavigate()

    const [confirmation, setConfirmation] = useState(false)
    const Separation_Url = process.env.REACT_APP_SEPARATION

    const user = getUser()
    const languageUse = useSelector(selectLanguageUse || user?.language)

    /**
        * @function endSubmission
        * @param
        * @description handle api to End the assesment  
    */
    const endSubmission = async () => {
        try {
            if (location?.pathname?.includes('uploadProgressDocuments') || location?.pathname?.includes('progress')) {
                const result = await endProgressSubmissionService({
                    authToken,
                    SubmissionId: props.submission_id
                })
                const encryptedSubId = CryptoJS.AES.encrypt(props.submission_id, 'mySecretKey').toString();
                toast.success(result?.data?.data?.message)
                props.handleClose()
                navigate(`/user/progress/dashboard/${encodeURIComponent(encryptedSubId)}`)
            } else {
                const result = await endSubmissionService({
                    authToken,
                    SubmissionId: props.submission_id
                });
                const encryptedSubId = CryptoJS.AES.encrypt(props.submission_id, 'mySecretKey').toString();
                toast.success(result?.data?.data?.message)
                props.handleClose()
                navigate(`/user/dashboard/${encodeURIComponent(encryptedSubId)}`)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    return <>
        <div>
            <Toaster />
        </div>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton />
            {/* on ducuments submission page */}
            {!props?.questionPage && !confirmation && <Modal.Body>
                {Separation_Url === "ESG" && <>
                    {languageUse === 'English' ? 'By submitting this survey, I confirm that:' : 'Dengan menyerahkan tinjauan ini, saya mengesahkan bahawa:'}
                    <ul className='mb-0'>
                        <li>{languageUse === 'English' ? 'All information provided is accurate and truthful.' : 'Semua maklumat yang diberikan adalah tepat dan benar.'}</li>
                        <li>{languageUse === 'English' ? 'The data may be used to generate an ESG maturity score and peer benchmark.' : 'Data boleh digunakan untuk menjana skor kematangan ESG dan penanda aras rakan sebaya.'}</li>
                        <li>{languageUse === 'English' ? 'My individual data will be kept confidential.' : 'Data individu saya akan dirahsiakan.'}</li>
                        <li>{languageUse === 'English' ? 'Aggregated data may be shared with the reporting company.' : 'Data agregat boleh dikongsi dengan syarikat pelapor.'}</li>
                    </ul>
                </>}
                {Separation_Url === "PROGRESS" && <>
                    {languageUse === 'English' ? 'By submitting this survey, I confirm that:' : 'Dengan menyerahkan tinjauan ini, saya mengesahkan bahawa:'}
                    <ul className='mb-0'>
                        <li>{languageUse === 'English' ? 'All information provided is accurate and truthful.' : 'Semua maklumat yang diberikan adalah tepat dan benar.'}</li>
                        <li>{languageUse === 'English' ? 'The data may be used to generate PRO-GRESS Climate Action Readiness report and peer benchmark.' : 'Data tersebut boleh digunakan untuk menjana laporan Kesediaan Tindakan Iklim PRO-GRESS dan penanda aras rakan sebaya.'}</li>
                        <li>{languageUse === 'English' ? 'My individual data will be kept confidential.' : 'Data individu saya akan dirahsiakan.'}</li>
                        <li>{languageUse === 'English' ? 'Aggregated data may be shared with the reporting company.' : 'Data agregat boleh dikongsi dengan syarikat pelapor.'}</li>
                    </ul>
                </>}
            </Modal.Body>}

            {/* on answer submission page */}
            {props?.questionPage && !confirmation && <Modal.Body>{languageUse === 'English' ? 'Would you like to upload supporting documents for your answers? (Kindly note that the option to upload supporting documents will no longer be available after submitting your assessment.)' : 'Adakah anda ingin memuat naik dokumen sokongan untuk jawapan anda? (Sila ambil perhatian bahawa pilihan untuk memuat naik dokumen sokongan tidak lagi tersedia selepas menyerahkan penilaian anda.)'}</Modal.Body>}

            {confirmation && <Modal.Body>
                {Separation_Url === "ESG" && <>
                    {languageUse === 'English' ? 'By submitting this survey, I confirm that:' : 'Dengan menyerahkan tinjauan ini, saya mengesahkan bahawa:'}
                    <ul className='mb-0'>
                        <li>{languageUse === 'English' ? 'All information provided is accurate and truthful.' : 'Semua maklumat yang diberikan adalah tepat dan benar.'}</li>
                        <li>{languageUse === 'English' ? 'The data may be used to generate an ESG maturity score and peer benchmark.' : 'Data boleh digunakan untuk menjana skor kematangan ESG dan penanda aras rakan sebaya.'}</li>
                        <li>{languageUse === 'English' ? 'My individual data will be kept confidential.' : 'Data individu saya akan dirahsiakan.'}</li>
                        <li>{languageUse === 'English' ? 'Aggregated data may be shared with the reporting company.' : 'Data agregat boleh dikongsi dengan syarikat pelapor.'}</li>
                    </ul>
                </>}
                {Separation_Url === "PROGRESS" && <>
                    {languageUse === 'English' ? 'By submitting this survey, I confirm that:' : 'Dengan menyerahkan tinjauan ini, saya mengesahkan bahawa:'}
                    <ul className='mb-0'>
                        <li>{languageUse === 'English' ? 'All information provided is accurate and truthful.' : 'Semua maklumat yang diberikan adalah tepat dan benar.'}</li>
                        <li>{languageUse === 'English' ? 'The data may be used to generate PRO-GRESS Climate Action Readiness report and peer benchmark.' : 'Data tersebut boleh digunakan untuk menjana laporan Kesediaan Tindakan Iklim PRO-GRESS dan penanda aras rakan sebaya.'}</li>
                        <li>{languageUse === 'English' ? 'My individual data will be kept confidential.' : 'Data individu saya akan dirahsiakan.'}</li>
                        <li>{languageUse === 'English' ? 'Aggregated data may be shared with the reporting company.' : 'Data agregat boleh dikongsi dengan syarikat pelapor.'}</li>
                    </ul>
                </>}
            </Modal.Body>}

            {!confirmation && !props?.questionPage && <Modal.Footer>
                <Button variant="secondary" type='button' onClick={props.handleClose}>
                    {languageUse === 'English' ? 'Close' : 'Tutup'}
                </Button>
                <Button variant="primary" type='submit' onClick={endSubmission}>
                    {languageUse === 'English' ? 'Yes' : 'Ya'}
                </Button>
            </Modal.Footer>}

            {!confirmation && props?.questionPage && <Modal.Footer className='d-flex justify-content-between'>
                {/* {Separation_Url === 'ESG' && <Button variant="primary" type='button' onClick={() => {
                    navigate(`/uploadDocuments/${props?.decryptedSubId}`)
                }}>
                    Yes
                </Button>}

                {Separation_Url === 'PROGRESS' && <Button variant="primary" type='button' onClick={() => {
                    navigate(`/uploadProgressDocuments/${props?.decryptedSubId}`)
                }}>
                    Yes
                </Button>} */}

                {location?.pathname?.includes('progress') ? <Button variant="primary" type='button' onClick={() => {
                    navigate(`/uploadProgressDocuments/${props?.decryptedSubId}`)
                }}>
                    {languageUse === 'English' ? 'Yes' : 'Ya'}
                </Button> : <Button variant="primary" type='button' onClick={() => {
                    navigate(`/uploadDocuments/${props?.decryptedSubId}`)
                }}>
                    {languageUse === 'English' ? 'Yes' : 'Ya'}
                </Button>}

                <Button variant="primary" type='button' onClick={() => {
                    setConfirmation(true)
                }}>
                    {languageUse === 'English' ? 'No' : 'Tidak'}
                </Button>
            </Modal.Footer>}

            {confirmation && <Modal.Footer className='d-flex justify-content-between'>
                <Button variant="primary" type='submit' onClick={endSubmission}>
                    {languageUse === 'English' ? 'Yes, submit' : 'Ya, serahkan'}
                </Button>

                <Button variant="primary" type='button' onClick={() => {
                    setConfirmation(false)
                }}>
                    {languageUse === 'English' ? 'No' : 'Tidak'}
                </Button>
            </Modal.Footer>}

        </Modal>
    </>
}

export default SubmissionEndModal;