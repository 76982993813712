import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function getOverallDimensionStateData
* @param {  authToken, id, state }
* @description This function is used to get over all user dimension data list based on state
*/
export const getOverallDimensionStateData = async ({ authToken, userId, id, state }) => {
    const result = axios.get(`${baseUrl}users/associcated/companies/usersLevel?dimension=${id}&userId=${userId}&state=${state}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        }
    })
    return result;
};