import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function languageSelectionService
* @param {  authToken, language }
* @description This function is used to join new access code
*/
export const languageSelectionService = async ({ authToken, language }) => {
    const result = axios.post(`${baseUrl}users/language`, {
        language
    }, {
        headers: {
            Authorization: `Bearer ${authToken}`,

        }
    })
    return result;
};