import React, { useState } from 'react'
import { useNavigate, useParam, Link } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Label,
    Spinner,
    Toast,
    Input,
    CardBody,
    CardTitle,
    CardHeader,
    FormFeedback,
} from 'reactstrap'

import { Button } from 'react-bootstrap'
import * as Icons from 'react-feather'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast, { Toaster } from 'react-hot-toast'

// API-Service
import { forgetPasswordService } from '../../../services/authService'

function ForgotPass() {
    const [isLoading, setLoading] = useState(false)
    const navigate = useNavigate()

    const LoginValidation = yup.object().shape({
        email: yup.string().email().required('Please enter email'),
    })
    // ** Hooks
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(LoginValidation) })

    /**
    * @function handleForgetPassword
    * @param (data)
    * @description api to handle forget password.
    */
    const handleForgetPassword = async (data) => {
        const userEmail = data.email
        setLoading(true)
        try {
            const result = await forgetPasswordService({
                email: data.email,
            });

            const tempToken = result.data.data.tempToken;

            if (result?.data.statusCode === 200) {
                reset()
                setLoading(false)
                navigate('/OTPverify', {
                    state: {
                        tempToken,
                        userEmail
                    }
                });
            } else {
                toast.success(result.data.statusCode)
                setLoading(false)
            };
            return false;

        } catch (error) {
            setLoading(false);
            toast.error(error.response.data.message || 'Something went wrong')
        };
    }

    /**
    * @function onSubmit
    * @param (data)
    * @description to submit
    */
    const onSubmit = data => {
        if (Object.values(data).every(field => field.length > 0)) {
            handleForgetPassword(data)
        }
    }

    return (
        <Container>
            <div>
                <Toaster />
            </div>

            <Row className="pt-4 pb-4">
                <div className="user-login d-flex flex-column mx-auto p-4 p-lg-5 pb-lg-3 authDiv text-start col-lg-5 col-md-6 col-sm-8">
                    <h1 className="sign">Forgot Password</h1>
                    <span className='login-border' />

                    <div>
                        {/* onSubmit={handleSubmit(onSubmit)} */}
                        <form className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
                            {/* <form className='auth-login-form mt-2'> */}
                            <div className='my-5'>
                                <div className='pb-4'>
                                    <div className='mb-4'>
                                        <Label className='form-label custom-label' for='email'>
                                            Email
                                        </Label>

                                        {/* <input type="email" class="form-control" name='email' id="email" aria-describedby="emailHelp" placeholder="Enter email"/> */}
                                        <Controller
                                            id='email'
                                            name='email'
                                            defaultValue=''
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type='email' placeholder='Enter registered email' invalid={errors.email && true} />
                                            )}
                                        />
                                        {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <Button className="float-end authButton login-btn-min-width" variant='outline-primary' color="primary" type="submit">

                                    {isLoading ? <Spinner variant='dark' size='sm' /> : 'Get Verification code'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Row>
        </Container>
    );
}

export default ForgotPass