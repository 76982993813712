import { useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Label, Spinner, Input, FormFeedback, UncontrolledTooltip, FormGroup } from 'reactstrap';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast, { Toaster } from 'react-hot-toast';

import { BsQuestionCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

// Custom Component
import InputPasswordToggle from '../../components/input-password-toggle';

// Utility-Service
import { getToken, setUserSession } from '../../../utility/AuthService';

// API-Service
import { registerService } from '../../../services/authService';
import { getIndustry } from '../../../services/industryService';
import { demographicsListService } from '../../../services/demographicsService';

//import custom list
// import states from '../../../utility/State';
// import cities from '../../../utility/City';

const RegisterPage = () => {
    const recaptchaRef = useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [captchaPass, setCaptchaPass] = useState(false);
    const [recaptchaResponse, setRecaptchaResponse] = useState();
    const [industryList, setIndustryList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const token = getToken();
    const navigate = useNavigate();

    const companySizeList = [
        {
            value: "Less than RM 300,000",
            label: "Less than RM 300,000"
        },
        {
            value: "RM 300,000 - 3 mil",
            label: "RM 300,000 - 3 mil"
        },
        {
            value: "RM 3 mil - 20 mil",
            label: "RM 3 mil - 20 mil"
        },
        {
            value: "RM 20 mil - 50 mil",
            label: "RM 20 mil - 50 mil"
        },
        {
            value: "RM 50 mil - 100 mil",
            label: "RM 50 mil - 100 mil"
        },
        {
            value: "RM 100 mil +",
            label: "RM 100 mil +"
        }
    ]

    // ** Hooks
    const { reset, watch, setValue, control, handleSubmit, clearErrors, setError, formState: { errors } } = useForm({
        mode: 'onChange'
    })
    const watchCountry = watch("country")
    const watchState = watch("state")

    /**
        * @function getCountryList
        * @param
        * @description get country list using api
    */
    const getCountryList = async () => {
        try {
            const result = await demographicsListService({
                type: 'Country',
                typeId: ""
            });
            if (result?.data?.statusCode === 200) {
                setCountryList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setCountryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function getStateList
        * @param
        * @description get state list using api
    */
    const getStateList = async () => {
        try {
            const result = await demographicsListService({
                type: 'State',
                typeId: watchCountry?.value
            });
            if (result?.data?.statusCode === 200) {
                setStateList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setStateList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function getCityList
        * @param
        * @description get city list using api
    */
    const getCityList = async () => {
        try {
            const result = await demographicsListService({
                type: 'City',
                typeId: watchState?.value
            });
            if (result?.data?.statusCode === 200) {
                setCityList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setCityList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getCountryList()
    }, []);

    useEffect(() => {
        if (watchCountry?.value) {
            getStateList()
            setValue("state", null)
            setValue("city", null)
        }
    }, [watchCountry]);

    useEffect(() => {
        if (watchState?.value) {
            getCityList()
            setValue("city", null)
        }
    }, [watchState]);

    /**
        * @function getIndustryID
        * @param
        * @description get industry list using api
    */
    const getIndustryID = async () => {
        try {
            const result = await getIndustry({
                filter: 'Active',
                offset: 0
            });
            setIndustryList(result?.data?.data?.list.map(item => {
                return {
                    value: item?._id,
                    label: item?.name
                }
            }))
        } catch (error) {
            setIndustryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getIndustryID()
    }, []);

    useEffect(() => {
        if (token) {
            navigate('/user/history')
        }
    }, [])

    /**
        * @function signUP
        * @param input
        * @description handle signUp api to sign up user.
    */
    const signUP = async (input) => {
        try {
            setLoading(true)
            const result = await registerService(input)
            const { accessToken, refreshToken } = result?.data?.data?.tokens;

            if (result?.data?.statusCode === 200) {
                reset()
                setLoading(false)
                const getData = result?.data?.data

                if (getData?.tokens) {
                    setUserSession(accessToken, { ...getData?.user, language: 'English' }, refreshToken);
                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    localStorage.setItem('userData', JSON.stringify({ ...getData?.user, language: 'English' }));
                    toast.success('Sign Up completed successfully')
                    navigate('/user/history');
                }
            }
        } catch (error) {
            recaptchaRef.current.reset()
            toast.error(error?.response?.data?.message || 'Something went wrong')
            setLoading(false)
        }

    }

    /**
        * @function handleRegister
        * @param data
        * @description prepare input data to call signUp api.
    */
    const handleRegister = async (data) => {
        const input = {
            username: data?.username,
            email: data?.email,
            company: data?.company,
            companyReg: data?.companyRegNum,
            companyLocation: data?.companyAddress,
            designation: data?.designation,
            mobile: data?.mobile,
            password: data?.password,
            industry: data?.industry?.value,
            country: data?.country?.label,
            city: data?.city?.label ? data?.city?.label : "",
            state: data?.state?.label,
            companySize: data?.companySize?.value,
            accessCode: data?.accessCode,
            repatcheResponse: recaptchaResponse
        }
        signUP(input)
    }

    /**
        * @function onSubmit
        * @param data
        * @description to submit
    */
    const onSubmit = data => {
        if (data) {
            handleRegister(data)
        }
    }

    /**
        * @function signIn
        * @param
        * @description navigate to login page
    */
    const signIn = () => {
        navigate('/login')
    }

    /**
        * @function onCaptchaChange
        * @params value
        * @description set the captcha value
    */
    const onCaptchaChange = (value) => {
        setRecaptchaResponse(value)
        if (value) {
            setCaptchaPass(true)
        } else {
            setCaptchaPass(false)
        }
    }

    return <Container>
        <div>
            <Toaster />
        </div>

        <Row className="pt-4 pb-4">
            <Col xs lg="12" className='text-center'>
                <h3 className='auth-heading'>Already have an account? <span className='signIn' onClick={signIn}>Sign In</span></h3>
            </Col>
        </Row>
        <Row>
            <div className="user-login d-flex flex-column mx-auto p-4 p-lg-5 pb-lg-3 authDiv text-start col-lg-5 col-md-6 col-sm-8">
                <h1 className="sign">SIGN UP</h1>
                <span className='login-border' />
                <div>
                    <form className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
                        <div className='my-5'>
                            <div className=''>
                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='username'>
                                        Username
                                    </Label>
                                    <Controller
                                        id='username'
                                        name='username'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => (
                                            <Input {...field} placeholder='username' invalid={errors.username && true} />
                                        )}
                                        rules={{
                                            required: 'Please enter Username'
                                        }}
                                    />
                                    {errors.username && <FormFeedback>{errors.username.message}</FormFeedback>}
                                </div>
                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='email'>
                                        Email
                                    </Label>
                                    <Controller
                                        id='email'
                                        name='email'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => (
                                            <Input {...field} type='email' placeholder='Enter registered email' invalid={errors.email && true} />
                                        )}
                                        rules={{
                                            required: 'Please enter Email'
                                        }}
                                    />
                                    {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
                                </div>

                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='company'>
                                        Company Name
                                    </Label>
                                    <Controller
                                        id='company'
                                        name='company'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => (
                                            <Input {...field} placeholder='Company Name' invalid={errors.company && true} />
                                        )}
                                        rules={{
                                            required: 'Please enter Company name'
                                        }}
                                    />
                                    {errors.company && <FormFeedback>{errors.company.message}</FormFeedback>}
                                </div>

                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='companyRegNum'>
                                        Company Registration Number
                                    </Label>
                                    <Controller
                                        id='companyRegNum'
                                        name='companyRegNum'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => (
                                            <Input {...field} placeholder='Company Registration Number' invalid={errors.companyRegNum && true} />
                                        )}
                                        rules={{
                                            required: 'Please enter Company Registration Number'
                                        }}
                                    />
                                    {errors.companyRegNum && <FormFeedback>{errors.companyRegNum.message}</FormFeedback>}
                                </div>

                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='companyAddress'>
                                        Company Address
                                    </Label>
                                    <Controller
                                        id='companyAddress'
                                        name='companyAddress'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => (
                                            <Input {...field} placeholder='Company Address' invalid={errors.companyAddress && true} />
                                        )}
                                        rules={{
                                            required: 'Please enter Company Address'
                                        }}
                                    />
                                    {errors.companyAddress && <FormFeedback>{errors.companyAddress.message}</FormFeedback>}
                                </div>

                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='designation'>
                                        Designation
                                    </Label>
                                    <Controller
                                        id='designation'
                                        name='designation'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => (
                                            <Input {...field} placeholder='Designation' invalid={errors.designation && true} />
                                        )}
                                        rules={{
                                            required: 'Designation is required'
                                        }}
                                    />
                                    {errors.designation && <FormFeedback>{errors.designation.message}</FormFeedback>}
                                </div>

                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='mobile'>
                                        Mobile
                                    </Label>
                                    <Controller
                                        id='mobile'
                                        name='mobile'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => (
                                            <PhoneInput
                                                placeholder='Mobile'
                                                defaultCountry="MY"
                                                value={field?.value}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                    {errors.mobile && <FormFeedback>{errors.mobile.message}</FormFeedback>}
                                </div>

                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='industry'>
                                        Industry
                                    </Label>
                                    <Controller
                                        name="industry"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                className='text-secondary'
                                                placeholder="Select Industry"
                                                isClearable
                                                options={industryList}
                                                isSearchable={true}
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'Industry is required'
                                        }}
                                    />
                                    {errors?.industry?.message && <span className='text-danger'>{errors?.industry?.message}</span>}
                                </div>

                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='country'>
                                        Country
                                    </Label>
                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                className='text-secondary'
                                                placeholder="Select Country"
                                                isClearable
                                                options={countryList}
                                                isSearchable={true}
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'Country is required'
                                        }}
                                    />
                                    {errors?.country?.message && <span className='text-danger'>{errors?.country?.message}</span>}
                                </div>

                                <div className='d-flex flex-row'>
                                    <div className='mb-4 col-6 pe-2'>
                                        <Label className='custom-label form-label' for='state'>
                                            State
                                        </Label>
                                        <Controller
                                            name="state"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    className='text-secondary'
                                                    placeholder="Select State"
                                                    isClearable
                                                    options={stateList}
                                                    isSearchable={true}
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'State is required'
                                            }}
                                        />
                                        {errors?.state?.message && <span className='text-danger'>{errors?.state?.message}</span>}
                                    </div>

                                    <div className='mb-4 col-6 ps-2'>
                                        <Label className='custom-label form-label' for='city'>
                                            City
                                        </Label>
                                        <Controller
                                            name="city"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    className='text-secondary'
                                                    placeholder="Select City"
                                                    isClearable
                                                    options={cityList}
                                                    isSearchable={true}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='companySize'>
                                        Company Size
                                    </Label>
                                    <Controller
                                        name="companySize"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                className='text-secondary'
                                                placeholder="Select Company Size"
                                                isClearable
                                                options={companySizeList}
                                                isSearchable={true}
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'Company Size is required'
                                        }}
                                    />
                                    {errors?.companySize?.message && <span className='text-danger'>{errors?.companySize?.message}</span>}
                                </div>

                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='accessCode'>
                                        Access Code&nbsp;

                                        <span id="UncontrolledTooltipExample"><BsQuestionCircle size={12} /></span>
                                        <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample">
                                            Access code is not required for registration. Only for companies that are participating in the supply chain program: you can insert the code here.
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Controller
                                        id='accessCode'
                                        name='accessCode'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => (
                                            <Input {...field} placeholder='Access Code' />
                                        )}
                                    />
                                </div>

                                <div className='mb-4'>
                                    <Label className='custom-label form-label' for='password'>
                                        Password
                                    </Label>
                                    <Controller
                                        id='password'
                                        name='password'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => (
                                            <InputPasswordToggle {...field} placeholder='Password' invalid={errors.password && true} />
                                        )}
                                        rules={{
                                            required: 'Please enter Password'
                                        }}
                                    />
                                    {errors.password && <FormFeedback>{errors.password.message}</FormFeedback>}
                                </div>

                                <div className='mb-4'>
                                    <FormGroup check>
                                        <Input type="checkbox" required />
                                        {' '}
                                        <Label check > I agree with{' '}
                                            <a href={'/terms&condition'} target='_blank'>
                                                <span className='text-decoration-underline'>Terms & Condition</span>
                                            </a>
                                        </Label>
                                    </FormGroup>
                                </div>
                            </div>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                className='google-recaptcha'
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={onCaptchaChange}
                            />
                        </div>
                        <div className=''>
                            <Button className="float-end authButton login-btn-min-width" variant='outline-primary' disabled={!captchaPass} color="primary" type="submit">
                                {isLoading ? <Spinner variant='dark' size='sm' /> : 'Register'}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Row>
    </Container>
};

export default RegisterPage;