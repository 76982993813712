import axios from 'axios'
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function getAdvanceMaturityDataService
* @param { token }
* @description This function is used to get indicator average data
*/
export const getAdvanceMaturityDataService = async ({ token }) => {
    const result = axios.get(`${baseUrl}users/get/esg/maturity`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    return result;
};


/**
* @function filterMaturityIndustryAndStateService
* @param { token, country, state, industry, fromDate, toDate}
* @description This function is used to get industry maturity data
*/
export const filterMaturityIndustryAndStateService = async ({ token, country, state, industry, toDate, fromDate }) => {
    const result = axios.get(`${baseUrl}users/get/esg/maturity?country=${country}&state=${state}&industry=${industry}&toDate=${toDate}&fromDate=${fromDate}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    return result;
};