import React, { useEffect, useState } from 'react'
import { Card, CardTitle, CardHeader, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';

import Selection from '../Selection/Selection';
import HorizontalBarChartStacked from '../CustomChart/HorizontalBarChartStacked';
import VerticalStackedBarChartLegends from '../CustomChart/VerticalStackedBarChartLegends';

// import { Undertaking } from "../../../assets/images/analytics/Undertakin.png";


import { getToken } from '../../../utility/AuthService';
import { getActiveThemeDimensionListService, getUnderTakingService } from '../../../services/analyticsServices/advanceAnalyticsService/advanceAnalyticsService';

const ProgressActions = () => {
    const token = getToken()

    const [isLoading, setLoading] = useState(false)
    const [watchThemeValue, setThemeWatchValue] = useState();
    const [theme, setTheme] = useState([])
    const [chartData, setchartData] = useState()

    const horizontalStackOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
            x: {
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                stacked: true
            },
            y: {
                max: 100,
                ticks: {
                    callback: function (value, index, ticks) {
                        return `${value}%`;
                    }
                },
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                stacked: true,
                ticks: {
                    display: false
                }
            },
            xAxes: [
                {
                    scaleLabel: {
                        display: false
                    },
                    gridLines: {
                        display: false
                    },
                    stacked: true,
                    barThickness: 6,  // number (pixels) or 'flex'
                    maxBarThickness: 8 // number (pixels)
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    stacked: true
                }
            ]
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                anchor: 'end',
                align: 'start',
                color: "white",
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: "bold",
                },
                formatter: (value) => {
                    return `${value}%`
                }
            },
            tooltip: {
                callbacks: {
                    title: (context) => {
                        return context[0].label.replaceAll(',', ' ');
                    }
                }
            }
        },
        pointLabelFontFamily: "Quadon Extra Bold",
        scaleFontFamily: "Quadon Extra Bold"
    };

    /**
        * @function fetchThemeData
        * @param
        * @description to get all theme list
    */
    const fetchThemeData = async () => {
        try {
            const result = await getActiveThemeDimensionListService({
                token
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setTheme(result?.data?.data?.list?.filter(item => item?.name !== 'Demographics').map((item) => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            } else {
                setLoading(false)
                setTheme([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setTheme([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        fetchThemeData()
    }, []);

    /**
        * @function getUnderTakingData
        * @param
        * @description to get under taking data
    */
    const getUnderTakingData = async () => {
        try {
            setLoading(true)
            const result = await getUnderTakingService({
                token,
                id: "64b5249d932389d15a010117"
                // id: watchThemeValue?.value
            });
            if (result?.data?.statusCode === 200) {
                setchartData(result?.data?.data?.final)
                setLoading(false)
            } else {
                setLoading(false)
                setchartData()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        if (watchThemeValue?.value) {
            getUnderTakingData()
        }
    }, [watchThemeValue])

    return <>

        <h3 className='mt-1 px-2'><strong>Submission details for each dimension</strong></h3>
        {theme?.length > 0 ? <div className='ps-2 fw-bold'>
            <Selection
                className={'w-25'}
                options={theme}
                title={'Theme'}
                name={'theme'}
                setWatchValue={setThemeWatchValue}
            />
        </div> : <div className='m-4'>
            <Spinner></Spinner>
        </div>
        }
        {watchThemeValue && <div className='p-2'>
            <h4>{watchThemeValue?.label}</h4>
        </div>}

        {!watchThemeValue && <div className='h-50 bg-white text-center'>
            <h4 className='fw-bold my-3'>Select An Theme Above</h4>
            <div>
                {/* <img src={Undertaking} alt='Undertaking' /> */}
            </div>
            <p className='my-5'>You haven’t selected any theme to show details</p>

        </div>}
        {isLoading && <div className='text-center h-100'><Spinner></Spinner></div>}

        {watchThemeValue && !isLoading &&
            <>
                <div className='row container bg-white ms-0'>
                    <VerticalStackedBarChartLegends />
                </div>
                <div className=''>
                    {chartData &&
                        <>
                            {Array.from(Array(Math.ceil(Object.keys(chartData)?.length / 1)).keys())?.map((item) => {
                                return <>
                                    <div className='p-2'>
                                        <span>Q{item + 1}. {Object.keys(chartData)[item]}</span>
                                        <div>
                                            <HorizontalBarChartStacked data={{
                                                labels: chartData ? [Object.keys(chartData)[item]] : [],
                                                datasets: [
                                                    {
                                                        data: chartData ? [Object.values(chartData)[item][0]?.Yes?.toFixed(2)] : [],
                                                        backgroundColor: "#34A853",
                                                        hoverBackgroundColor: "green",
                                                        label: '',
                                                        borderRadius: 5,
                                                        barThickness: 50,
                                                        maxBarThickness: 50
                                                    },
                                                    {
                                                        data: chartData ? [Object.values(chartData)[item][1]?.No?.toFixed(2)] : [],
                                                        backgroundColor: "#EA4335",
                                                        hoverBackgroundColor: "red",
                                                        label: '',
                                                        borderRadius: 5,
                                                        barThickness: 50,
                                                        maxBarThickness: 50
                                                    },
                                                ]
                                            }} options={horizontalStackOptions} />
                                        </div>
                                    </div>
                                </>
                            })}
                        </>
                    }
                </div>
            </>
        }
    </>
}

export default ProgressActions