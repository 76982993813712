import { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Row, Col, Spinner } from 'reactstrap';
import { Accordion, Button, Card, Spinner as BootstrapSpinner } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useForm, useFieldArray } from 'react-hook-form';
import CryptoJS from 'crypto-js';
import { useSelector } from 'react-redux';

// Utility-Service
import { getToken, getUser } from '../../utility/AuthService';

// API-Service
import { userAnswerService } from '../../../src/services/userQ&AService';
import { sectionQuestionService } from '../../../src/services/sectionService';
import { getAssociatedCompaniesSubmissionService } from '../../services/associatedCompamiesSubmissionDetalisService';

//redux
import { selectLanguageUse } from '../../redux/slices/language.Slice';

const QuestionPage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');

    const Indicato_ID = props.indicator_ID;
    const authToken = getToken();
    const [eventArray, setEventArray] = useState(0);

    const [isLoading, setLoading] = useState(false)
    const [isQuestionLoading, setQuestionLoading] = useState(false)
    const [sectionQues, setSectionQues] = useState([])
    const [activeKey, setActiveKey] = useState(0);
    const [savedSubmitButton, setSavedSubmitButton] = useState(false);

    const viewPage = location.pathname.includes('view')
    const decryptedSubId = CryptoJS.AES.decrypt(id, 'mySecretKey').toString(CryptoJS.enc.Utf8);
    const user = getUser()
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const { register, control, watch, handleSubmit, reset } = useForm({});

    const { fields: finalField } = useFieldArray({
        control,
        name: 'finalAnswers'
    })

    const { fields: reqField } = useFieldArray({
        control,
        name: 'reqAnswers'
    })

    const { fields: optField } = useFieldArray({
        control,
        name: 'optAnswers'
    })

    const { fields: sectionOptField } = useFieldArray({
        control,
        name: 'sectionOptAnswers'
    })

    const { fields: sectionReqField } = useFieldArray({
        control,
        name: 'sectionReqAnswers'
    })
    const watchSecOthers = watch("sectionOptAnswers");

    /**
        * @function getQuestions
        * @param
        * @description handle API to get the Questions
    */
    const getSectionQuestions = async () => {
        try {
            setQuestionLoading(true)
            const result = await sectionQuestionService({
                authToken,
                indicatorId: Indicato_ID,
                submissionId: decryptedSubId,
            });
            setSectionQues(result?.data?.data)
            setQuestionLoading(false)
        } catch (error) {
            setSectionQues([])
            setQuestionLoading(false)
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        if (Indicato_ID) {
            getSectionQuestions()
        }
    }, [Indicato_ID])

    /**
        * @function getAssociatedSectionQuestions
        * @param
        * @description handle API to get the Questions
    */
    const getAssociatedSectionQuestions = async () => {
        try {
            const result = await getAssociatedCompaniesSubmissionService({
                authToken,
                indicator: Indicato_ID,
                submissionId: decryptedSubId,
                userId: userId
            });
            setSectionQues(result?.data?.data)
        } catch (error) {
            setSectionQues([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        if (userId) {
            getAssociatedSectionQuestions()
        }
    }, [Indicato_ID, userId])

    /**
        * @function answerSubmit
        * @param data
        * @description api to submit the answer
    */
    const answerSubmit = async (data) => {
        setLoading(true)
        let sectionOptAnswers = []
        if (data?.sectionOptAnswers?.length > 0) {
            data?.sectionOptAnswers?.map((innerArray) => (
                sectionOptAnswers = [...sectionOptAnswers, ...innerArray.filter((obj) => Object.values(obj).every((val) => val !== null))]
            ));
        }
        let OtherSecOptionalAns = []
        if (props.currentIndicator === 'Demographics') {
            OtherSecOptionalAns = sectionOptAnswers?.map((item => (item?.answer?.length > 0 || item?.otherAnswer) && {
                id: item?.id,
                answer: ((languageUse === 'English' && item?.answer === "Other") || (languageUse === 'Malay' && item?.answer === "Lain-lain")) ? item?.otherAnswer : Array.isArray(item?.answer) && item?.answer?.length > 0 ? item?.answer?.map((itemq) => {
                    return languageUse === 'English' ? itemq : sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options[sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options_Malay?.findIndex(optionItem => optionItem?.ans_Malay === itemq)]?.ans
                }) : languageUse === 'English' ? item?.answer : sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options[sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options_Malay?.findIndex(optionItem => optionItem?.ans_Malay === item?.answer)]?.ans,
                answerMalay: ((languageUse === 'English' && item?.answer === "Other") || (languageUse === 'Malay' && item?.answer === "Lain-lain")) ? item?.otherAnswer : Array.isArray(item?.answer) && item?.answer?.length > 0 ? item?.answer?.map((itemq) => {
                    return languageUse === 'Malay' ? itemq : sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options_Malay[sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options?.findIndex(optionItem => optionItem?.ans === itemq)]?.ans_Malay
                }) : languageUse === 'Malay' ? item?.answer : sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options_Malay[sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options?.findIndex(optionItem => optionItem?.ans === item?.answer)]?.ans_Malay
            })).filter((item) => item?.answer && item?.id)
        } else {
            OtherSecOptionalAns = sectionOptAnswers?.map((item => (item?.answer?.length > 0 || item?.otherAnswer) && {
                id: item?.id,
                answer: ((languageUse === 'English' && item?.answer?.includes("Other")) || (languageUse === 'Malay' && item?.answer?.includes("Lain-lain"))) ? item?.answer?.map((itemq) => {
                    if ((languageUse === 'English' && itemq === "Other") || (languageUse === 'Malay' && itemq === "Lain-lain")) {
                        return item?.otherAnswer
                    } else {
                        return languageUse === 'English' ? itemq : sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options[sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options_Malay?.findIndex(optionItem => optionItem?.ans_Malay === itemq)]?.ans
                    }
                }) : item?.answer?.map((itemq) => {
                    return languageUse === 'English' ? itemq : sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options[sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options_Malay?.findIndex(optionItem => optionItem?.ans_Malay === itemq)]?.ans
                }),
                answerMalay: ((languageUse === 'English' && item?.answer?.includes("Other")) || (languageUse === 'Malay' && item?.answer?.includes("Lain-lain"))) ? item?.answer?.map((itemq) => {
                    if ((languageUse === 'English' && itemq === "Other") || (languageUse === 'Malay' && itemq === "Lain-lain")) {
                        return item?.otherAnswer
                    } else {
                        return languageUse === 'Malay' ? itemq : sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options_Malay[sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options?.findIndex(optionItem => optionItem?.ans === itemq)]?.ans_Malay
                    }
                }) : item?.answer?.map((itemq) => {
                    return languageUse === 'Malay' ? itemq : sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options_Malay[sectionQues?.find(sectionItem => sectionItem?.questions?.some(question => question._id === item?.id))?.questions?.find(question => question._id === item?.id)?.questionId?.options?.findIndex(optionItem => optionItem?.ans === itemq)]?.ans_Malay
                })
            })).filter((item) => item?.answer && item?.id)
        }
        let sectionReqAnswers = []
        if (data?.sectionReqAnswers?.length > 0) {
            data?.sectionReqAnswers?.map((innerArray) => (
                sectionReqAnswers = [...sectionReqAnswers, ...innerArray.filter((obj) => Object.values(obj).every((val) => val !== null))]
            ));
        }
        const sectionReqAnswers2 = sectionReqAnswers?.map((item) => {
            if (item?.answer?.length > 0) {
                return {
                    answer: languageUse === 'English' ? [item?.answer] : [item?.answer === 'Ya' ? 'yes' : 'no'],
                    answerMalay: languageUse === 'English' ? [item?.answer === 'yes' ? 'Ya' : 'Tidak'] : [item?.answer],
                    id: item?.id
                }
            }
        }).filter((item) => item?.answer?.length > 0)

        let finalAnswersData = []

        if (props.currentIndicator === 'Demographics') {
            finalAnswersData = sectionReqAnswers2.concat(OtherSecOptionalAns?.map((item) => {
                return {
                    answer: Array.isArray(item?.answer) ? item?.answer : [item?.answer],
                    answerMalay: Array.isArray(item?.answerMalay) ? item?.answerMalay : [item?.answerMalay],
                    id: item?.id
                }
            }));
        } else {
            finalAnswersData = sectionReqAnswers2.concat(OtherSecOptionalAns);
        }

        try {
            const result = await userAnswerService({
                authToken,
                data: finalAnswersData.filter((item => item.answer && item.id))
            });
            toast.success(result?.data?.data?.message)
            props.setChangeIndicatorName(!props.changeIndicatorName)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function onSubmit
        * @param data
        * @description submit the answer
    */
    const onSubmit = (data) => {
        if (data) {
            answerSubmit(data);
        } else {
            toast.error('Something went wrong')
        }
    };

    /**
        * @function handleReset
        * @param
        * @description reset all form data
    */
    const handleReset = () => {
        reset();
    };

    useEffect(() => {
        if (sectionQues) {
            setActiveKey(0)
            reset()
            let tempStatus = false

            const defaultValues = {};

            let secOptDeafultValues = []

            if (sectionQues?.length > 0 && props.currentIndicator !== 'Demographics') {
                sectionQues.filter(item => item?.questions?.filter(itemq => itemq?.questionId?.isOptional).length > 0).map(itemq => {
                    let temp = []
                    temp = itemq.questions.filter(item => item.isOptional).map((item => {
                        return {
                            answer: languageUse === 'English' ? !item?.answer.every(elem => item?.questionId?.options.some(element => element.ans === elem) || item?.questionId?.options_Malay.some(element => element.ans === elem)) && item?.answer?.filter(elem => item?.questionId?.options.some(element => element.ans === elem) || item?.questionId?.options_Malay.some(element => element.ans === elem)).length > 0 ? item?.answer.map(itemq => (item?.questionId?.options?.some(answerItem => answerItem?.ans === itemq) || item?.questionId?.options_Malay?.some(answerItem => answerItem?.ans === itemq)) ? itemq : 'Other') : item?.answer : languageUse === 'Malay' ? !item?.answerMalay.every(elem => item?.questionId?.options_Malay.some(element => element.ans_Malay === elem)) && item?.answerMalay?.filter(elem => item?.questionId?.options_Malay.some(element => element.ans_Malay === elem)).length > 0 ? item?.answerMalay.map(itemq => (item?.questionId?.options_Malay?.some(answerItem => answerItem?.ans_Malay === itemq)) ? itemq : 'Lain-lain') : item?.answerMalay : '',
                            id: item?._id,
                            otherAnswer: !item?.answer.every(elem => item?.questionId?.options.some(element => element.ans === elem)) && item?.answer?.filter(elem => !item?.questionId?.options.some(element => element.ans === elem)).length > 0 ? item?.answer?.filter(elem => !item?.questionId?.options.some(element => element.ans === elem))[0] : ''
                        }
                    }))
                    secOptDeafultValues = [...secOptDeafultValues, temp]
                })
                defaultValues.sectionOptAnswers = secOptDeafultValues;
            }

            if (sectionQues?.length > 0 && props.currentIndicator === 'Demographics') {
                sectionQues.filter(item => item?.questions?.filter(itemq => itemq?.questionId?.isOptional).length > 0).map(itemq => {
                    let temp = []
                    temp = itemq.questions.filter(item => item.isOptional).map((item => {
                        return {
                            answer: languageUse === 'English' && item?.answer.every(elem => item?.questionId?.options.some(element => element.ans === elem) || item?.questionId?.options_Malay.some(element => element.ans_Malay === elem)) && item?.answer?.filter(elem => item?.questionId?.options.some(element => element.ans === elem) || item?.questionId?.options_Malay.some(element => element.ans_Malay === elem)).length > 1 ? item?.answer.map(itemq => item?.questionId?.options.some(element => element.ans === itemq) ? itemq : 'Other') : languageUse === 'Malay' && item?.answerMalay.every(elem => item?.questionId?.options_Malay.some(element => element.ans_Malay === elem)) && item?.answerMalay?.filter(elem => item?.questionId?.options_Malay.some(element => element.ans_Malay === elem)).length > 1 ? item?.answerMalay.map(itemq => item?.questionId?.options_Malay.some(element => element.ans_Malay === itemq) ? itemq : 'Lain-lain') : languageUse === 'English' && Array.isArray(item?.answer) && item?.questionId?.options.some(element => element.ans === item?.answer[0]) ? item?.answer[0] : languageUse === 'English' && Array.isArray(item?.answer) && !item?.questionId?.options.some(element => element.ans === item?.answer[0]) ? 'Other' : languageUse === 'Malay' && Array.isArray(item?.answerMalay) && item?.questionId?.options_Malay.some(element => element.ans_Malay === item?.answerMalay[0]) ? item?.answerMalay[0] : languageUse === 'Malay' && Array.isArray(item?.answerMalay) && !item?.questionId?.options_Malay.some(element => element.ans_Malay === item?.answerMalay[0]) ? 'Lain-lain' : '',
                            id: item?._id,
                            otherAnswer: !item?.answer.every(elem => item?.questionId?.options.some(element => element.ans === elem)) && item?.answer?.filter(elem => !item?.questionId?.options.some(element => element.ans === elem)).length > 0 ? item?.answer?.find(elem => !item?.questionId?.options.some(element => element.ans === elem)) : ''
                        }
                    }))
                    secOptDeafultValues = [...secOptDeafultValues, temp]
                })
                defaultValues.sectionOptAnswers = secOptDeafultValues;
            }

            let secReqDeafultValues = []

            if (sectionQues?.length > 0) {
                sectionQues.filter(item => item?.questions?.filter(itemq => !itemq?.questionId?.isOptional).length > 0).map(itemq => {
                    let temp = []
                    temp = itemq.questions.filter(item => !item.isOptional).map((item => {
                        return {
                            answer: languageUse === 'English' && item?.answer?.length > 0 ? item?.answer[0] : languageUse === 'Malay' && item?.answerMalay?.length > 0 ? item?.answerMalay[0] : '',
                            id: item?._id,
                        }
                    }))
                    secReqDeafultValues = [...secReqDeafultValues, temp]
                })

                defaultValues.sectionReqAnswers = secReqDeafultValues;
            }

            tempStatus = secOptDeafultValues?.some(item => item?.some(innerItem => innerItem?.answer?.length > 0)) || secReqDeafultValues?.some(item => item?.some(innerItem => innerItem?.answer?.length > 0))
            setSavedSubmitButton(tempStatus)
            reset({ ...defaultValues })
        }
    }, [sectionQues, languageUse])


    /**
        * @function backToprevPage
        * @param ()
        * @description back To previous Page
    */
    const backToprevPage = () => {
        navigate('/')
    }

    useEffect(() => {
        setEventArray(Array.from({ length: sectionQues?.length }, (_, i) => (i)))
    }, [sectionQues])

    return <>
        {isQuestionLoading ? <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
            <BootstrapSpinner animation="grow" />
        </div> : <>
            <div>
                <Toaster />
            </div>

            <Row className=''>
                <Col className='' md='12' sm='12'>

                    <div className='left-border'>
                        <div className='my-question'>
                            {sectionQues?.length > 0 ? <form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Accordion activeKey={eventArray} alwaysOpen>
                                        {sectionQues?.length > 0 && sectionQues?.map((item, index) => (
                                            <>
                                                <Accordion.Item eventKey={index}>
                                                    <Accordion.Header>{languageUse === 'English' ? item?.section?.name : item?.section?.name_Malay}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {item?.questions?.filter(itemq => !itemq?.questionId?.isOptional).length > 0 && item?.questions?.filter(itemq => !itemq?.questionId?.isOptional).map((itemQ, indexQ) => (
                                                            <>
                                                                {itemQ?.questionId?.isOptional ? <></> : <div className='p-2' key={indexQ}>

                                                                    <div className='d-flex flex-row mb-1'>
                                                                        <span>{indexQ + 1}.</span>
                                                                        <span className='question-just ps-1'>{languageUse === 'English' ? itemQ?.questionId?.question : itemQ?.questionId?.question_Malay} <span className='text-danger'>*</span></span>
                                                                    </div>
                                                                    {/* Yes_Malay No_Malay */}
                                                                    <div className='d-flex flex-column ps-3'>
                                                                        <div className='form-check d-flex align-items-start'>
                                                                            <input type="radio" {...register(`sectionReqAnswers.${index}.${indexQ}.answer`)} value={languageUse === 'English' ? 'yes' : 'Ya'} className='mt-1' />
                                                                            <input type="hidden" {...register(`sectionReqAnswers.${index}.${indexQ}.id`)} value={itemQ?._id} />
                                                                            <label className='ps-1'>{languageUse === 'English' ? 'Yes' : 'Ya'}</label>
                                                                        </div>
                                                                        <div className='form-check d-flex align-items-start'>
                                                                            <input type="radio" {...register(`sectionReqAnswers.${index}.${indexQ}.answer`,)} value={languageUse === 'English' ? 'no' : 'Tidak'} className='mt-1' />
                                                                            <input type="hidden" {...register(`sectionReqAnswers.${index}.${indexQ}.id`)} value={itemQ?._id} />
                                                                            <label className='ps-1'>{languageUse === 'English' ? 'No' : 'Tidak'}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            </>
                                                        ))}

                                                        {item?.questions?.filter(itemq => itemq?.questionId?.isOptional).length > 0 && <div className='rounded bgColor-Optional p-3'>
                                                            {props.currentIndicator !== 'Demographics' && <h4>{languageUse === 'English' ? 'Optional Questions' : 'Soalan Pilihan'}</h4>}
                                                            {item?.questions?.filter(itemq => itemq?.questionId?.isOptional).map((itemQ, indexQ) => {
                                                                return <>
                                                                    <div className='rounded bgColor-Optional p-2' key={indexQ}>
                                                                        <div className='d-flex flex-row mb-1'>
                                                                            <span>{indexQ + 1}.</span>
                                                                            <span className='question-just ps-1'>{languageUse === 'English' ? itemQ?.questionId?.question : itemQ?.questionId?.question_Malay}</span>
                                                                        </div>
                                                                        {itemQ?.questionId?.options?.length > 0 ? <>
                                                                            {itemQ?.questionId?.options.map((opt, Optionindex) => (
                                                                                <div className='form-check d-flex align-items-start ps-4 mb-1' key={Optionindex}>
                                                                                    <input type={(props.currentIndicator === 'Demographics' && indexQ !== 4) ? "radio" : "checkbox"} {...register(`sectionOptAnswers.${index}.${indexQ}.answer`)} value={languageUse === 'English' ? opt?.ans : itemQ?.questionId?.options_Malay[Optionindex]?.ans_Malay} className='mt-1' />
                                                                                    <input type="hidden" {...register(`sectionOptAnswers.${index}.${indexQ}.id`)} value={itemQ?._id} />
                                                                                    <label className='ps-1'>{languageUse === 'English' ? opt?.ans : itemQ?.questionId?.options_Malay[Optionindex]?.ans_Malay}</label>
                                                                                    <input type="hidden" className='custom-other-label form-label rounded' {...register(`sectionOptAnswers.${index}.${indexQ}.otherAnswer`)} />
                                                                                </div>

                                                                            ))}
                                                                            {(watchSecOthers?.length > 0 && watchSecOthers[index][indexQ]?.answer && watchSecOthers[index][indexQ]?.answer?.length > 0 && (watchSecOthers[index][indexQ]?.answer?.includes("Other") || watchSecOthers[index][indexQ]?.answer?.includes("Lain-lain"))) ? <div className='d-flex flex-column mt-3'>
                                                                                <label className='ps-1' >{languageUse === 'English' ? 'Please Enter Other option here' : 'Sila Masukkan pilihan Lain di sini'}</label>
                                                                                <input type="text" className='custom-other-label form-label rounded' {...register(`sectionOptAnswers.${index}.${indexQ}.otherAnswer`)} />
                                                                                <input type="hidden" {...register(`sectionOptAnswers.${index}.${indexQ}.id`)} value={itemQ?._id} />
                                                                            </div> : <>
                                                                                <label className='ps-1 d-none' >{languageUse === 'English' ? 'Please Enter Other option here' : 'Sila Masukkan pilihan Lain di sini'}</label>
                                                                                <input type="hidden" className='custom-other-label form-label rounded' {...register(`sectionOptAnswers.${index}.${indexQ}.otherAnswer`)} />
                                                                                <input type="hidden" {...register(`sectionOptAnswers.${index}.${indexQ}.id`)} value={itemQ?._id} />
                                                                            </>
                                                                            }
                                                                        </> : <div className='rounded bgColor-Optional'>
                                                                            <div className='d-flex flex-column ps-4'>
                                                                                <div className='form-check d-flex align-items-start'>
                                                                                    <input type="radio" {...register(`sectionOptAnswers.${index}.${indexQ}.answer`,)} value={"true"} className='mt-1' />
                                                                                    <input type="hidden" {...register(`sectionOptAnswers.${index}.${indexQ}.id`)} value={itemQ?._id} />
                                                                                    <label className='ps-1'>True</label>
                                                                                </div>
                                                                                <div className='form-check d-flex align-items-start'>
                                                                                    <input type="radio" {...register(`sectionOptAnswers.${index}.${indexQ}.answer`,)} value={"false"} className='mt-1' />
                                                                                    <input type="hidden" {...register(`sectionOptAnswers.${index}.${indexQ}.id`)} value={itemQ?._id} />
                                                                                    <label className='ps-1'>False</label>
                                                                                </div>
                                                                                <div className='form-check d-flex align-items-start'>
                                                                                    <input type="radio" {...register(`sectionOptAnswers.${index}.${indexQ}.answer`,)} value={"Other"} className='mt-1' />
                                                                                    <input type="hidden" {...register(`sectionOptAnswers.${index}.${indexQ}.id`)} value={itemQ?._id} />
                                                                                    <label className='ps-1' >Others</label>
                                                                                </div>
                                                                                {(watchSecOthers?.length > 0 && watchSecOthers[index][indexQ]?.answer && watchSecOthers[index][indexQ]?.answer === 'Other') ? <div className='d-flex flex-column mt-3'>
                                                                                    <label className='ps-1' >{languageUse === 'English' ? 'Please Enter Other option here' : 'Malay_Please Enter Other option here'}</label>
                                                                                    <input type="text" className='custom-other-label form-label rounded' {...register(`sectionOptAnswers.${index}.${indexQ}.otherAnswer`)} />
                                                                                    <input type="hidden" {...register(`sectionOptAnswers.${index}.${indexQ}.id`)} value={itemQ?._id} />
                                                                                </div> : <>
                                                                                    <label className='ps-1 d-none' >{languageUse === 'English' ? 'Please Enter Other option here' : 'Malay_Please Enter Other option here'}</label>
                                                                                    <input type="hidden" className='custom-other-label form-label rounded' {...register(`sectionOptAnswers.${index}.${indexQ}.otherAnswer`)} />
                                                                                    <input type="hidden" {...register(`sectionOptAnswers.${index}.${indexQ}.id`)} value={itemQ?._id} />
                                                                                </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </>
                                                            })
                                                            }
                                                        </div>
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </>
                                        ))}
                                    </Accordion>
                                </Row>

                                {viewPage ? <></> : <>
                                    <Row>
                                        <Col>
                                            <div className='d-flex align-items-center p-3 ms-1'>
                                                <Button className="backButton me-4" variant='light' onClick={backToprevPage} disabled={isLoading}>{languageUse === 'English' ? 'Back' : 'Kembali'}</Button>
                                                <Button className="question-submit-button me-4" variant='outline-primary' color="primary" type='submit' disabled={isLoading}> {isLoading ? <Spinner variant='dark' size='sm' /> : languageUse === 'English' ? 'Submit' : 'Hantar'}</Button>
                                                <Button className="cancelButton me-4" variant="outline-light" onClick={handleReset} disabled={isLoading}>{languageUse === 'English' ? 'Clear' : 'Padam'}</Button>
                                                {savedSubmitButton && <Button className="text-light me-4" variant="success" disabled={isLoading}>{languageUse === 'English' ? 'Saved' : 'Disimpan'}</Button>}
                                            </div>
                                        </Col>
                                    </Row>
                                </>}

                            </form> : <>
                                <div className='ps-0'>
                                    <div className='w-100 rounded bgColor-noQ height-noQ d-flex align-items-center justify-content-center'>
                                        {languageUse === 'English' ? <p className=''>Please select the Dimension and choose Indicator to get questions.</p> : <p className=''>Sila pilih dimensi dan penunjuk untuk soalan dipaparkan.</p>}
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </>
        }
    </>
}

export default QuestionPage
