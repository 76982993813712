import { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Row, Col, UncontrolledTooltip, Spinner } from 'reactstrap';
import { Button, Spinner as BootstrapSpinner } from 'react-bootstrap';
import { RiErrorWarningFill } from "react-icons/ri";
import toast, { Toaster } from 'react-hot-toast'
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import CryptoJS from 'crypto-js';
import classNames from 'classnames';

// Utility-Service
import { getToken, getUser } from '../../utility/AuthService';

// API-Service
import { userProgressAnswerService } from '../../services/userQ&AService';
import { progressSectionQuestionService } from '../../services/sectionService';
import { getProgressAssociatedCompaniesSubmissionService } from '../../services/associatedCompamiesSubmissionDetalisService';

// Redux-Functions
import { selectLanguageUse } from '../../redux/slices/language.Slice';

const ProgressQuestionPage = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = useParams()
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId')

    const dimensionID = props.dimensionID;
    const authToken = getToken()

    const [isLoading, setLoading] = useState(false)
    const [isQuestionLoading, setQuestionLoading] = useState(false)
    const [offset, setOffset] = useState(0)
    const [sectionQues, setSectionQues] = useState([])
    const [savedSubmitButton, setSavedSubmitButton] = useState(false);
    const viewPage = location.pathname.includes('view')
    const decryptedSubId = CryptoJS.AES.decrypt(id, 'mySecretKey').toString(CryptoJS.enc.Utf8);
    const user = getUser()
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const { register, control, watch, handleSubmit, reset } = useForm({});
    const { fields: sectionReqField } = useFieldArray({
        control,
        name: 'sectionReqAnswers'
    })

    const watchSectionReqAnswers = watch("sectionReqAnswers");
    const redStar = ''

    /**
        * @function getQuestions
        * @param
        * @description handle API to get the Questions
    */
    const getSectionQuestions = async () => {
        try {
            setQuestionLoading(true)
            const result = await progressSectionQuestionService({
                authToken,
                proGress_Dimension: dimensionID,
                submissionId: decryptedSubId
            });
            setSectionQues(result?.data?.data?.list)
            setQuestionLoading(false)
        } catch (error) {
            setSectionQues([])
            setQuestionLoading(false)
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        if (dimensionID) {
            setOffset(0)
        }
    }, [dimensionID]);

    useEffect(() => {
        if (dimensionID) {
            getSectionQuestions()
        }
    }, [dimensionID, offset])

    /**
        * @function getAssociatedSectionQuestions
        * @param
        * @description handle API to get the Questions
    */
    const getAssociatedSectionQuestions = async () => {
        try {
            const result = await getProgressAssociatedCompaniesSubmissionService({
                authToken,
                submissionId: decryptedSubId,
                proGress_Dimension: dimensionID,
                userId: userId
            });
            setSectionQues(result?.data?.data?.list)
        } catch (error) {
            setSectionQues([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        if (userId) {
            getAssociatedSectionQuestions()
        }
    }, [dimensionID, userId])

    /**
        * @function answerSubmit
        * @param data
        * @description api to submit the answer
    */
    const answerSubmit = async (data) => {
        setLoading(true)
        try {
            const result = await userProgressAnswerService({
                authToken,
                data: data?.sectionReqAnswers?.filter(item => props?.currentDimension === 'Demographics' ? item?.yesAnswers : item?.answer)?.map(item => {
                    if (item?.answer === "Yes" || item?.answer === "Ya" || props?.currentDimension === 'Demographics') {
                        if (typeof item?.otherAnswer === 'string') {
                            return {
                                answer: props?.currentDimension === 'Demographics' ? (item?.answer ? item?.answer : 'Yes') : languageUse === 'English' ? item?.answer : sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options_Malay?.findIndex(optionItem => optionItem === item?.answer)],
                                answerMalay: props?.currentDimension === 'Demographics' ? (item?.answer ? item?.answer : 'Ya') : languageUse === 'English' ? sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options_Malay[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options?.findIndex(optionItem => optionItem === item?.answer)] : item?.answer,
                                id: item?.id,
                                yesAnswers: props?.currentDimension === 'Demographics' && Array.isArray(item?.yesAnswers) && item?.yesAnswers?.length > 0 ? languageUse === 'English' ? [...item?.yesAnswers, item?.otherAnswer] : [...item?.yesAnswers?.map(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options_Malay?.findIndex(optionItem => optionItem === yesAnswerItem)]), item?.otherAnswer] : typeof item?.yesAnswers === 'string' && languageUse === 'English' ? [item?.yesAnswers, item?.otherAnswer] : typeof item?.yesAnswers === 'string' ? [sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options_Malay?.findIndex(optionItem => optionItem === item?.yesAnswers)], item?.otherAnswer] : [item?.otherAnswer],
                                yesAnswersMalay: props?.currentDimension === 'Demographics' && Array.isArray(item?.yesAnswers) && item?.yesAnswers?.length > 0 ? languageUse === 'Malay' ? [...item?.yesAnswers, item?.otherAnswer] : [...item?.yesAnswers?.map(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options_Malay[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options?.findIndex(optionItem => optionItem === yesAnswerItem)]), item?.otherAnswer] : typeof item?.yesAnswers === 'string' && languageUse === 'Malay' ? [item?.yesAnswers, item?.otherAnswer] : typeof item?.yesAnswers === 'string' ? [sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options_Malay[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options?.findIndex(optionItem => optionItem === item?.yesAnswers)], item?.otherAnswer] : [item?.otherAnswer]
                            }
                        } else {
                            return {
                                answer: props?.currentDimension === 'Demographics' ? (item?.answer ? item?.answer : 'Yes') : languageUse === 'English' ? item?.answer : sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options_Malay?.findIndex(optionItem => optionItem === item?.answer)],
                                answerMalay: props?.currentDimension === 'Demographics' ? (item?.answer ? item?.answer : 'Ya') : languageUse === 'English' ? sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options_Malay[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options?.findIndex(optionItem => optionItem === item?.answer)] : item?.answer,
                                id: item?.id,
                                yesAnswers: ((Array.isArray(item?.yesAnswers) && item?.yesAnswers?.length > 0) || typeof item?.yesAnswers === 'string') ? languageUse === 'English' && Array.isArray(item?.yesAnswers) && item?.yesAnswers?.length > 0 ? item?.yesAnswers : Array.isArray(item?.yesAnswers) && item?.yesAnswers?.length > 0 ? item?.yesAnswers?.map(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options_Malay?.findIndex(optionItem => optionItem === yesAnswerItem)]) : languageUse === 'English' ? [item?.yesAnswers] : [sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options_Malay?.findIndex(optionItem => optionItem === item?.yesAnswers)]] : [],
                                yesAnswersMalay: ((Array.isArray(item?.yesAnswers) && item?.yesAnswers?.length > 0) || typeof item?.yesAnswers === 'string') ? languageUse === 'Malay' && Array.isArray(item?.yesAnswers) && item?.yesAnswers?.length > 0 ? item?.yesAnswers : Array.isArray(item?.yesAnswers) && item?.yesAnswers?.length > 0 ? item?.yesAnswers?.map(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options_Malay[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options?.findIndex(optionItem => optionItem === yesAnswerItem)]) : languageUse === 'Malay' ? [item?.yesAnswers] : [sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options_Malay[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options?.findIndex(optionItem => optionItem === item?.yesAnswers)]] : []
                            }
                        }
                    } else {
                        return {
                            answer: props?.currentDimension === 'Demographics' ? (item?.answer ? item?.answer : 'Yes') : languageUse === 'English' ? item?.answer : sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options_Malay?.findIndex(optionItem => optionItem === item?.answer)],
                            answerMalay: props?.currentDimension === 'Demographics' ? (item?.answer ? item?.answer : 'Ya') : languageUse === 'English' ? sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options_Malay[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.options?.findIndex(optionItem => optionItem === item?.answer)] : item?.answer,
                            id: item?.id,
                            yesAnswers: props?.currentDimension === 'Demographics' ? languageUse === 'English' ? [item?.yesAnswers] : [sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options_Malay?.findIndex(optionItem => optionItem === item?.yesAnswers)]] : [],
                            yesAnswersMalay: props?.currentDimension === 'Demographics' ? languageUse === 'Malay' ? [item?.yesAnswers] : [sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options_Malay[sectionQues?.find(sectionItem => sectionItem?._id === item?.id)?.questionId?.yes_options?.findIndex(optionItem => optionItem === item?.yesAnswers)]] : []
                        }
                    }
                })
            });
            toast.success(result?.data?.data?.message)
            props.setChangeDimensionName(!props.changeDimensionName)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    /**
        * @function onSubmit
        * @param data
        * @description submit the answer
    */
    const onSubmit = (data) => {
        if (data) {
            answerSubmit(data);
        } else {
            toast.error('Something went wrong')
        }
    };

    /**
        * @function handleReset
        * @param
        * @description reset all form data
    */
    const handleReset = () => {
        reset();
    };

    useEffect(() => {
        if (sectionQues) {
            reset()
            const defaultValues = {};
            let secReqDeafultValues = []
            if (sectionQues?.length > 0) {
                secReqDeafultValues = sectionQues.map((item, index) => {
                    if (item?.answer) {
                        return {
                            answer: languageUse === 'Malay' && item?.answerMalay ? item?.answerMalay : item?.answer,
                            id: item?._id,
                            yesAnswers: props?.currentDimension === 'Demographics' ? languageUse === 'English' && Array.isArray(item?.yesAnswers) && item?.yesAnswers?.filter(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem._id === item?._id)?.questionId?.yes_options?.some(yesItem => yesItem === yesAnswerItem))?.length > 1 ? item?.yesAnswers : languageUse === 'English' && Array.isArray(item?.yesAnswers) && item?.yesAnswers?.filter(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem._id === item?._id)?.questionId?.yes_options?.some(yesItem => yesItem === yesAnswerItem))?.length === 1 ? item?.yesAnswers[0] : languageUse === 'English' && typeof item?.yesAnswers === 'string' ? item?.yesAnswers : languageUse === 'Malay' && Array.isArray(item?.yesAnswersMalay) && item?.yesAnswersMalay?.filter(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem._id === item?._id)?.questionId?.yes_options_Malay?.some(yesItem => yesItem === yesAnswerItem))?.length > 1 ? item?.yesAnswersMalay : languageUse === 'Malay' && Array.isArray(item?.yesAnswersMalay) && item?.yesAnswersMalay?.filter(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem._id === item?._id)?.questionId?.yes_options_Malay?.some(yesItem => yesItem === yesAnswerItem))?.length === 1 ? item?.yesAnswersMalay[0] : languageUse === 'Malay' && typeof item?.yesAnswersMalay === 'string' ? item?.yesAnswersMalay : [] : languageUse === 'English' ? item?.yesAnswers.filter(itemOther => sectionQues[index]?.questionId?.yes_options.includes(itemOther)) : item?.yesAnswersMalay.filter(itemOther => sectionQues[index]?.questionId?.yes_options_Malay.includes(itemOther)),
                            otherAnswer: languageUse === 'English' ? item?.yesAnswers.filter(itemOther => !sectionQues[index]?.questionId?.yes_options.includes(itemOther)) : item?.yesAnswersMalay.filter(itemOther => !sectionQues[index]?.questionId?.yes_options_Malay.includes(itemOther))
                        }
                    }

                    if (watchSectionReqAnswers?.some(watchItem => watchItem?.id === item?._id)) {
                        return watchSectionReqAnswers?.find(watchItem => watchItem?.id === item?._id)
                    }
                    return {
                        answer: item?.answer,
                        id: item?._id,
                        yesAnswers: props?.currentDimension === 'Demographics' ? languageUse === 'English' && Array.isArray(item?.yesAnswers) && item?.yesAnswers?.filter(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem._id === item?._id)?.questionId?.yes_options?.some(yesItem => yesItem === yesAnswerItem))?.length > 1 ? item?.yesAnswers : languageUse === 'English' && Array.isArray(item?.yesAnswers) && item?.yesAnswers?.filter(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem._id === item?._id)?.questionId?.yes_options?.some(yesItem => yesItem === yesAnswerItem))?.length === 1 ? item?.yesAnswers[0] : languageUse === 'English' && typeof item?.yesAnswers === 'string' ? item?.yesAnswers : languageUse === 'Malay' && Array.isArray(item?.yesAnswersMalay) && item?.yesAnswersMalay?.filter(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem._id === item?._id)?.questionId?.yes_options_Malay?.some(yesItem => yesItem === yesAnswerItem))?.length > 1 ? item?.yesAnswersMalay : languageUse === 'Malay' && Array.isArray(item?.yesAnswersMalay) && item?.yesAnswersMalay?.filter(yesAnswerItem => sectionQues?.find(sectionItem => sectionItem._id === item?._id)?.questionId?.yes_options_Malay?.some(yesItem => yesItem === yesAnswerItem))?.length === 1 ? item?.yesAnswersMalay[0] : languageUse === 'Malay' && typeof item?.yesAnswersMalay === 'string' ? item?.yesAnswersMalay : [] : languageUse === 'English' ? item?.yesAnswers.filter(itemOther => sectionQues[index]?.questionId?.yes_options.includes(itemOther)) : item?.yesAnswersMalay.filter(itemOther => sectionQues[index]?.questionId?.yes_options_Malay.includes(itemOther)),
                        otherAnswer: languageUse === 'English' ? item?.yesAnswers.filter(itemOther => !sectionQues[index]?.questionId?.yes_options.includes(itemOther)) : item?.yesAnswersMalay.filter(itemOther => !sectionQues[index]?.questionId?.yes_options_Malay.includes(itemOther))
                    }
                })
                defaultValues.sectionReqAnswers = secReqDeafultValues;
            }
            reset({ ...defaultValues })
        }
    }, [sectionQues, languageUse])

    /**
        * @function backToprevPage
        * @param
        * @description back To previous Page
    */
    const backToprevPage = () => {
        navigate('/')
    }

    return <>
        {isQuestionLoading ? <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
            <BootstrapSpinner animation="grow" />
        </div> : <>
            <div>
                <Toaster />
            </div>

            <Row className=''>
                <Col className='' md='12' sm='12'>
                    <div className='left-border'>
                        <div className='my-question'>
                            {sectionQues?.length > 0 ? <form onSubmit={handleSubmit(onSubmit)}>
                                <Row className='p-4 pt-0'>
                                    <>
                                        {props?.currentDimension !== 'Demographics' && sectionQues?.length > 0 && sectionQues?.map((item, index) => (
                                            <>
                                                <div className='mb-3' key={index}>
                                                    <div className='d-flex'>
                                                        {item?.questionId?.isMultiStatement ? <span className='d-flex'>{index + 1} {<span className='ms-2'>{item?.questionId?.question && parse(`${languageUse === 'English' ? item?.questionId?.question : item?.questionId?.question_Malay} ${redStar}`)}</span>}{!item?.isOptional && <span className='text-danger'>*</span>}</span> : <span>{index + 1} {languageUse === 'English' ? item?.questionId?.question : item?.questionId?.question_Malay}{!item?.isOptional && <span className='text-danger'>*</span>}
                                                        </span>
                                                        }
                                                        {(item?.questionId?.toolTip || item?.questionId?.toolTip_Malay) && <>
                                                            <span id="UncontrolledTooltipExample"><RiErrorWarningFill size={20} /></span>
                                                            <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample">
                                                                {languageUse === 'English' ? item?.questionId?.toolTip : item?.questionId?.toolTip_Malay}
                                                            </UncontrolledTooltip>
                                                        </>}

                                                    </div>

                                                    <div className='d-flex flex-column ps-3'>
                                                        {languageUse === 'English' && item?.questionId?.options?.length > 0 && item?.questionId?.options?.map((optionItem, optionIndex) => {
                                                            return <div className='form-check d-flex align-items-start' key={optionIndex}>
                                                                <input type="radio" {...register(`sectionReqAnswers.${index}.answer`)} value={optionItem} className='mt-1' />
                                                                <input type="hidden" {...register(`sectionReqAnswers.${index}.id`)} value={item?._id} />
                                                                <label className='ps-1'>{optionItem}</label>
                                                            </div>
                                                        })}

                                                        {languageUse === 'Malay' && item?.questionId?.options_Malay?.length > 0 && item?.questionId?.options_Malay?.map((optionItem, optionIndex) => {
                                                            return <div className='form-check d-flex align-items-start' key={optionIndex}>
                                                                <input type="radio" {...register(`sectionReqAnswers.${index}.answer`)} value={optionItem} className='mt-1' />
                                                                <input type="hidden" {...register(`sectionReqAnswers.${index}.id`)} value={item?._id} />
                                                                <label className='ps-1'>{optionItem}</label>
                                                            </div>
                                                        })}
                                                    </div>
                                                    {watchSectionReqAnswers?.length > 0 && (watchSectionReqAnswers[index]?.answer === "Ya" || watchSectionReqAnswers[index]?.answer === "Yes") && item?.questionId?.yes_options?.length > 0 && <div className='bgColor-Optional p-3'>
                                                        <p>{languageUse === 'English' ? "If yes," : "Jika ya,"}</p>
                                                        <p>{languageUse === 'English' ? "Please select the applicable checkboxes from the following options" : "Sila pilih kotak semak yang berkenaan daripada pilihan berikut"}</p>
                                                        {languageUse === 'English' && item?.questionId?.yes_options?.length > 0 && item?.questionId?.yes_options?.map((yesOptionItem, yesOptionIndex) => {
                                                            return <div className='form-check d-flex align-items-start' key={yesOptionIndex}>
                                                                <input type="checkbox" {...register(`sectionReqAnswers.${index}.yesAnswers`)} value={yesOptionItem} className='mt-1' />
                                                                <label className='ps-1'>{yesOptionItem}</label>
                                                            </div>
                                                        })
                                                        }
                                                        {languageUse === 'Malay' && item?.questionId?.yes_options_Malay?.length > 0 && item?.questionId?.yes_options_Malay?.map((yesOptionItem, yesOptionIndex) => {
                                                            return <div className='form-check d-flex align-items-start' key={yesOptionIndex}>
                                                                <input type="checkbox" {...register(`sectionReqAnswers.${index}.yesAnswers`)} value={yesOptionItem} className='mt-1' />
                                                                <label className='ps-1'>{yesOptionItem}</label>
                                                            </div>
                                                        })
                                                        }
                                                    </div>
                                                    }
                                                    {watchSectionReqAnswers?.length > 0 && (watchSectionReqAnswers[index]?.answer === "Ya" || watchSectionReqAnswers[index]?.answer === "Yes") && item?.questionId?.isContainInput &&
                                                        <div className={classNames('d-flex flex-column', { ' mt-0 ps-3 bgColor-Optional': item?.questionId?.yes_options?.length > 0 }, { ' mt-3': !item?.questionId?.yes_options?.length > 0 })} >
                                                            {props?.currentDimension.toLowerCase() !== 'ghg emissions reduction' ? <>
                                                                <label className='ps-1' >{languageUse === 'English' ? item?.questionId?.inputLabel : item?.questionId?.inputLabel_Malay}</label>
                                                                <input type="text" className='custom-other-label form-label rounded' {...register(`sectionReqAnswers.${index}.otherAnswer`)} />
                                                            </> : <>
                                                                <label className='ps-1' >{languageUse === 'English' ? item?.questionId?.inputLabel : item?.questionId?.inputLabel_Malay}</label>

                                                                <div class="input-group mb-3 w-50">
                                                                    <input type="number" step={0.01} placeholder='120,482' className='custom-other-label form-control form-label rounded-start border-end-0 mb-0' {...register(`sectionReqAnswers.${index}.otherAnswer`)} />
                                                                    <div class="input-group-append bg-transparent border-start-0">
                                                                        <span class="input-group-text  bg-transparent input-group-left-border-0 border-start-0">MTCO2e</span>
                                                                    </div>
                                                                </div>
                                                            </>}
                                                        </div>}
                                                </div>
                                            </>
                                        ))}
                                        <>
                                            {props?.currentDimension === 'Demographics' && <div className='bgColor-Optional pt-3 mb-3'>
                                                {sectionQues?.length > 0 && sectionQues?.map((item, index) => (
                                                    <>
                                                        <div className='' key={index}>
                                                            <div className='d-flex'>
                                                                {item?.questionId?.isMultiStatement ? <span className='d-flex'>{index + 1} {<span className='ms-2'>{item?.questionId?.question && parse(`${languageUse === 'English' ? item?.questionId?.question : item?.questionId?.question_Malay} ${redStar}`)}</span>}{!item?.isOptional && <span className='text-danger'>*</span>}</span> : <span>{index + 1} {languageUse === 'English' ? item?.questionId?.question : item?.questionId?.question_Malay}{!item?.isOptional && <span className='text-danger'>*</span>}
                                                                </span>
                                                                }
                                                                {(item?.questionId?.toolTip || item?.questionId?.toolTip_Malay) && <>
                                                                    <span id="UncontrolledTooltipExample"><RiErrorWarningFill size={20} /></span>
                                                                    <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample">
                                                                        {languageUse === 'English' ? item?.questionId?.toolTip : item?.questionId?.toolTip_Malay}
                                                                    </UncontrolledTooltip>
                                                                </>}
                                                            </div>

                                                            {watchSectionReqAnswers?.length > 0 && item?.questionId?.yes_options?.length > 0 ? <div className='bgColor-Optional p-3'>

                                                                {languageUse === 'English' && item?.questionId?.yes_options?.length > 0 && item?.questionId?.yes_options?.map((yesOptionItem, yesOptionIndex) => {

                                                                    return <div className='form-check d-flex align-items-start' key={yesOptionIndex}>
                                                                        <input type={(props.currentDimension === 'Demographics' && (index !== 4)) ? "radio" : "checkbox"} {...register(`sectionReqAnswers.${index}.yesAnswers`)} value={yesOptionItem} className='mt-1' />
                                                                        <label className='ps-1'>{yesOptionItem}</label>
                                                                    </div>
                                                                })
                                                                }

                                                                {languageUse === 'Malay' && item?.questionId?.yes_options_Malay?.length > 0 && item?.questionId?.yes_options_Malay?.map((yesOptionItem, yesOptionIndex) => {

                                                                    return <div className='form-check d-flex align-items-start' key={yesOptionIndex}>
                                                                        <input type={(props.currentDimension === 'Demographics' && (index !== 4)) ? "radio" : "checkbox"} {...register(`sectionReqAnswers.${index}.yesAnswers`)} value={yesOptionItem} className='mt-1' />
                                                                        <label className='ps-1'>{yesOptionItem}</label>
                                                                    </div>
                                                                })
                                                                }
                                                            </div> : <>
                                                                <div className='d-flex flex-column ps-3'>
                                                                    {languageUse === 'English' && item?.questionId?.options?.length > 0 && item?.questionId?.options?.map((optionItem, optionIndex) => {
                                                                        return <div className='form-check d-flex align-items-start' key={optionIndex}>
                                                                            <input type="radio" {...register(`sectionReqAnswers.${index}.yesAnswers`)} value={optionItem} className='mt-1' />
                                                                            <input type="hidden" {...register(`sectionReqAnswers.${index}.id`)} value={item?._id} />
                                                                            <label className='ps-1'>{optionItem}</label>
                                                                        </div>
                                                                    })}

                                                                    {languageUse === 'Malay' && item?.questionId?.options_Malay?.length > 0 && item?.questionId?.options_Malay?.map((optionItem, optionIndex) => {
                                                                        return <div className='form-check d-flex align-items-start' key={optionIndex}>
                                                                            <input type="radio" {...register(`sectionReqAnswers.${index}.yesAnswers`)} value={optionItem} className='mt-1' />
                                                                            <input type="hidden" {...register(`sectionReqAnswers.${index}.id`)} value={item?._id} />
                                                                            <label className='ps-1'>{optionItem}</label>
                                                                        </div>
                                                                    })}
                                                                </div>

                                                            </>
                                                            }

                                                            {(watchSectionReqAnswers?.length > 0 && (watchSectionReqAnswers[index]?.yesAnswers?.includes("Other") || watchSectionReqAnswers[index]?.yesAnswers?.includes("Lain-lain"))) && <div className='d-flex flex-column mt-3'>

                                                                <label className='ps-1' >{languageUse === 'English' ? 'Please Enter Other option here' : 'Sila Masukkan pilihan Lain di sini'}</label>
                                                                <input type="text" className='custom-other-label form-label rounded' {...register(`sectionReqAnswers.${index}.otherAnswer`)} />
                                                                <input type="hidden" {...register(`sectionReqAnswers.${index}.id`)} value={index?._id} />

                                                            </div>
                                                            }
                                                        </div>
                                                    </>
                                                ))}
                                            </div>}
                                        </>
                                    </>
                                </Row>

                                {viewPage ? <></> : <>
                                    <Row>
                                        <Col>
                                            <div className='d-flex align-items-center p-3 ms-1'>
                                                <Button className="backButton me-4" variant='light' onClick={backToprevPage} disabled={isLoading}>{languageUse === 'English' ? 'Back' : 'Kembali'}</Button>
                                                <Button className="question-submit-button me-4" variant='outline-primary' color="primary" type='submit' disabled={isLoading}>{isLoading ? <Spinner variant='dark' size='sm' /> : languageUse === 'English' ? 'Submit' : 'Hantar'}</Button>
                                                <Button className="cancelButton me-4" variant="outline-light" onClick={handleReset} disabled={isLoading}>{languageUse === 'English' ? 'Clear' : 'Padam'}</Button>
                                                {savedSubmitButton && <Button className="text-light me-4" variant="success" disabled={isLoading}>{languageUse === 'English' ? 'Saved' : 'Disimpan'}</Button>}
                                            </div>
                                        </Col>
                                    </Row>
                                </>}

                            </form> : <>
                                <div className='ps-0'>
                                    <div className='w-100 rounded bgColor-noQ height-noQ d-flex align-items-center justify-content-center'>
                                        <p className=''>{languageUse === 'English' ? 'Please select the Dimension to get questions.' : 'Sila pilih Dimensi untuk mendapatkan soalan.'}</p>
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </>
        }
    </>
};

export default ProgressQuestionPage;
