import React from 'react'
import { Bar, Radar } from "react-chartjs-2";

import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,

    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
 
} from "chart.js";
ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ChartDataLabels,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);
import HorizontalBarChartStacked from '../../CustomChart/HorizontalBarChartStacked';
import { flatten } from "lodash";

const Learn = (props) => {

    // const labelList = ["1", "2", "3", "4", "5"]
    const labelList = [["All employees", "have completed", "online learning"], ["The relevant", "department", "participated", "in a vendor", "development", "program"], ["Management", "has attended", "a workshop"], ["The responsible", "employee has", "attended a", "training"], "Other"]

    const horizontalStackData = {
        labels: props?.learn?.map(item => Object.keys(item)[0]?.split(" ")),
        
        datasets: [
        
            {
                data: props?.learn ? flatten(props?.learn?.map(item => parseInt(Object.values(item)))) : [],
                backgroundColor: "#34A853",
                hoverBackgroundColor: "green",
                label: '',
                borderRadius: 5,
            },
            {
                data: props?.learn ? flatten(props?.learn?.map(item => 100 - parseInt(Object.values(item)))) : [],
                backgroundColor: "#EA4335",
                hoverBackgroundColor: "red",
                label: '',
                borderRadius: 5,
            }, 
        ]
    };

    const horizontalStackOptions = {
        responsive: true,
        
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                stacked: true,
            },
            y: {
                max: 100,
                ticks: {
                    // display: false,
                    callback: function(value, index, ticks) {
                        return `${value}%`;
                    }
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                // title: {
                //     display: true,
                //     text: 'Average Score'
                // },
                stacked: true,
            },
            xAxes: [
                {
                    scaleLabel: {
                        display: false
                    },
                    gridLines: {
                        display: false
                    },
                    stacked: true,
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    stacked: true,
                }
            ]
        },

        plugins: {
            legend: {
                display: false,
            },
            
            datalabels: {
                anchor: 'end',
                // clamp: false,
                align: 'start',
                color: "white",
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: "bold",
                },
                formatter: (value) => {
                    return `${value}%`
                },
            },
        },
        
        pointLabelFontFamily: "Quadon Extra Bold",
        scaleFontFamily: "Quadon Extra Bold",
    };

    return (
        <div className='p-2'>
            <h5 className='mb-3'>Learn</h5>
            <div>
                <HorizontalBarChartStacked data={horizontalStackData} options={horizontalStackOptions}/>
            </div>
            <h6 className='fw-bold text-dark text-center mt-3'>Type of learning activities that the Company has completed in the last year</h6>
        </div>
    )
};

export default Learn