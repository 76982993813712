import axios from 'axios'
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function getESGPerformanceDataService
* @param { token }
* @description This function is used to get data
*/
export const getESGPerformanceDataService = async ({ token }) => {
    const result = axios.get(`${baseUrl}users/get/esg/performance`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    return result;
};


/**
* @function filterESGPerformanceIndustryAndStateService
* @param { token, state, industry, toDate, fromDate}
* @description This function is used to get industry maturity data
*/
export const filterESGPerformanceIndustryAndStateService = async ({ token, country, state, industry, toDate, fromDate }) => {
    const result = axios.get(`${baseUrl}users/get/esg/performance?country=${country}&state=${state}&industry=${industry}&toDate=${toDate}&fromDate=${fromDate}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    return result;
};