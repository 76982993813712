// ** React Imports
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Offcanvas } from 'react-bootstrap';

// ** Reactstrap Imports
import { Row, Col, Container } from "reactstrap";

// ** Icons Imports
import { Menu } from "react-feather"

// Utility-Service
import { getUser } from "../../../utility/AuthService";
import ProgressQuestionPage from "../ProgressQuestionPage";
import ProgressSidebar from "../../components/layouts/ProgressSidebar";
import Header from "../../components/layouts/Navbar";
import Footer from '../../components/layouts/Footer'

//redux
import { selectLanguageUse } from '../../../redux/slices/language.Slice'

const Progress = () => {
    const user = getUser()

    const [show, setShow] = useState(false);
    const [dimensionName, setDimensionName] = useState();
    const [dimensionName_Malay, setDimensionName_Malay] = useState();
    const [currentDimension, setCurrentDimension] = useState([])
    const [dimensionID, setDimensionID] = useState();
    const [changeDimensionName, setChangeDimensionName] = useState(false);
    const [currentIndicator, setCurrentIndicator] = useState('')
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const handleSidebar = () => {
        setShow(!show);
    }

    return <Fragment>
        <Header />
        <Container>
            <Row>
                <Col md="12" sm="12" className="my-4">
                    <svg role='button' className="mb-4 d-md-none" onClick={handleSidebar} width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.25 13.6729H18.4167M3.25 7.17285H22.75M3.25 20.1729H22.75" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <h5>
                        {" "}
                        {languageUse === 'English' ? dimensionName : dimensionName_Malay}
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col md="3" sm="12" className="d-none d-md-block">
                    <ProgressSidebar dimensionName={dimensionName} setDimensionName={setDimensionName} setDimensionName_Malay={setDimensionName_Malay} setCurrentIndicator={setCurrentIndicator} setDimensionID={setDimensionID} changeDimensionName={changeDimensionName} handleSidebar={handleSidebar} currentDimension={currentDimension} setCurrentDimension={setCurrentDimension} />
                </Col>
                <Col md="9" sm="12" className="mt-3 mt-md-0">
                    <ProgressQuestionPage currentDimension={currentDimension} dimensionID={dimensionID} changeDimensionName={changeDimensionName} setChangeDimensionName={setChangeDimensionName} />
                </Col>
            </Row>
        </Container>
        <Offcanvas className='min-vw-100 d-md-none sidebar px-3' show={show} onHide={handleSidebar}>
            <Header />
            <div className='px-2 my-4'>
                <svg role='button' className="text-primary mb-4 d-md-none" onClick={handleSidebar} width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.25 13.6729H18.4167M3.25 7.17285H22.75M3.25 20.1729H22.75" stroke="#009EDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <ProgressSidebar dimensionName={dimensionName} setDimensionName={setDimensionName} setDimensionName_Malay={setDimensionName_Malay} setCurrentIndicator={setCurrentIndicator} setDimensionID={setDimensionID} changeDimensionName={changeDimensionName} handleSidebar={handleSidebar} currentDimension={currentDimension} setCurrentDimension={setCurrentDimension} />
            </div>
        </Offcanvas>
        <Footer />
    </Fragment>
}

export default Progress;
