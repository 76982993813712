
import React, { useState, useEffect } from 'react'
//custom componentes
import Analytics from './Analytics'
import IndicatorScoreAnalytics from './IndicatorScoreAnalytics'
import StateBasedAnalytics from './StateBasedAnalytics';
import IndustryBasedAnalytics from './IndustryBasedAnalytics';
//third party
import toast, { Toaster } from 'react-hot-toast'
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import StateComparisionAnalytics from './StateComparisionAnalytics';
import IndustryComparisionAnalytics from './IndustryComparisionAnalytics';
//API
import { getAllIndustryListService } from '../../../services/industryService';
import { dimensionListService, getActiveDimensionListService } from '../../../services/dimensionService';

// Utility-Service
import { getToken, getUser } from '../../../utility/AuthService'
//custom
import Selection from '../Selection/Selection';
//Redux
import { useSelector } from 'react-redux';
import { selectLanguageUse } from '../../../redux/slices/language.Slice'

const DataIndex = () => {
    const token = getToken()
    const user = getUser()
    const [isLoading, setLoading] = useState(false)
    const [pageLimit, setPageLimit] = useState(10)
    const [offsetCal, setoffsetCal] = useState(0)
    const [dimensionList, setDimensionList] = useState([])
    const [industryList, setIndustryList] = useState([])
    const [allDimensionList, setallDimensionList] = useState([])
    const [watchDimensionValue, setDimensionWatchValue] = useState();
    const [dimensions, setDimensions] = useState([]);
    const [dimensionValue, setDimensionValue] = useState(null);
    const languageUse = useSelector(selectLanguageUse || user?.language)
    const dimension = [
        { label: "Environment", value: "Environment" },
        { label: "Social", value: "Social" },
        { label: "Governance", value: "Governance" },
    ];

    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();

    /**
    * @function fetchData
    * @param (page)
    * @description Handle to get the dimension list
    */
    const fetchData = async () => {
        try {
            const result = await dimensionListService({
                authToken: token,
                filter: 'Active',
                limit: 20,
                offset: 0
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setDimensionList(result?.data?.data?.list)
            } else {
                setLoading(false)
                setDimensionList([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setDimensionList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    /**
    * @function fetchIndustryData
    * @param ()
    * @description to get all indistry list
    */
    const fetchIndustryData = async () => {
        try {
            const result = await getAllIndustryListService({
                token,
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setIndustryList(result?.data?.data?.list)
            } else {
                setLoading(false)
                setIndustryList([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setIndustryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        fetchData()
        fetchIndustryData()
    }, []);

    return (
        <div className='container mb-3'>
            <h2 className="mb-4">Analytics</h2>
            <div className='bg-white'>
                <Analytics dimensionList={dimensionList} />
                <IndicatorScoreAnalytics languageUse={languageUse} />
                <StateBasedAnalytics dimensionList={dimensionList} languageUse={languageUse} />
                <IndustryBasedAnalytics dimensionList={dimensionList} industryList={industryList} languageUse={languageUse} />
            </div>

            <h4 className='mb-1 mt-3'>Comparison</h4>
            <div className=''>
                <Selection className={'w-40'} options={dimension} title={'Dimension'} name={'dimension'} setWatchValue={setDimensionWatchValue} />
            </div>

            <div className='bg-white px-3 pb-3'>
                <StateComparisionAnalytics watchDimensionValue={watchDimensionValue} dimensionList={dimensionList} languageUse={languageUse} />
                <IndustryComparisionAnalytics watchDimensionValue={watchDimensionValue} dimensionList={dimensionList} industryList={industryList} languageUse={languageUse} />
            </div>

        </div>
    )
};

export default DataIndex
