import { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Toaster, toast } from 'react-hot-toast';
import { AiOutlineEye } from "react-icons/ai";
import { BiImport } from "react-icons/bi";
import { useSelector } from "react-redux";

// Utility-Service
import { getToken, getUser } from '../../../utility/AuthService';

// Redux-Functions
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// API-Service
import { getSubmittedDocumentServices, getProgressSubmittedDocumentServices } from '../../../services/uploadDocumentServices/getSubmittedDocumentServices';

const UploadedDocumentsModal = (props) => {
    const AWS_Url = process.env.REACT_APP_AWSURL;

    const token = getToken();
    const user = getUser()

    const languageUse = useSelector(selectLanguageUse || user?.language)

    const [uploadedDocInfo, setUploadedDocInfo] = useState([]);
    const [isLoading, setLoading] = useState(false);

    /**
        * @function getSubmittedDocs
        * @param
        * @description get Submitted Documents
    */
    const getSubmittedDocs = async () => {
        let result = []
        try {
            setLoading(true)
            result = await getSubmittedDocumentServices({
                authToken: token,
                SubmissionId: props?.submissionId

            });
            if (result?.data?.statusCode === 200) {
                setUploadedDocInfo(result?.data?.data)
                setLoading(false)
            } else {
                setLoading(false)
                setUploadedDocInfo([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setUploadedDocInfo([])
            toast.error(result?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function getProgressSubmittedDocs
        * @param
        * @description get Progress Submitted Documents
    */
    const getProgressSubmittedDocs = async () => {
        let result = []
        try {
            setLoading(true)
            result = await getProgressSubmittedDocumentServices({
                authToken: token,
                SubmissionId: props?.submissionId

            });
            if (result?.data?.statusCode === 200) {
                setUploadedDocInfo(result?.data?.data)
                setLoading(false)
            } else {
                setLoading(false)
                setUploadedDocInfo([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setUploadedDocInfo([])
            toast.error(result?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        if (props?.submissionId && !props?.progress) {
            getSubmittedDocs()
        }
        if (props?.submissionId && props?.progress) {
            getProgressSubmittedDocs()
        }
    }, [props?.submissionId, props?.progress])

    /**
        * @function viewDoc
        * @param (data)
        * @description to view Document
    */
    const viewDoc = (item) => {
        props?.setPdfItem(item)
        props?.handleShowPdfModalShow()
    };

    return <>
        <div>
            <Toaster />
        </div>
        <div>
            <Modal
                isOpen={props.modal}
                toggle={() => props.toggle()}
                size='lg'
            >
                <ModalHeader className='border-0' toggle={() => props.toggle()}>{languageUse === 'English' ? "Uploaded Documents" : "Dokumen yang dimuat naik"}</ModalHeader>
                {!isLoading &&
                    <ModalBody>
                        {uploadedDocInfo?.length > 0 ? <div className="table-responsive">
                            <table className="table table-bordered table-responsive">
                                <thead className="text-color-primary fw-bold">
                                    <tr>
                                        <td scope="col">{languageUse === 'English' ? "Dimension" : "Dimensi"}</td>
                                        <td scope="col">{languageUse === 'English' ? "Name" : "Nama"}</td>
                                        <td scope="col">{languageUse === 'English' ? "Description" : "Penerangan"}</td>
                                        <td scope="col">{languageUse === 'English' ? "Uploaded PDF" : "Muat naik pdf"}</td>
                                        <td scope="col">{languageUse === 'English' ? "Actions" : "Tindakan"}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {uploadedDocInfo?.length > 0 && uploadedDocInfo?.map((it, ind) => {
                                        return (
                                            it?.files?.map((item, index) => (
                                                <tr key={index}>
                                                    {index === 0 && <td rowSpan={it?.files?.length}>{it?.dimension?.name || it?.proGress_Dimension?.name}</td>}
                                                    {index === 0 && <td rowSpan={it?.files?.length}>{it?.name}</td>}
                                                    {index === 0 && <td rowSpan={it?.files?.length}>{it?.description}</td>}
                                                    <td className=''>{item ? `${languageUse === 'English' ? 'Document' : 'Dokumen'}-${index + 1}` : '--'}</td>
                                                    <td className='text-color-primary'>
                                                        <AiOutlineEye className='me-2' role='button' onClick={() => viewDoc(item)} />
                                                        <a href={`${AWS_Url}${item}`} target="_blank"><BiImport className='' role='button' /></a>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> : <div className='w-100 rounded bgColor-noQ d-flex align-items-center justify-content-center'>
                            <p className='py-2 mb-0'>{languageUse === 'English' ? "No Document Available" : "Tiada Dokumen Tersedia"}</p>
                        </div>
                        }
                    </ModalBody>
                }
            </Modal>
        </div>
    </>
};

export default UploadedDocumentsModal