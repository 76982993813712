import { useState, useEffect, useRef } from "react";
import { Row, Col, Spinner, Container, Label, FormGroup, Input } from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BiImport } from "react-icons/bi";
import { BsCheckCircleFill } from "react-icons/bs";
import { useForm, Controller } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import toast, { Toaster } from 'react-hot-toast'
import CryptoJS from 'crypto-js';
import { useSelector } from "react-redux";
import parse from 'html-react-parser';
import moment from 'moment';
import classNames from "classnames";

// Custom Component
import NavbarComponent from "../../components/layouts/Navbar";
import Footer from '../../components/layouts/Footer'
import ProgressPdfReportPage from "../pdfReport/ProgressPdfReportPage";
import SignatureModal from "../../components/modal/SignatureModal";

// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";

// Redux-Functions
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// API-Service
import { getProgressSubmittedDocumentServices } from "../../../services/uploadDocumentServices/getSubmittedDocumentServices";
import { getProgressAssessmentSummaryService } from "../../../services/progressReportServices/getProgressAssessmentSummaryService";
import { uploadApprovalInfoServices } from "../../../services/progressReportServices/uploadApprovalInfo";

const DashboardProgress = () => {
    ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);

    const [searchParams] = useSearchParams();
    const download = searchParams.get('download');

    const componentRef = useRef();
    const AWS_Url = process.env.REACT_APP_AWSURL

    const [isLoading, setLoading] = useState(false);
    const [dimensionData, setDimensionData] = useState([]);
    const [profileData, setProfileData] = useState();
    const [readiness, setReadiness] = useState();
    const [ccptLevel, setCcptLevel] = useState();
    const [scorePercent, setScorePercent] = useState();
    const [actionPlan, setActionPlan] = useState([]);
    const [questionAns, setQuestionAns] = useState([]);
    const [pdfContent, setPdfContent] = useState(false);
    const [overallVal, setOverallVal] = useState();
    const [overallValLebel, setOverallValLebel] = useState();
    const [overallColor, setOverallColor] = useState();
    const [uploadedDocInfo, setUploadedDocInfo] = useState([])
    const [userSign, setUserSign] = useState();
    const [userSignUploaded, setUserSignUploaded] = useState(false);
    const [approveInfoUploaded, setApproveInfoUploaded] = useState(false);
    const [approveInfo, setApproveInfo] = useState();
    const [lastSubmission, setLastSubmission] = useState();
    const [signatureModalShow, setSignatureModalShow] = useState(false);

    const authToken = getToken()
    const { SId } = useParams()
    const user = getUser()
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const { control, watch, reset, register, setError, clearErrors, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({
        mode: "onBlur"
    });

    const decryptedSubId = CryptoJS.AES.decrypt(SId, 'mySecretKey').toString(CryptoJS.enc.Utf8);

    /**
        * @function handleSignatureModalShow
        * @param
        * @description  used to show signature modal
    */
    const handleSignatureModalShow = () => {
        setSignatureModalShow(!signatureModalShow);
    }

    /**
        * @function getSubmittedDocs
        * @param
        * @description get Submitted Documents
    */
    const getSubmittedDocs = async () => {
        let result = []
        try {
            setLoading(true)
            result = await getProgressSubmittedDocumentServices({
                authToken: authToken,
                SubmissionId: decryptedSubId
            });
            if (result?.data?.statusCode === 200) {
                setUploadedDocInfo(result?.data?.data)
            } else {
                setLoading(false)
                setUploadedDocInfo([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setUploadedDocInfo([])
            toast.error(result?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getSubmittedDocs()
    }, []);

    /**
        * @function selectESGcolor
        * @param
        * @description used to select ESG color
    */
    const selectESGcolor = (value) => {
        if (value > 0 && value <= 20) {
            return '#E06666'
        } else if (value > 20 && value <= 40) {
            return '#FCE5CD'
        } else if (value > 40 && value <= 60) {
            return '#D9EAD3'
        } else if (value > 60 && value <= 80) {
            return '#B6D7A8'
        } else if (value > 80 && value <= 100) {
            return '#93C47D'
        }
    }

    useEffect(() => {
        if (dimensionData?.length > 0) {
            const labels = dimensionData?.length > 0 ? dimensionData?.map(item => languageUse === 'English' ? item.name : item.name_Malay) : [];
            labels.push(languageUse === 'English' ? 'Climate Action Readiness' : 'Kesediaan Tindakan Iklim');
            setOverallValLebel(labels)

            const totalValues = dimensionData?.length > 0 ? dimensionData?.map(item => (item.total)) : []
            let totalValuesSum = 0;
            totalValues.map(x => (totalValuesSum += x));

            const correctValues = dimensionData?.length > 0 ? dimensionData?.map(item => (item.correct)) : []
            let correctValuesSum = 0;
            correctValues.map(x => (correctValuesSum += x));

            const overallValue = ((correctValuesSum / totalValuesSum) * 100).toFixed(2)
            const ESGcolor = selectESGcolor(overallValue)
            const allColor = dimensionData?.length > 0 && dimensionData?.map(item => selectESGcolor(((item?.correct / item?.total) * 100).toFixed(2)))
            allColor.push(ESGcolor)
            setOverallColor(allColor)

            const chartData = dimensionData?.length > 0 && dimensionData?.map(item => ((item.correct / item.total) * 100).toFixed(2));
            chartData.push(overallValue);
            setOverallVal(chartData)
        }
    }, [dimensionData, languageUse]);

    const data = {
        labels: overallValLebel,
        datasets: [
            {
                label: "Score of Category",
                data: overallVal,
                backgroundColor: overallColor,
                borderColor: overallColor,
                borderWidth: 1
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Score of Category"
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                color: "black",
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: "bold",
                },
                formatter: (value) => {
                    return `${value}%`
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: true,
                },
                position: 'bottom',
                ticks: {
                    align: 'center'
                }
            },
            y: {
                max: 150,
                ticks: {
                    display: false
                },
                grid: {
                    display: false
                },
                border: {
                    display: true
                }
            }
        },
        maintainAspectRatio: true
    };

    const horizontalData = {
        labels: dimensionData?.length > 0 && [...dimensionData?.map((item) => languageUse === 'English' ? item.name : item.name_Malay), "Climate Action Readiness"],
        datasets: [
            {
                label: languageUse === 'English' ? "Your Score" : "Markah anda",
                data: dimensionData?.length > 0 && [
                    ...dimensionData?.map((item) => {
                        if (item.correct) {
                            return ((item?.correct / item?.total) * 100).toFixed(2)
                        } else {
                            return 0
                        }
                    }),
                    dimensionData?.map((item) => {
                        if (item.correct) {
                            return ((item?.correct / item?.total) * 100).toFixed(2)
                        } else {
                            return 0
                        }
                    }).reduce((prev, next) => parseFloat(prev) + parseFloat(next)) / 3
                ],
                backgroundColor: "#009EDB",
                borderColor: "#009EDB",
                borderWidth: 1,
            },
            {
                label: languageUse === 'English' ? "Peer Score" : "Skor Rakan Sebaya",
                data: dimensionData?.length > 0 && [
                    ...dimensionData?.map((item) => {
                        if (item?.industryScore) {
                            return ((item?.industryScore / item?.total) * 100).toFixed(2)
                        } else {
                            return 0
                        }
                    }),
                    dimensionData?.map((item) => {
                        if (item?.industryScore) {
                            return ((item?.industryScore / item?.total) * 100).toFixed(2)
                        } else {
                            return 0
                        }
                    }).reduce((prev, next) => parseFloat(prev) + parseFloat(next)) / 3
                ],
                backgroundColor: "#1E3250",
                borderColor: "#1E3250",
                borderWidth: 1,
            },
        ],
    };


    const horoizontalOptions = {
        responsive: true,
        indexAxis: 'y',
        plugins: {
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                    boxWidth: 20,
                    padding: 50
                },
                position: 'bottom',
                align: 'center'
            },
            title: {
                display: true,
                text: "Score of Category"
            },
            datalabels: {
                display: false
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: (value) => {
                        return `${value}%`
                    }
                },
                grid: {
                    display: false,

                },
                border: {
                    display: false,
                },
                position: 'bottom'
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                }
            }
        },
        maintainAspectRatio: true,

    };

    /**
        * @function getAssesmentSummary
        * @param
        * @description get Assesment Summary List
    */
    const getAssesmentSummary = async () => {
        try {
            setLoading(true);
            const result = await getProgressAssessmentSummaryService({
                authToken,
                SubmissionId: decryptedSubId
            });
            setProfileData(result?.data?.data?.profile)
            setReadiness(result?.data?.data?.readinessLeval)
            setCcptLevel(result?.data?.data?.ccptLevel)
            setActionPlan(result?.data?.data?.actionPlans)
            setDimensionData(result?.data?.data?.dimensionScoring)
            setScorePercent(result?.data?.data?.scorePercent)
            setQuestionAns(result?.data?.data?.questionAnswers)
            setUserSign(result?.data?.data?.approvedBySign)
            setApproveInfo(result?.data?.data?.approvedBy)
            setLastSubmission(result?.data?.data?.lastSubmissionDate)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setProfileData()
            setReadiness()
            setCcptLevel()
            setActionPlan([])
            setDimensionData([])
            setScorePercent()
            setQuestionAns([])
            setUserSign()
            setApproveInfo()
            setLastSubmission()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        getAssesmentSummary()
    }, [userSignUploaded, approveInfoUploaded])

    /**
        * @function handleApprovalInfo
        * @param ()
        * @description upload aprroval information
    */
    const handleApprovalInfo = async (data) => {
        try {
            setLoading(true);
            const result = await uploadApprovalInfoServices({
                authToken,
                submissionId: decryptedSubId,
                name: data?.name,
                desigantion: data?.designation,
                date: data?.date

            });
            if (result?.data?.statusCode === 200) {
                setLoading(false);
                setApproveInfoUploaded(!approveInfoUploaded)
                toast.success('Uploaded')
            }
        } catch (error) {
            setLoading(false);
            toast.error('Something went wrong.')
        }
    }

    /**
        * @function onSubmit
        * @param (data)
        * @description to submit
    */
    const onSubmit = data => {
        handleApprovalInfo(data)
    }

    useEffect(() => {
        const defaultValues = {};
        defaultValues.name = approveInfo?.name
        defaultValues.designation = approveInfo?.desigantion
        defaultValues.date = approveInfo?.date
        reset({ ...defaultValues })
    }, [approveInfoUploaded, approveInfo])

    /**
        * @function afterPrint
        * @param 
        * @description set pdf content false after the print
    */
    const afterPrint = () => {
        setPdfContent(false)
    }

    /**
        * @function pdfContenFunc
        * @param
        * @description handle pdf content
    */
    const pdfContenFunc = useReactToPrint({
        pageStyle: `@media print {
                  @page {
                    margin: 20px !important;
                  }
                  @page :footer {
                    display: none !important;
                  }

                  @page :header {
                    display: none !important;
                  }

                  html, body {
                    height: 99%; 
                    -webkit-print-color-adjust: exact;   
                  }
                }`,
        content: () => componentRef.current,
        documentTitle: `${profileData?.company}_UNCG`,
        onAfterPrint: afterPrint
    })

    /**
        * @function pdfDownloadFunc
        * @param
        * @description handle pdf download
    */
    const pdfDownloadFunc = () => {
        setPdfContent(true)
        const timer = setTimeout(() => {
            pdfContenFunc()
        }, 1000);

        return () => clearTimeout(timer);
    };

    useEffect(() => {
        if (download) {
            pdfDownloadFunc()
        }
    }, [readiness])

    return <>
        <div>
            <Toaster />
        </div>
        <NavbarComponent />

        {isLoading ? <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
            <Spinner animation="grow" />
        </div> : <>
            <Container className="pdfSize">
                <div className="d-flex justify-content-between">
                    <h2 className="mb-4">{languageUse === 'English' ? "PRO-GRESS Climate Action Maturity Report" : "Laporan Kematangan Tindakan Iklim PRO-GRESS"}</h2>
                    <div className="d-none d-lg-block">
                        <Button className="float-end authButton login-btn-min-width mb-4" variant='outline-primary' onClick={pdfDownloadFunc}>
                            {isLoading ? <Spinner variant='dark' size='sm' /> : <><BiImport style={{ fontSize: "20px", marginBottom: '3px' }}></BiImport> {languageUse === 'English' ? '  Report' : '  Laporan'}</>}
                        </Button>
                    </div>
                </div>
                <div className="profile">
                    <Row className="profile p-2 mb-0">
                        <Col className="" md="6" sm="12">
                            <p className="mb-0">{languageUse === 'English' ? 'Current Report Dated' : 'Laporan Semasa Bertarikh'} : <span className="bold">{moment(profileData?.updatedAt).format("DD-MM-YYYY")}</span></p>
                            <p className="mb-0">{languageUse === 'English' ? 'Company' : 'Syarikat'} : <span className="bold">{profileData?.company}</span></p>
                            <p className="mb-0">{languageUse === 'English' ? 'Company Registration Number' : 'No. Pendaftaran Syarikat'} : <span className="fw-bold">{profileData?.companyReg}</span></p>
                            <p className="mb-0">{languageUse === 'English' ? 'Username' : 'Nama Pengguna'} : <span className="bold">{profileData?.username}</span></p>
                            <p className="mb-0">
                                {languageUse === 'English' ? 'State' : 'Negeri'} : <span className="fw-bold">{profileData?.state}</span>
                            </p>
                        </Col>
                        <Col className="" md="6" sm="12">
                            <p className="mb-0">{languageUse === 'English' ? 'Last Submission Date' : 'Tarikh Penyerahan Terakhir'} :{" "}<span className="bold">{lastSubmission ? moment(lastSubmission).format("DD-MM-YYYY") : 'No Previous Submission'}</span></p>
                            <p className="mb-0">{languageUse === 'English' ? 'Industry' : 'Industri'} :{" "}<span className="bold">{languageUse === 'English' ? profileData?.industry?.name : profileData?.industry?.name_Malay}</span></p>
                            <p className="mb-0">
                                Email : <span className="bold">{profileData?.email}</span>
                            </p>
                            <p className="mb-0">
                                {languageUse === 'English' ? 'Country' : 'Negara'} : <span className="fw-bold">{profileData?.country}</span>
                            </p>
                            <p className="mb-0">
                                {languageUse === 'English' ? 'City' : 'Bandar'} : <span className="fw-bold">{profileData?.city}</span>
                            </p>
                        </Col>
                    </Row>
                </div>

                <Row className="p-0 my-2 d-none d-lg-block">
                    <table className="table table-bordered border-0">
                        <tbody>
                            <tr className="border-0">
                                <td rowSpan={2} className="ps-3 text-center fw-bold border">{languageUse === 'English' ? "Your Climate Action Maturity Level:" : "Tahap Kematangan Tindakan Iklim Anda:"}</td>
                                <td className="ps-3 border-0 text-center fw-bold text-dark bg-Laggard">{languageUse === 'English' ? 'Laggard' : 'Laggard'}</td>

                                <td className="ps-3 border-0 text-center fw-bold text-dark bg-Beginner">{languageUse === 'English' ? 'Beginner' : 'Pemula'}</td>

                                <td className="ps-3 border-0 text-center fw-bold text-dark bg-Intermediate">{languageUse === 'English' ? 'Intermediate' : 'Pertengahan'}</td>

                                <td className="ps-3 border-0 text-center fw-bold text-white bg-Advanced">{languageUse === 'English' ? 'Advanced' : 'Maju'}</td>

                                <td className="ps-3 border-0 text-center fw-bold text-white bg-Leader">{languageUse === 'English' ? 'Leader' : 'Ketua'} </td>
                            </tr>
                            <tr className="border-0">
                                <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Laggard': readiness?.leval === 'Laggard' || readiness?.leval_Malay === 'Laggard', })}>{languageUse === 'English' ? readiness?.leval === 'Laggard' ? <BsCheckCircleFill className="text-white" /> : '' : readiness?.leval_Malay === 'Laggard' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Beginner': readiness?.leval === 'Beginner' || readiness?.leval_Malay === 'Beginner', })}>{languageUse === 'English' ? readiness?.leval === 'Pemula' ? <BsCheckCircleFill className="text-white" /> : '' : readiness?.leval_Malay === 'Pemula' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Intermediate': readiness?.leval === 'Intermediate' || readiness?.leval_Malay === 'Pertengahan', })}>{languageUse === 'English' ? readiness?.leval === 'Intermediate' ? <BsCheckCircleFill className="text-white" /> : '' : readiness?.leval_Malay === 'Pertengahan' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Advanced': readiness?.leval === 'Advanced' || readiness?.leval_Malay === 'Advanced', })}>{languageUse === 'English' ? readiness?.leval === 'Maju' ? <BsCheckCircleFill className="text-white" /> : '' : readiness?.leval_Malay === 'Maju' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Leader': readiness?.leval === 'Leader' || readiness?.leval_Malay === 'Ketua', })}>{languageUse === 'English' ? readiness?.leval === 'Leader' ? <BsCheckCircleFill className="text-white" /> : '' : readiness?.leval_Malay === 'Ketua' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>

                {/* For Small device */}
                <Row className="p-0 my-2 d-block d-lg-none">
                    <h5 className="fw-bold">{languageUse === 'English' ? "Your Climate Action Maturity Level:" : "Tahap Kematangan Tindakan Iklim Anda:"}</h5>
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td className="ps-3 fw-bold text-dark" style={{ backgroundColor: '#E06666' }}>{languageUse === 'English' ? 'Laggard' : 'Laggard'}</td>
                                <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? readiness?.leval === 'Laggard' ? <BsCheckCircleFill /> : '' : readiness?.leval_Malay === 'Laggard' ? <BsCheckCircleFill /> : ''}</td>
                            </tr>

                            <tr>
                                <td className="ps-3 fw-bold text-dark" style={{ backgroundColor: '#FCE5CD' }}>{languageUse === 'English' ? 'Beginner' : 'Pemula'}</td>
                                <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? readiness?.leval === 'Beginner' ? <BsCheckCircleFill /> : '' : readiness?.leval_Malay === 'Pemula' ? <BsCheckCircleFill /> : ''}</td>
                            </tr>

                            <tr>
                                <td className="ps-3 fw-bold text-dark" style={{ backgroundColor: '#D9EAD3' }}>{languageUse === 'English' ? 'Intermediate' : 'Pertengahan'}</td>
                                <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? readiness?.leval === 'Intermediate' ? <BsCheckCircleFill /> : '' : readiness?.leval_Malay === 'Pertengahan' ? <BsCheckCircleFill /> : ''}</td>
                            </tr>

                            <tr>
                                <td className="ps-3 fw-bold text-white" style={{ backgroundColor: '#B6D7A8' }}>{languageUse === 'English' ? 'Advanced' : 'Maju'}</td>
                                <td className="ps-3 fw-bold text-center border-0">{languageUse === 'English' ? readiness?.leval === 'Advanced' ? <BsCheckCircleFill /> : '' : readiness?.leval_Malay === 'Maju' ? <BsCheckCircleFill /> : ''}</td>
                            </tr>

                            <tr>
                                <td className="ps-3 fw-bold text-white" style={{ backgroundColor: '#93C47D' }}>{languageUse === 'English' ? 'Leader' : 'Ketua'} </td>
                                <td className="ps-3 fw-bold text-center border-0">{languageUse === 'English' ? readiness?.leval === 'Leader' ? <BsCheckCircleFill /> : '' : readiness?.leval_Malay === 'Ketua' ? <BsCheckCircleFill /> : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>

                <Row className="mt-2 pt-2" style={{ backgroundColor: '#F4FBFE', borderRadius: '5px', border: '1px solid #009EDB' }}>
                    <h5 className="fw-bold mb-2">{profileData?.company}</h5>
                    <span className="textColor mb-2">{languageUse === 'English' ? readiness?.leval : readiness?.leval_Malay} :</span>

                    <p className="">{languageUse === 'English' ? readiness?.description : readiness?.description_Malay}</p>
                </Row>

                <Row className="justify-content-between align-items-center mt-2">
                    <Col
                        className="history-text-color px-0 mb-4 mb-md-0"
                        md="6"
                        sm="12"
                    >
                        <h4 className="ps-3 pt-2 mb-4">{languageUse === 'English' ? "Score of Dimensions" : "Skor Dimensi"}</h4>

                        <div className="mt-5 d-flex justify-content-center align-items-center">
                            <Bar data={data} options={options} height={questionAns?.length > 0 && questionAns[2]?.["Business Strategy Integration"]?.length > 0 && questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length > 8 ? 270 : 150}></Bar>
                        </div>
                    </Col>

                    <Col
                        className="history-text-color px-2 my-3"
                        md="6"
                        sm="12"
                    >
                        <div className="">
                            <table className="table table-bordered mb-0">
                                <thead className="table-head">
                                    <tr className="history-text-color">
                                        <th className="ps-3 border-end-0" scope="col">{languageUse === 'English' ? "GHG emissions" : "Pelepasan GHG"}</th>
                                        <th className="ps-3 border-start-0" scope="col"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className="ps-3">{languageUse === 'English' ? `Scope 1` : "Skop 1"}</td>
                                        {questionAns?.length > 0 && questionAns[1]?.["GHG emissions reduction"]?.length > 0 && questionAns[1]?.["GHG emissions reduction"][0]?.yesAnswers?.length > 0 ? questionAns[1]?.["GHG emissions reduction"][0]?.yesAnswers?.map((item, index) => (
                                            <td key={index} className="ps-3">{`${item} MTCO2e`}</td>
                                        )) : <td className="ps-3">N/A</td>}
                                    </tr>
                                    <tr>
                                        <td className="ps-3">{languageUse === 'English' ? `Scope 2` : "Skop 2"}</td>
                                        {questionAns?.length > 0 && questionAns[1]?.["GHG emissions reduction"]?.length > 0 && questionAns[1]?.["GHG emissions reduction"][1]?.yesAnswers?.length > 0 ? questionAns[1]?.["GHG emissions reduction"][1]?.yesAnswers?.map((item, index) => (
                                            <td key={index} className="ps-3">{`${item} MTCO2e`}</td>
                                        )) : <td className="ps-3">N/A</td>}
                                    </tr>
                                    <tr>
                                        <td className="ps-3">{languageUse === 'English' ? `GHG emission reduction target` : "Sasaran pengurangan pelepasan GHG"}</td>
                                        {questionAns?.length > 0 && questionAns[1]?.["GHG emissions reduction"]?.length > 0 && questionAns[1]?.["GHG emissions reduction"][2]?.answer ? <td className="ps-3 d-flex flex-column">{questionAns[1]?.["GHG emissions reduction"][2]?.answer === "Yes" ? `${questionAns[1]?.["GHG emissions reduction"][2]?.answer}, \n ${questionAns[1]?.["GHG emissions reduction"][2]?.yesAnswers} MTCO2e` : questionAns[1]?.["GHG emissions reduction"][2]?.answer}</td> : <td className="ps-3">N/A</td>}
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className="">
                            <table className="table table-bordered mb-0">
                                <thead className="table-head">
                                    <tr className="history-text-color">
                                        <th className="ps-3" scope="col">{languageUse === 'English' ? "Certification" : "Pensijilan"}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {questionAns?.length > 0 && questionAns[2]?.["Business Strategy Integration"]?.length > 0 && questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length > 0 ? questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="ps-3">{item}</td>
                                        </tr>
                                    )) : <tr>
                                        <td className="ps-3">N/A</td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>

                <Row className="border mt-5" >
                    <h4 className="pt-3">{languageUse === 'English' ? "Peer Comparison Bar" : "Carta Perbandingan Rakan Industri"}</h4>
                    <div className="mt-3">
                        <Bar style={{ marginRight: '5rem', paddingRight: '5rem', marginBottom: '5px' }} data={horizontalData} options={horoizontalOptions} height={100}></Bar>
                    </div>
                </Row>

                <Row className="my-3">
                    <h4 className="pt-2 my-3 fw-bold text-center">{languageUse === 'English' ? "CLIMATE TRANSITION ACTION PLAN" : "PELAN TINDAKAN TRANSISI IKLIM"}</h4>
                    <p>{languageUse === 'English' ? `This is an overview of Company X climate transition action plan. The action plan is developed according to 3 focus areas: climate governance, emissions reduction and business strategy integration. The management of Company X hereby commits to implement the following action plan within 18 months from the date of signature.`.replace(/Company X/g, profileData?.company) : `Ini ialah gambaran keseluruhan pelan tindakan peralihan iklim Syarikat X. Pelan tindakan dibangunkan mengikut 3 bidang fokus: tadbir urus iklim, pengurangan pelepasan dan penyepaduan strategi perniagaan. Pihak pengurusan Syarikat X dengan ini komited untuk melaksanakan pelan tindakan berikut dalam tempoh 18 bulan dari tarikh tandatangan.`.replace(/Syarikat X/g, profileData?.company)}</p>
                </Row>

                {actionPlan?.length > 0 && actionPlan?.map((itemAction, indexAction) => {
                    return <div key={indexAction}>
                        <Row>
                            <div className={classNames({ "bg-Progress": scorePercent <= 50, "bg-Leader": scorePercent > 50 })}>
                                <h4 className="pt-2 mb-3 fw-bold text-white">
                                    {languageUse === 'English' ? itemAction?.proGress_Dimension?.name ? parse(itemAction?.proGress_Dimension?.name) : '--' : itemAction?.proGress_Dimension?.name_Malay ? parse(itemAction?.proGress_Dimension?.name_Malay) : '--'}
                                </h4>
                                <p className="text-white">
                                    {languageUse === 'English' ? itemAction?.actionPlan ? parse(itemAction?.actionPlan) : '--' : itemAction?.actionPlan_Malay ? parse(itemAction?.actionPlan_Malay) : '--'}
                                </p>

                            </div>

                            <div className="my-2 px-0 table-responsive">
                                <table className="table table-bordered">
                                    <thead className={classNames('table-head', { "bg-Progress": scorePercent <= 50, "bg-Leader": scorePercent > 50 })}>
                                        <tr className="text-white">
                                            <th className="ps-3" scope="col">{languageUse === 'English' ? "Action Plan Objective" : "Objektif Pelan Tindakan"}</th>
                                            <th className="ps-3" scope="col">{languageUse === 'English' ? "Key Actions" : "Tindakan Utama"}</th>
                                            <th className="ps-3" scope="col">{languageUse === 'English' ? "Outcomes" : "Hasil"}</th>
                                            <th className="ps-3" scope="col">{languageUse === 'English' ? "Timeline" : "Garis masa"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="ps-3">
                                                {languageUse === 'English' ? itemAction?.objective ? parse(itemAction?.objective) : '--' : itemAction?.resources_Malay ? parse(itemAction?.resources_Malay) : '--'}
                                            </td>
                                            <td>
                                                {languageUse === 'English' ? itemAction?.process ? parse(itemAction?.process) : '--' : itemAction?.process_Malay ? parse(itemAction?.process_Malay) : '--'}
                                            </td>
                                            <td>
                                                {languageUse === 'English' ? itemAction?.output ? parse(itemAction?.output) : '--' : itemAction?.output_Malay ? parse(itemAction?.output_Malay) : '--'}
                                            </td>
                                            <td>
                                                {languageUse === 'English' ? itemAction?.timeline ? parse(itemAction?.timeline) : '--' : itemAction?.timeline_Malay ? parse(itemAction?.timeline_Malay) : '--'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                    </div>
                })}

                <Row className="d-flex align-items-end">
                    <Col className="d-flex flex-column" md="8" sm="12">
                        <p>{languageUse === 'English' ? "This Report Has Been Checked and Approved By :" : "Laporan Ini Telah Disemak dan Diluluskan Oleh :"}</p>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-1 d-flex w-50">
                                <Label className='form-label mb-0 d-flex align-items-center' for='name'>
                                    {languageUse === 'English' ? "Name" : "Nama"}&nbsp;:
                                </Label>
                                <Form.Control
                                    className="ms-2 border-0 border-bottom rounded-0"
                                    type="text"
                                    placeholder=""
                                    {...register("name", {
                                        required: languageUse === 'English' ? "Name Is Required" : "Nama Diperlukan"
                                    })}
                                />
                            </div>
                            {errors?.name?.message && <span className='text-danger text-capitalize'>{errors?.name?.message}</span>}

                            <div className="mb-1 d-flex w-50">
                                <Label className='form-label mb-0 d-flex align-items-center' for='designation'>
                                    {languageUse === 'English' ? "Designation" : "Jawatan"}&nbsp;:
                                </Label>
                                <Form.Control
                                    className="ms-2 border-0 border-bottom rounded-0"
                                    type="text"
                                    placeholder=""
                                    {...register("designation", {
                                        required: languageUse === 'English' ? "Designation Is Required" : "Jawatan Diperlukan"
                                    })}
                                />
                            </div>
                            {errors?.designation?.message && <span className='text-danger text-capitalize'>{errors?.designation?.message}</span>}

                            <div className="mb-1 ">
                                <FormGroup className="d-flex w-50">
                                    <Label className="d-flex align-items-center mb-0" for="exampleDate">{languageUse === 'English' ? "Date" : "Tarikh"}&nbsp;:</Label>

                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                className="border-0 border-bottom rounded-0"
                                                type="date"
                                                id="date"
                                                placeholder=""
                                                max={new Date().toISOString().split("T")[0]}
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: languageUse === 'English' ? 'Date is required' : 'Tarikh diperlukan'
                                        }}
                                    />
                                </FormGroup>
                            </div>
                            {errors?.date?.message && <span className='text-danger text-capitalize'>{errors?.date?.message}</span>}


                            <div className=''>
                                <Button className="mt-2 authButton login-btn-min-width" variant='outline-primary' color="primary" type="submit">
                                    {isLoading ? <Spinner variant='dark' size='sm' /> : languageUse === 'English' ? 'Save' : 'Simpan'}
                                </Button>
                            </div>
                        </Form>
                    </Col>

                    <Col className="d-flex flex-column justify-content-end pb-5" md="4" sm="12">
                        <div role="button" onClick={() => handleSignatureModalShow()} >
                            {userSign && <div className="">
                                <img src={`${AWS_Url}${userSign}`} width={120} alt="sign" />
                            </div>}
                            <hr className="w-100 mb-0"></hr>
                            <p>{languageUse === 'English' ? 'Signature' : 'Tandatangan'}</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <h4 className="pt-2 my-3 fw-bold">{languageUse === 'English' ? 'DISCLAIMER' : 'PENAFIAN'}</h4>
                    <p>
                        {languageUse === 'English' ? `The report and score calculations provided on this website are based on self-declared data submissions by ${profileData?.company}. The information provided in this report shall not be relied upon as a substitute for professional advice. UNGCMYB and its partner shall not be held liable for any errors, omissions, or inaccuracies in the data provided by ${profileData?.company} or for any actions taken in reliance on such information. ${profileData?.company} is solely responsible for the accuracy and completeness of the information they provide.` : `Laporan dan pengiraan markah yang disediakan di tapak web ini adalah berdasarkan penyerahan data yang diisytiharkan sendiri oleh ${profileData?.company}. Maklumat yang diberikan dalam laporan ini tidak boleh dipercayai sebagai pengganti nasihat profesional. UNGCMYB dan rakan kongsinya tidak akan bertanggungjawab atas sebarang kesilapan, peninggalan atau ketidaktepatan dalam data yang disediakan oleh ${profileData?.company} atau untuk sebarang tindakan yang diambil dengan bergantung pada maklumat tersebut. ${profileData?.company} bertanggungjawab sepenuhnya untuk ketepatan dan kesempurnaan maklumat yang mereka berikan.`}
                    </p>
                    <p className="fs-14">For more information & support, reach out to <a className="text-decoration-none" href="mailto:strategicpartnerships@alliancefg.com">strategicpartnerships@alliancefg.com</a></p>
                </Row>
            </Container>
            <ProgressPdfReportPage ref={componentRef} id={decryptedSubId} pdfContent={pdfContent} data={data} options={options} dimensionData={dimensionData} profileData={profileData} overallVal={overallVal} overallValLebel={overallValLebel} horoizontalOptions={horoizontalOptions} horizontalData={horizontalData} uploadedDocInfo={uploadedDocInfo} ccptLevel={ccptLevel} readiness={readiness} actionPlan={actionPlan} scorePercent={scorePercent} questionAns={questionAns} userSign={userSign} approveInfo={approveInfo} lastSubmission={lastSubmission} />
        </>
        }

        <Footer />
        <SignatureModal show={signatureModalShow} handleClose={handleSignatureModalShow} submissionId={decryptedSubId} setUserSignUploaded={setUserSignUploaded} userSignUploaded={userSignUploaded} />
    </>
};

export default DashboardProgress;
