import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function demographicsListService
    * @param ({ type, typeId })
    * @description call API to get the country/city/state list
*/
export const demographicsListService = async ({ type, typeId }) => {
    const result = await axios.get(`${baseUrl}users/country/StateCity?type=${type}&typeId=${typeId}`)
    return result;
}
