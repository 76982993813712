import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import { ChevronDown } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const ViewAllComapnyModal = (props) => {
    const navigate = useNavigate()

    const columns = [

        // {
        //     name: '',
        //     cell: (row, index) => pageCount > 1 ? ((pageCount - 1) * 10) + index + 1 : index + 1,
        //     width: "100px",
        // },
        {
            name: 'No.',
            cell: (row, index) => index === 0 ? 1 : index > 0 ? index + 1 : '--',
            width: "100px",
        },
        {
            name: 'Comapny Name',
            selector: (row) => row.company ? row.company : '--',
            // sortable: true,
            className: 'onlyCapitalized',

        },
        {
            name: 'User Name',
            selector: (row) => row.username ? row.username : '--',
            // sortable: true,
            className: 'onlyCapitalized',
            // style: {
            //     borderLeft: '1px solid green'
            // }
        },


    ];


    return (
        <>
            <Modal show={props.show} onHide={() => {
                props.handleClose()
            }} size='lg' backdropClassName='bg-dark'>
                {/* <Modal show={props.show} onHide={props.handleClose} fullscreen={true}> */}
                <Modal.Header>
                    {/* <Modal.Title>Are you sure to submit all the answers</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>

                    <div className='react-dataTable react-dataTable-selectable-rows border'>
                        <DataTable
                            customStyles={{
                                headCells: {
                                    style: {
                                        fontSize: '16px'
                                    }
                                },
                            }}
                            noHeader
                            columns={columns}
                            className='react-dataTable'
                            sortIcon={<ChevronDown size={10} />}
                            data={props?.fieldData}
                        // pagination
                        // paginationServer
                        // paginationTotalRows={totalEntry}
                        // paginationPerPage={countPerPage}
                        // onChangePage={(page) => setPageCount(page)}
                        // paginationComponent={CustomPagination}
                        // paginationDefaultPage={currentPage + 1}
                        // actions={actionsMemo}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button variant="secondary" onClick={() => {
                        props.handleClose()
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ViewAllComapnyModal