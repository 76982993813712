import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function dimensionListService
    * @param ({ authToken, filter, limit, offset })
    * @description call API to get the dimension list
*/
export const dimensionListService = async ({ authToken, filter, limit, offset }) => {
    const result = await axios.get(`${baseUrl}dimension?offset=${offset}&limit=${limit}&filter=${filter}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}

/**
    * @function progressDimensionListService
    * @param ({ authToken, submissionId, filter, limit, offset })
    * @description call API to get the progress dimension list
*/
export const progressDimensionListService = async ({ authToken, submissionId, filter, limit, offset }) => {
    const result = await axios.get(`${baseUrl}ProGress-dimension/list?submissionId=${submissionId}&offset=${offset}&limit=${limit}&filter=${filter}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}

/**
    * @function getActiveDimensionListService
    * @param ({ authToken })
    * @description call API to get the active dimension list
*/
export const getActiveDimensionListService = async ({ authToken }) => {
    const result = await axios.get(`${baseUrl}dimension?filter=Active`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
};

/**
    * @function getActiveProgressDimensionListService
    * @param ({ authToken })
    * @description call API to get the active progress dimension list
*/
export const getActiveProgressDimensionListService = async ({ authToken }) => {
    const result = await axios.get(`${baseUrl}ProGress-dimension/list?filter=Active`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
};
