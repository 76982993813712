import { useState, useEffect } from "react";
import { Container, NavbarBrand, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Label } from "reactstrap";
import Select from "react-select";
import toast from 'react-hot-toast';
import { LogOut, User, Lock } from "react-feather";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

import ChangePasswordModal from "../modal/ChangePasswordModal";

// Utility-Service
import { getToken, getUser, setUserSession, getReFreshToken } from "../../../utility/AuthService";

import logo from '../../../assets/images/logo.png';
import LogoProgress from '../../../assets/images/LogoProgress.png';

import { logoutService } from "../../../../src/services/authService";
import { languageSelectionService } from "../../../services/languageSelectionService/languageSelectionService";
import { getProfileService } from "../../../services/getProfileService";

//redux
import { submissionId } from '../../../redux/slices/submission.Slice'
import { setLanguageChoice, selectLanguageUse } from '../../../redux/slices/language.Slice'

const NavbarPage = () => {
    const Separation_Url = process.env.REACT_APP_SEPARATION
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);
    const token = getToken();
    const refreshToken = getReFreshToken();

    const user = getUser()

    const languageUse = useSelector(selectLanguageUse || user?.language);

    const [changePasswordModal, setChangePasswordModal] = useState(false);

    const ChangePasswordModaltoggle = () => {
        setChangePasswordModal(!changePasswordModal)
    };

    const { reset, handleSubmit } = useForm();

    const languages = [
        {
            label: "English",
            value: "English"
        },
        {
            label: "Malay",
            value: "Malay"
        }
    ];

    const [selectLanguage, setSelectLanguage] = useState(languages?.find(item => item.label === user?.language));

    /**
        * @function goToProfile
        * @param
        * @description to navigate user profile
    */
    const goToProfile = () => {
        navigate('/user/profile');
    };

    /**
        * @function getProfile
        * @param
        * @description to get profile data
    */
    const getProfile = async () => {
        let result = []
        try {
            setLoading(true)
            result = await getProfileService({
                authToken: token
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setUserSession(token, result?.data?.data, refreshToken)
                localStorage.setItem('accessToken', token);
                localStorage.setItem('refreshToken', refreshToken);
                localStorage.setItem('userData', JSON.stringify(result?.data?.data));
            } else {
                setLoading(false)
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            toast.error(result?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function handleSelectLanguage
        * @param
        * @description to select preferred language
    */
    const handleSelectLanguage = async (selectLanguage) => {
        if (selectLanguage) {
            try {
                const result = await languageSelectionService({
                    authToken: token,
                    language: selectLanguage?.value
                });
                if (result?.data?.statusCode === 200) {
                    setLoading(false)
                    getProfile()
                    dispatch(setLanguageChoice(selectLanguage?.value))
                } else {
                    setLoading(false)
                    toast.error(result?.response?.data?.message || 'Something went wrong')
                }

            } catch (error) {
                setLoading(false)
                toast.error(error?.response?.data?.message || 'Something went wrong')
            }
        }
    };

    /**
        * @function handleSelectLang
        * @param data
        * @description to select language
    */
    const handleSelectLang = async (data) => {
        setSelectLanguage(data);
        handleSelectLanguage(data)
        if (data.value === 'English') {
            i18next.changeLanguage('en')
        } else if (data.value === 'Malay') {
            i18next.changeLanguage('ms')
        }
    };

    const logOut = () => {
        logoutService();
        dispatch(submissionId());
        navigate("/login");
    };

    /**
        * @function onSubmit
        * @param (data)
        * @description to submit
    */
    const onSubmit = async (data) => {
    };

    useEffect(() => {
        const defaultValues = {};
        defaultValues.language = languages?.find(item => item.label === user?.language)
        reset({ ...defaultValues })
    }, [user?.language])

    return <>
        <Container className="px-0">
            <div className="d-md-flex justify-content-md-between align-items-center pt-3 pb-2">
                <div>
                    <NavbarBrand href="/">
                        {(Separation_Url === 'ESG') && <div className="main-logo">
                            <img src={logo} alt="logoNavbar" />
                        </div>}

                        {Separation_Url === 'PROGRESS' && <div className="main-logo">
                            <img src={LogoProgress} alt="logoNavbar" />
                        </div>}
                    </NavbarBrand>
                </div>
                <div className="right-menu d-flex justify-content-between align-items-center">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="d-flex align-items-center">
                            <Label className="mb-0">Language: &nbsp;</Label>
                            <Select
                                defaultValue={selectLanguage}
                                onChange={handleSelectLang}
                                options={languages}
                            />
                        </div>
                    </form>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="ms-5">
                        <DropdownToggle nav caret className="userName">
                            {user?.username}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={goToProfile}>
                                <User size={14} className="me-1" />
                                <span className="align-middle">{languageUse === 'English' ? 'Profile' : 'Profil'}</span>
                            </DropdownItem>
                            <DropdownItem onClick={ChangePasswordModaltoggle}>
                                <Lock size={14} className="me-1" />
                                <span className="align-middle">{languageUse === 'English' ? 'Change Password' : 'Tukar kata laluan'}</span>
                            </DropdownItem>
                            <DropdownItem className="logOut" onClick={logOut}>
                                <LogOut className="me-1" /> {languageUse === 'English' ? 'Logout' : 'Log keluar'}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
        </Container>
        <hr className="header-row" />

        <ChangePasswordModal modal={changePasswordModal} toggle={ChangePasswordModaltoggle} />
    </>
}

export default NavbarPage;
