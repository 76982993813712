import { useState, useEffect } from 'react'
import { Modal, ModalFooter, ModalHeader, ModalBody, Form, Label, Input, Button, FormFeedback, Spinner } from "reactstrap";
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Toaster, toast } from 'react-hot-toast';
import { Trash, Copy } from "react-feather";
import { useSelector } from "react-redux";

// Redux-Functions
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// Utility Service
import { getToken, getUser } from '../../../utility/AuthService';

// API Service
import { getProfileService } from '../../../services/getProfileService';
import { joinedAccessCodeService } from '../../../services/joinNewAccessCodeService';

const AccessCodeModal = (props) => {
    const token = getToken();
    const user = getUser();

    const languageUse = useSelector(selectLanguageUse || user?.language);

    const [isLoading, setLoading] = useState(false)
    const [toggleNewCodeBtn, setToggleNewCodeBtn] = useState(true);
    const [moreCodes, setMoreCodes] = useState([])

    const { register, watch, control, handleSubmit, reset, formState: { errors } } = useForm();

    const { fields: option_fields } = useFieldArray({
        control,
        name: "inputs"
    });
    const watchInputs = watch("inputs")

    /**
        * @function getAccessCode
        * @param
        * @description used to get access code
    */
    const getAccessCode = async () => {
        let result = []
        try {
            setLoading(true)
            result = await getProfileService({
                authToken: token
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setMoreCodes(result?.data?.data?.joinedAccessCode?.filter(item => item))
            } else {
                setLoading(false)
                setMoreCodes([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setMoreCodes([])
            toast.error(result?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getAccessCode()
    }, []);

    /**
        * @function handleJoinAccessCode
        * @param data
        * @description to get profile data
    */
    const handleJoinAccessCode = async (data) => {
        if (!data?.inputs && !data?.newAccessCode) {
            const deleteID = moreCodes?.splice(data, 1);
        }
        let result = []
        try {
            setLoading(true)
            result = await joinedAccessCodeService({
                authToken: token,
                joinedAccessCode: data?.inputs && data?.newAccessCode ? [...data?.inputs?.filter(item => item), data?.newAccessCode] : data?.inputs ? data?.inputs?.filter(item => item) : data?.newAccessCode ? [data?.newAccessCode] : moreCodes?.filter(item => item)
            });

            if (result?.data?.statusCode === 200) {
                setLoading(false)
                toast.success(languageUse === 'English' ? 'Code Updated' : 'Kod Dikemas Kini')
                props.toggle()
                getAccessCode()
            } else {
                setLoading(false)
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function toggleAddNewCodebtn
        * @param
        * @description used to toggle add new code btn
    */
    const toggleAddNewCodebtn = () => {
        setToggleNewCodeBtn(!toggleNewCodeBtn)
    }

    /**
        * @function handleDelete
        * @param id
        * @description to handle delete
    */
    const handleDelete = (id) => {
        handleJoinAccessCode(id)
    }

    /**
        * @function onSubmit
        * @param (data)
        * @description to submit
    */
    const onSubmit = data => {
        if (data?.inputs || data?.newAccessCode) {
            toggleAddNewCodebtn()
            handleJoinAccessCode(data)
        } else {
            toast.error(languageUse === 'English' ? 'You have to add new access code to save' : 'Anda perlu menambah kod akses baharu untuk menyimpan')
        }
    };

    useEffect(() => {
        const defaultValues = {};
        defaultValues.inputs = moreCodes
        defaultValues.accessCode = user?.ownAccessCode
        reset({ ...defaultValues })
    }, [moreCodes]);

    /**
        * @function handleCopyOwnAccessCode
        * @param
        * @description used to copy own access code
    */
    const handleCopyOwnAccessCode = () => {
        navigator.clipboard.writeText(user?.ownAccessCode)
        toast.success(`${user?.ownAccessCode} Copied`)
    }

    return <>
        <div>
            <Toaster />
        </div>
        <div>
            <Modal
                isOpen={props.modal}
                toggle={props.toggle}
                backdropClassName='bg-dark'
            >
                <ModalHeader className='border-0'>{languageUse === 'English' ? 'Access Code Management' : 'Pengurusan Kod Akses'}</ModalHeader>
                <Form className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        {user?.isCorporate && <div className='mb-4 pb-3 border-bottom d-flex flex-column align-items-start justify-content-between w-100'>
                            <Label className='form-label' for='accessCode'>
                                {languageUse === 'English' ? 'Access Code' : 'Kod akses'}
                            </Label>
                            <div className='d-flex w-100'>
                                <Controller
                                    id='accessCode'
                                    name='accessCode'
                                    control={control}
                                    render={({ field }) => (
                                        <Input {...field} type='text' placeholder='Access Code' className='fs-20' disabled />
                                    )}
                                />
                                <div className='mt- border rounded-2 ms-3' onClick={handleCopyOwnAccessCode}>
                                    <Button className='' type="button" color='transparent'>
                                        <Copy size={20} />
                                    </Button>
                                </div>
                            </div>
                        </div>}

                        {option_fields?.length > 0 && option_fields?.map((item, index) => (
                            <div className='my-4 pb-3 border-bottom d-flex align-items-center justify-content-between w-100'>
                                <div className="flex-grow-1 me-2">
                                    <label htmlFor="addedCode" className="form-labe">
                                        {`${languageUse === 'English' ? 'Added Code' : 'Kod Ditambah'} ${index + 1}`}
                                    </label>
                                    <input
                                        disabled
                                        type="text"
                                        className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                        {...register(`inputs.${index}`)}
                                    />
                                </div>
                                <div className='mt-4 ms-1 border rounded-2'>
                                    <Button className='' type="button" color='transparent' onClick={() => handleDelete(index)}>
                                        <Trash size={20} />
                                    </Button>
                                </div>
                            </div>
                        ))}

                        {!toggleNewCodeBtn && <div className='mb-1'>
                            <Label className='form-label' for='newAccessCode'>
                                {languageUse === 'English' ? 'Add New Access Code' : 'Tambah Kod Akses Baharu'}
                            </Label>
                            <Controller
                                id='newAccessCode'
                                name='newAccessCode'
                                control={control}
                                render={({ field }) => (
                                    <Input {...field} type='text' placeholder={languageUse === 'English' ? 'Add New Access Code' : 'Tambah Kod Akses Baharu'} invalid={errors.newAccessCode && true} />
                                )}
                            />
                            {errors.newAccessCode && <FormFeedback>{errors.newAccessCode.message}</FormFeedback>}
                        </div>}

                        {toggleNewCodeBtn && <div>
                            <Button className=" authButton w-100 mt-3" variant='outline-primary' onClick={toggleAddNewCodebtn}>
                                {languageUse === 'English' ? 'Add New Code' : 'Tambah Kod Baharu'}
                            </Button>
                        </div>}

                    </ModalBody>

                    {!toggleNewCodeBtn && <ModalFooter className='d-flex justify-content-between'>
                        <Button className=" authButton login-btn-min-width mb-4" variant='outline-primary' onClick={props.toggle} >
                            {languageUse === 'English' ? 'Cancel' : 'Batal'}
                        </Button>
                        <Button type='submit' className=" authButton login-btn-min-width mb-4" variant='outline-primary'>
                            {isLoading ? <Spinner variant='dark' size='sm' /> : languageUse === 'English' ? 'Save' : 'Simpan'}
                        </Button>
                    </ModalFooter>}
                </Form>
            </Modal>
        </div>
    </>
};

export default AccessCodeModal