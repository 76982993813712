// ** React Imports
import React, { Fragment, useState, forwardRef, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParam, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

// ** Reactstrap Imports
import {
    Row,
    Col,
    Container,
    Label,
    Spinner,
    Toast,
    Input,
    CardBody,
    CardTitle,
    CardHeader,
    FormFeedback,
} from 'reactstrap'

import { Button } from 'react-bootstrap'
import * as Icons from 'react-feather'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast, { Toaster } from 'react-hot-toast'

// auth/input-password-toggle
import InputPasswordToggle from '../../components/input-password-toggle/index'

// Utility-Service
import { getToken, setUserSession } from '../../../utility/AuthService'

// API-Service
import { loginService } from '../../../services/authService'

// Redux-Settings
import {
    selectPostDetail,
    setLoginDetails,
} from '../../../redux/slices/auth.Slice'
import { setLanguageChoice } from '../../../redux/slices/language.Slice';

const LoginPage = () => {
    const dispatch = useDispatch()
    const recaptchaRef = useRef(null);
    const [isLoading, setLoading] = useState(false)
    const [captchaPass, setCaptchaPass] = useState(false);
    const [recaptchaResponse, setRecaptchaResponse] = useState();
    const token = getToken()
    const navigate = useNavigate()

    useEffect(() => {
        if (token) {
            // toast.success('Event Added')
            navigate('/user/history')
        }
    })

    const LoginValidation = yup.object().shape({
        email: yup.string().email().required('Please enter email'),
        password: yup.string().required('Please enter password')
    })
    // ** Hooks
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(LoginValidation) })

    /**
    * @function handleLogin
    * @param (data)
    * @description handle log in api, store in local storage and cookies.
    */
    const handleLogin = async (data) => {
        // e.preventDefault();
        setLoading(true)
        try {
            const result = await loginService({
                email: data.email,
                password: data.password,
                repatcheResponse: recaptchaResponse,
                userType: 'User'
            });
            const { accessToken, refreshToken } = result.data.data.tokens;

            if (result?.data?.statusCode === 200) {
                reset()
                setLoading(false)
                const getData = result?.data.data

                if (getData?.tokens) {
                    setLoading(false)
                    toast.success('Login successfully')

                    setUserSession(accessToken, { ...getData?.user, language: 'English' }, refreshToken);
                    dispatch(setLoginDetails(getData?.user));
                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    // localStorage.setItem('userData', JSON.stringify({ ...getData?.user, language: 'English' }));
                    localStorage.setItem('userData', JSON.stringify({ ...getData?.user }));
                    navigate('/user/history');
                    dispatch(setLanguageChoice(getData?.user?.language))
                } else {
                    toast.error(result?.data?.data?.message || 'Something went wrong')
                    setLoading(false);
                    // ErrorToast(result)
                };
            } else {
                recaptchaRef.current.reset()
                toast.error('Something went wrong!')
                setLoading(false)
            };
            return false;

        } catch (error) {
            recaptchaRef.current.reset()
            setLoading(false);
            toast.error(error?.response?.data?.message || 'Something went wrong')
        };

    }

    /**
    * @function onSubmit
    * @param (data)
    * @description to submit
    */
    const onSubmit = data => {
        if (Object.values(data).every(field => field.length > 0)) {
            handleLogin(data)
        }
    }

    /**
    * @function signUp
    * @param ()
    * @description to navigate signUp page
    */

    const signUp = () => {
        navigate('/register')
    }

    /**
        * @function onCaptchaChange
        * @params value
        * @description set the captcha value
    */
    const onCaptchaChange = (value) => {
        setRecaptchaResponse(value)
        if (value) {
            setCaptchaPass(true)
        } else {
            setCaptchaPass(false)
        }
    }


    return (
        <>
            <Fragment>
                <Container>
                    <Row className="pt-4 pb-4">
                        <div>
                            <Toaster />
                        </div>

                        <Col xs lg="12" className='text-center'>
                            <h3 className='already-title auth-heading'>Don’t have an account? <span className='signIn' onClick={signUp}>Sign Up</span></h3>
                        </Col>
                    </Row>
                    <Row>
                        <div className="user-login d-flex flex-column mx-auto p-4 p-lg-5 pb-lg-3 authDiv text-start col-lg-5 col-md-6 col-sm-8">
                            <h1 className="sign">SIGN IN</h1>
                            <span className='login-border' />
                            <div>
                                <form className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
                                    <div className='my-5'>
                                        <div className='pb-4'>
                                            <div className='mb-4'>
                                                <Label className='form-label custom-label' for='email'>
                                                    Email
                                                </Label>
                                                <Controller
                                                    id='email'
                                                    name='email'
                                                    defaultValue=''
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Input {...field} type='email' placeholder='Enter registered email' invalid={errors.email && true} />
                                                    )}
                                                />
                                                {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
                                            </div>
                                            <div className='mb-4'>
                                                <Label className='form-label custom-label' for='password'>
                                                    Password
                                                </Label>
                                                <Controller
                                                    id='password'
                                                    name='password'
                                                    defaultValue=''
                                                    control={control}
                                                    render={({ field }) => (
                                                        <InputPasswordToggle {...field} placeholder='Password' invalid={errors.password && true} />
                                                    )}
                                                />
                                                {errors.password && <FormFeedback>{errors.password.message}</FormFeedback>}
                                            </div>
                                            <Link className="float-end anchor" to='/forgotPassword'>
                                                <small>Forgot Password?</small>
                                            </Link>
                                        </div>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            className='google-recaptcha'
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                            onChange={onCaptchaChange}
                                        />
                                    </div>
                                    <div className=''>
                                        <Button className="float-end authButton login-btn-min-width" variant='outline-primary' color="primary" disabled={!captchaPass} type="submit">
                                            {isLoading ? <Spinner variant='dark' size='sm' /> : 'Login'}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </Row>
                </Container>
            </Fragment>
        </>

    )
}

export default LoginPage
