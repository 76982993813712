import React from 'react'
import { Bar, Radar } from "react-chartjs-2";

import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,

    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
 
} from "chart.js";

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ChartDataLabels,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);
import { flatten } from "lodash";
import HorizontalBarChartStacked from '../../CustomChart/HorizontalBarChartStacked';


const Asses = (props) => {

    const labelList = [["Customers", "requested", "for data"], ["Collected", "employee", "feedback"], ["Regularly", "addressed", "during", "management", "meetings"], ["Conducted", "a materiality", "assessment"], "Other"]

    // const labelList = ["Customers requested for data", "Collected employee feedback", "Regularly addressed during management meetings", "Conducted a materiality assessment", "Other"]

    // const labelList = ["1", "2", "3", "4", "5"]


    const horizontalStackData = {
        labels: props?.asses?.map(item => Object.keys(item)[0]?.split(" ")),
        
        datasets: [
        
            {
                data: props?.asses ? flatten(props?.asses?.map(item => parseInt(Object.values(item)))) : [],
                backgroundColor: "#34A853",
                hoverBackgroundColor: "green",
                label: '',
                borderRadius: 5,
            },
            {
                data: props?.asses ? flatten(props?.asses?.map(item => 100 - parseInt(Object.values(item)))) : [],
                backgroundColor: "#EA4335",
                hoverBackgroundColor: "red",
                label: '',
                borderRadius: 5,
            }, 
        ]
    };

    const horizontalStackOptions = {
        responsive: true,
        
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                stacked: true,
            },
            y: {
                max: 100,
                ticks: {
                    // display: false,
                    callback: function(value, index, ticks) {
                        return `${value}%`;
                    }
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                // title: {
                //     display: true,
                //     text: 'Average Score'
                // },
                stacked: true,
            },
            xAxes: [
                {
                    scaleLabel: {
                        display: false
                    },
                    gridLines: {
                        display: false
                    },
                    stacked: true,
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    stacked: true,
                }
            ]
        },

        plugins: {
            legend: {
                display: false,
            },
            
            datalabels: {
                anchor: 'end',
                // clamp: false,
                align: 'start',
                color: "white",
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: "bold",
                },
                formatter: (value) => {
                    return `${value}%`
                },
            },
            tooltip: {
                callbacks: {
                    title: (context) => {
                        return context[0].label.replaceAll(',', ' ');
                    }
                }
            }
        },
        
        pointLabelFontFamily: "Quadon Extra Bold",
        scaleFontFamily: "Quadon Extra Bold",
    };
    
    // const horizontalStackOptions = {
    //     responsive: true,
        
    //     scales: {
    //         x: {
    //             grid: {
    //                 display: false,
    //             },
    //             border: {
    //                 display: false,
    //             },
    //             stacked: true,
    //         },
            
    //         y: {
    //             max: 100,
    //             ticks: {
    //                 // display: false,
    //                 callback: function(value, index, ticks) {
    //                     return `${value}%`;
    //                 }
    //             },
    //             grid: {
    //                 display: false,
    //             },
    //             border: {
    //                 display: false,
    //             },
    //             // title: {
    //             //     display: true,
    //             //     text: 'Average Score'
    //             // },
    //             stacked: true,
    //             // formatter: function(params) {
    //             //     return `${params.value}%`;
    //             // }
    //         },
    //         xAxes: [
    //             {
    //                 scaleLabel: {
    //                     display: false
    //                 },
    //                 gridLines: {
    //                     display: false
    //                 },
    //                 stacked: true,
    //                 // label: {
    //                 //     avoidCollisions: false
    //                 // }
                    
    //             }
    //         ],
    //         yAxes: [
    //             {
    //                 gridLines: {
    //                     display: false,
    //                 },
    //                 stacked: true,
    //             }
    //         ]
    //     },

    //     plugins: {
    //         legend: {
    //             display: false,
    //         },
            
    //         datalabels: {
    //             anchor: 'end',
    //             // clamp: false,
    //             align: 'start',
    //             color: "white",
    //             display: function (context) {
    //                 return context.dataset.data[context.dataIndex] > 0;
    //             },
    //             font: {
    //                 weight: "bold",
    //             },
    //             formatter: (value) => {
    //                 return `${value}%`
    //             },
    //         },
    //     },
        
    //     pointLabelFontFamily: "Quadon Extra Bold",
    //     scaleFontFamily: "Quadon Extra Bold",
    // };

    return (
        <div className='p-2'>
            <h5 className='mb-3'>Assess</h5>
            {/* <div id="chratScroll">
                <HorizontalBarChartStacked data={horizontalStackData} options={horizontalStackOptions}/>
            </div> */}
            <div>
                <HorizontalBarChartStacked data={horizontalStackData} options={horizontalStackOptions}/>
            </div>
            <h6 className='fw-bold text-dark text-center mt-3'>How the Company has assessed the importance of this topic</h6>
        </div>
    )
};

export default Asses