// ** React Imports
import React, { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";

// ** Reactstrap Imports
import {
    Row,
    Col,
    Spinner,
    Container,
} from "reactstrap";
import { Button } from "react-bootstrap";
import { BiImport } from "react-icons/bi";

// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";

import NavbarComponent from "../../components/layouts/Navbar";
import { Bar } from "react-chartjs-2";

import toast, { Toaster } from 'react-hot-toast'
import CryptoJS from 'crypto-js';
import moment from 'moment'

// Images
import logo from '../../../assets/images/logo.png'
import footerLogo from '../../../assets/images/FooterLogo.png'

import useWindowSize from '../../../hooks/windowSize'
// API-Service
import { getAssesmentSummaryService } from '../../../services/submissionService'
import { getImageIndicatorService } from '../../../services/indicatorService'

import PdfReportPage from '../../pages/pdfReport/PdfReportPage'
import PdfReportPage2 from '../../pages/pdfReport/PdfReportPage2'
import { element } from "prop-types";

const PdfReportPage3 = (props) => {
    ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

    const size = useWindowSize()
    // const dimensionData = props?.dimensionData;
    // const indicatorData = props?.indicatorData;
    // const selectedIndicatorData = props?.selectedIndicatorData;
    // const profileData = props?.profileData;
    // const ESGperformanceData = props?.ESGperformanceData;
    // const optionalQuesData = props?.optionalQuesData;
    // const requiredQuestions = props?.requiredQuestions;

    const [isLoading, setLoading] = useState(false);
    const [dimensionData, setDimensionData] = useState([]);
    const [indicatorData, setIndicatorData] = useState([]);
    const [selectedIndicatorData, setSelectedIndicatorData] = useState([]);
    const [profileData, setProfileData] = useState();
    const [ESGperformanceData, setESGperformanceData] = useState();
    const [optionalQuesData, setOptionalQuesData] = useState([]);
    const [requiredQuestions, setrequiredQuestions] = useState([]);

    const [pdfContent, setPdfContent] = useState(false);
    const [overallVal, setOverallVal] = useState();
    const [overallValLebel, setOverallValLebel] = useState();
    const [overallColor, setOverallColor] = useState();
    const [imageList, setImageList] = useState([])

    const authToken = getToken()
    const { SId } = useParams()
    const user = getUser()
    const decryptedSubId = CryptoJS.AES.decrypt(SId, 'mySecretKey').toString(CryptoJS.enc.Utf8);

    const colorLeval = [
        {
            leval: "Laggard",
            color_code: "#FF0000"
        },
        {
            leval: "Beginner",
            color_code: "#FF9900"
        },
        {
            leval: "Intermediate",
            color_code: "#B6D7A8"
        },
        {
            leval: "Advanced",
            color_code: "#93C47D"
        },
        {
            leval: "Leader",
            color_code: "#38761D"
        },
    ]

    /**
    * @function getImage
    * @param (rowRecord, index)
    * @description update indicator image list
    */
    const getImage = async (rowRecord, index) => {
        if (rowRecord) {
            try {
                const result = await getImageIndicatorService({
                    token: authToken,
                    file: rowRecord
                })
                const temp = imageList;
                temp[index] = `data:image/${rowRecord?.filename?.split(".")[1]};base64, ${Buffer.from(result?.data, "base64").toString("base64")}`
                setImageList(temp)
            } catch (error) {
                toast.error(error?.response?.data?.message || 'Something went wrong')
            }
        } else {
            const temp = imageList;
            temp[index] = ''
            setImageList(temp)
        }
    }

    const selectESGcolor = (value) => {
        if (value > 0 && value <= 20) {
            return '#FF0000'
        } else if (value > 20 && value <= 40) {
            return '#FF9900'
        } else if (value > 40 && value <= 60) {
            return '#B6D7A8'
        } else if (value > 60 && value <= 80) {
            return '#93C47D'
        } else if (value > 80 && value <= 100) {
            return '#38761D'
        }
    }

    useEffect(() => {
        if (dimensionData?.length > 0) {
            const labels = dimensionData?.length > 0 ? dimensionData?.map(item => item.dimension) : [];
            labels.push('ESG');
            // labels.push('Overall');
            setOverallValLebel(labels)

            const totalValues = dimensionData?.length > 0 ? dimensionData?.map(item => (item.total)) : []
            let totalValuesSum = 0;
            totalValues.map(x => (totalValuesSum += x));

            const correctValues = dimensionData?.length > 0 ? dimensionData?.map(item => (item.correct)) : []
            let correctValuesSum = 0;
            correctValues.map(x => (correctValuesSum += x));

            const overallValue = ((correctValuesSum / totalValuesSum) * 100).toFixed(2)
            const ESGcolor = selectESGcolor(overallValue)


            const allColor = dimensionData?.length > 0 && dimensionData?.map(item => colorLeval.find(element => element.leval === item.leval).color_code)
            allColor.push(ESGcolor)
            setOverallColor(allColor)

            const chartData = dimensionData?.length > 0 && dimensionData?.map(item => ((item.correct / item.total) * 100).toFixed(2));
            chartData.push(overallValue);
            setOverallVal(chartData)
        }
    }, [dimensionData]);

    const data = {
        labels: overallValLebel,
        datasets: [
            {
                label: "Score of Category",

                data: overallVal,
                backgroundColor: overallColor,
                // backgroundColor: dimensionData?.length > 0 ? dimensionData?.map(item => colorLeval.find(element => element.leval === item.leval).color_code) : [],

                borderColor: overallColor,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Score of Category",
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                color: "black",
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: "bold",
                },
                formatter: (value) => {
                    return `${value}%`
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: true,
                },
                position: 'bottom',
            },
            y: {
                max: 150,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: true,
                },
            },
        },
        maintainAspectRatio: true,

    };

    const horizontalData = {
        labels: indicatorData?.length > 0 && indicatorData?.map((item) => item.name),
        datasets: [
            {
                label: "Your score",

                data: indicatorData?.length > 0 && indicatorData?.map((item) => {
                    if (item.correct) {
                        return ((item?.correct / item?.total) * 100).toFixed(2)
                    } else {
                        return 0
                    }
                }),
                backgroundColor: indicatorData?.map((item) => item?.dimension?.color),

                borderColor: indicatorData?.map((item) => item?.dimension?.color),
                borderWidth: 1,
            },
            {
                label: "Peer Score",

                data: indicatorData?.length > 0 && indicatorData?.map((item) => {
                    if (item?.industryScore) {
                        return ((item?.industryScore / item?.total) * 100).toFixed(2)
                    } else {
                        return 0
                    }
                }),
                backgroundColor: indicatorData?.map((item) => item?.dimension?.peerColor),

                borderColor: indicatorData?.map((item) => item?.dimension?.peerColor),
                borderWidth: 1,
            },
        ],
    };


    const horoizontalOptions = {
        responsive: true,
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                labels: {
                    usePointStyle: true,
                    boxWidth: 20,
                    padding: 50,

                },

                position: 'bottom',
                align: 'center'
            },

            title: {
                display: true,
                text: "Score of Category",
            },
            datalabels: {

                display: false,

            },
        },
        scales: {
            x: {
                // max: 10,
                ticks: {
                    callback: (value) => {
                        return `${value}%`
                    }
                },
                grid: {
                    display: false,

                },
                border: {
                    display: false,
                },
                position: 'bottom',
            },
            y: {
                // max: 150,
                ticks: {
                    // display: false,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: true,

    };

    /**
     * @function getAssesmentSummary
     * @param ()
     * @description get Assesment Summary List
     */
    const getAssesmentSummary = async () => {
        const submissionID = localStorage.getItem('submissionId');

        try {
            setLoading(true);
            const result = await getAssesmentSummaryService({
                authToken,
                SubmissionId: decryptedSubId
            });

            setDimensionData(result?.data?.data?.dimensions)
            let select = []
            result?.data?.data?.dimensions.map((item) => {
                let temp = []
                result?.data?.data?.indicaters.filter(indicatorItem => indicatorItem.dimension.name === item.dimension).map((filteredItem) => {
                    temp = [...temp, colorLeval.findIndex(element => element.leval === filteredItem.leval)]
                })
                if (temp.every(element => element >= 2)) {
                    select = [...select, result?.data?.data?.indicaters.filter(indicatorItem => indicatorItem.dimension.name === item.dimension).reduce((prev, current) => (((prev?.correct / prev?.total) * 100).toFixed(2) > ((current?.correct / current?.total) * 100).toFixed(2)) ? prev : current)]
                } else {
                    select = [...select, result?.data?.data?.indicaters.filter(indicatorItem => indicatorItem.dimension.name === item.dimension).reduce((prev, current) => (((prev?.correct / prev?.total) * 100).toFixed(2) < ((current?.correct / current?.total) * 100).toFixed(2)) ? prev : current)]
                }
            })
            setSelectedIndicatorData(select)
            setIndicatorData(result?.data?.data?.indicaters)
            setProfileData(result?.data?.data?.profile)
            setESGperformanceData(result?.data?.data?.esgPerformance)
            setOptionalQuesData(result?.data?.data?.optionalQuestions)
            setrequiredQuestions(result?.data?.data?.requiredQuestions)

            await Promise.all(result?.data?.data?.indicaters.map(async (item, index) => {
                if (item?.file) {
                    await getImage(item?.file, index)
                } else {
                    await getImage(null, index)
                }
            }))

            setLoading(false);

        } catch (error) {
            setLoading(true);
            setSelectedIndicatorData([])
            setIndicatorData([])
            setProfileData()
            setESGperformanceData()
            setOptionalQuesData([])
            setrequiredQuestions([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
            setLoading(false);
        }
    }

    useEffect(() => {
        getAssesmentSummary()
    }, [])

    const higherLowerDetector = (item) => {
        if (item) {
            if (((item?.correct / item?.total) * 100).toFixed(2) >= ((item?.industryScore / item?.total) * 100).toFixed(2)) {
                return `${(((item?.correct / item?.total) * 100).toFixed(2) - ((item?.industryScore / item?.total) * 100).toFixed(2)).toFixed(2)}% higher`
            } else {
                return `${(((item?.industryScore / item?.total) * 100).toFixed(2) - ((item?.correct / item?.total) * 100).toFixed(2)).toFixed(2)}% lower`
            }
        } else {
            return ''
        }
    }

    return (
        <Fragment>
            <div className='container px-4 pdfFont'>
                {/* <div ref={ref} className='container px-4 pdfFont'> */}
                {/* <div ref={ref} className='container px-4 pdfClass'> */}
                {/* {props?.printing &&
                    <div className="d-flex justify-content-between mb-3">
                        <h2 className="pt-3">ESG MATURITY REPORT</h2>
                        <img src={logo} alt="logo" width={300} />
                    </div>
                } */}
                <table class="report-container">
                    <thead class="report-header">
                        <tr>
                            <th class="report-header-cell">
                                <div className="header-info">
                                    {/* <div className="header-info" style={{
                                    paddingRight: size?.width >= 1400 && '12rem'
                                }}> */}
                                    <div className="d-flex justify-content-between mb-5">
                                        <h2 className="pt-3">ESG MATURITY REPORT</h2>
                                        <img src={logo} alt="logo" width={650} />
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    {/* <tfoot class="report-footer">
                        <tr>
                            <td class="report-footer-cell">
                                <div class="footer-info position-relative py-3 my-3">
                                    <span className="position-absolute bottom-0 end-0" style={{marginRight:'21px', marginBottom:'13px'}}>1</span>
                                    <img className="position-absolute bottom-0 end-0" src={footerLogo} alt="footerLogo" width={50} />
                                </div>
                            </td>
                        </tr>
                    </tfoot> */}
                    <tbody class="report-content">
                        <tr>
                            <td class="report-content-cell">
                                <div class="main">
                                    <div class="article">
                                        <div className="profile">
                                            {/* <div className="profile" style={{ paddingRight: size?.width >= 1400 && '7rem' }}> */}

                                            <Row className="profile px-5 pt-4">
                                                <Col className="" md="6" sm="12">
                                                    <p>
                                                        Date : <span className="bold">{moment(profileData?.updatedAt).format("DD-MM-YYYY")}</span>
                                                    </p>
                                                    <p>
                                                        Company Name : <span className="bold">{profileData?.company}</span>
                                                    </p>
                                                    <p>Company Registration Number : <span className="fw-bold">{profileData?.companyReg}</span></p>
                                                </Col>
                                                <Col className="" md="6" sm="12">
                                                    <p>
                                                        Industry : <span className="bold">{profileData?.industry?.name}</span>
                                                    </p>
                                                    {/* <p className="mb-0">
                                                        Company Registration Number : <span className="fw-bold">{profileData?.companyReg}</span>
                                                    </p> */}
                                                </Col>
                                                <hr></hr>
                                            </Row>

                                            {dimensionData?.length > 0 &&
                                                <Row className="profile px-5 mb-4">
                                                    <Col className="" md="6" sm="12">
                                                        <p>
                                                            ESG Maturity Level : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === ESGperformanceData?.leval)?.color_code }}>{ESGperformanceData?.leval}</span>
                                                        </p>
                                                        <p>
                                                            Environmental Maturity Level :{" "}
                                                            <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[0]?.leval)?.color_code }}>
                                                                {dimensionData[0]?.leval}
                                                            </span>
                                                        </p>
                                                    </Col>
                                                    <Col className="" md="6" sm="12">
                                                        <p className="mb-0">
                                                            Social Maturity Level : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[1]?.leval)?.color_code }}>{dimensionData[1]?.leval}</span>
                                                        </p>
                                                        <p className="mb-0">
                                                            Governance Maturity Level : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[2]?.leval)?.color_code }}>{dimensionData[2]?.leval}</span>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            }

                                        </div>


                                        <Row className="justify-content-evenly" >
                                            {/* <Row className="justify-content-evenly" style={{ paddingRight: size?.width >= 1400 && '10rem' }}> */}
                                            <Col
                                                className="history-text-color px-0 mb-4 mb-md-0"
                                                md="5"
                                                sm="12"
                                            >
                                                <h4 className="ps-3 pt-2 mb-4">Score of Category</h4>
                                                <div className="mt-5 d-flex justify-content-start align-items-center">
                                                    <Bar className="ps-1 pe-2 chart-wrapper" data={data} options={options} height={220}></Bar>
                                                </div>
                                            </Col>
                                            <Col className="history-text-color px-0" md="6" sm="12">
                                                <div>
                                                    <h4 className="ps-3 pt-2 mb-4">Score of Indicator</h4>
                                                    <table className="table border">
                                                        <thead className="table-head">
                                                            <tr>
                                                                <td scope="col">Dimension</td>
                                                                <td scope="col">Indicator</td>
                                                                <td scope="col" className="text-center">
                                                                    Your Score
                                                                </td>
                                                                <td scope="col" className="text-center">
                                                                    Peer Score
                                                                </td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {indicatorData?.length > 0 && [...new Set(indicatorData.map(item => item?.dimension?._id))].map((it, ind) => {
                                                                return (
                                                                    indicatorData?.filter(item => item?.dimension?._id === it).map((item, index) => (
                                                                        <tr key={index}>
                                                                            {index === 0 && <td rowSpan={indicatorData?.filter(item => item?.dimension?._id === it).length} style={{ backgroundColor: '#f4fbfe' }}>{item?.dimension?.name ? `${item?.dimension?.name}` : '--'}</td>}

                                                                            <td className="">{item.name ? `${item?.name}` : '--'}</td>
                                                                            <td className="text-center">{item?.correct ? `${((item?.correct / item?.total) * 100).toFixed(2)}%` : '--'}</td>
                                                                            <td className="text-center">{item?.industryScore ? `${((item?.industryScore / item?.total) * 100).toFixed(2)}%` : '--'}</td>
                                                                        </tr>

                                                                    )))
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="" style={{ marginTop: '5px' }} >
                                            <h4 className="pt-3 px-5">Peer Comparison Bar</h4>
                                            <div className="mt-5">
                                                {/* <Bar className="ps-1 pe-4" data={props.horizontalData} options={props.horoizontalOptions}></Bar> */}
                                                <Bar style={{ marginRight: '10rem', paddingRight: '5rem' }} data={horizontalData} options={horoizontalOptions}></Bar>
                                            </div>
                                        </Row>

                                        <Row className="mt-5" style={{ pageBreakBefore: 'always' }}>
                                            {/* <Row className="mt-5" style={{ pageBreakBefore: 'always', paddingRight: size?.width >= 1400 && '10rem' }}> */}
                                            <h4 className="pt-2 mb-4 fw-bold">ESG performance</h4>
                                            <h4 className="pt-2 textColor">{profileData?.company} has an {ESGperformanceData?.leval} level</h4>
                                            <p className="pt-2 mb-4">{ESGperformanceData?.data?.esgDescription.replace("SME X", profileData?.company)}</p>

                                            <h5><hr></hr></h5>
                                        </Row>

                                        {dimensionData?.length > 0 && dimensionData?.map((dimensionItem, dimensionIndex) => (
                                            <Row className="mt-5">
                                                {/* <Row className="mt-5" style={{ paddingRight: size?.width >= 1400 && '10rem' }}> */}
                                                <h4 className="pt-2 mb-4 fw-bold">{dimensionItem?.dimension} performance</h4>
                                                {/* <h4 className="pt-2 textColor">{profileData?.company} has an {dimensionItem?.leval} level</h4> */}
                                                <h4 className="pt-2 textColor">{`SME X shows ${dimensionItem?.leval} level in the ${dimensionItem?.dimension} dimension which is evident for the ${selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name} indicator which scores ${higherLowerDetector(selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id))} than peers .`.replace("SME X", profileData?.company)}</h4>
                                                <p className="pt-2 mb-4">{dimensionItem?.performance?.description.replace("SME X", profileData?.company)}</p>
                                                <h5 className="pt-2 fw-bold">The company undertakes the following activities on {dimensionItem?.dimension} topics:</h5>

                                                {optionalQuesData?.length > 0 && optionalQuesData?.map((optItem, optIndex) => (
                                                    <Row className="mt-1">

                                                        {dimensionItem?.dimension === optItem?.dimension ? <>

                                                            {/* {optItem?.questons?.filter(itemInd => itemInd.questions?.length > 0 && itemInd?.questions?.filter(item => item.answer.length > 0)).map((itemInd, indexInd) => (
                                
                                                                <div className="ps-4 mb-2">
                                                                    {itemInd?.questions?.filter(item => item?.answer?.length > 0).map((itemQ, indexQ) => (
                                                                        <div className="">
                                                                            {indexQ === 0 && <p>{itemInd.indicater}</p>}
                                                                            <p key={indexQ} className="">{indexInd + 1}.{indexQ + 1}. {itemQ?.answer.join(', ')}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))} */}

                                                            {optItem?.questons?.filter(itemInd => itemInd.questions?.length > 0 && itemInd?.questions).map((itemInd, indexInd) => (

                                                                <div className="ps-4 mb-2">
                                                                    {itemInd?.questions?.map((itemQ, indexQ) => (
                                                                        <div className="">
                                                                            {indexQ === 0 && <p>{itemInd.indicater}</p>}
                                                                            {itemQ?.answer?.length > 0 ? <p key={indexQ} className="">{indexInd + 1}.{indexQ + 1}. {itemQ?.answer?.join(', ')}</p> : <p>{indexInd + 1}.{indexQ + 1}. None</p>
                                                                            }

                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </> : <></>}

                                                    </Row>
                                                ))}

                                                <h5><hr></hr></h5>
                                            </Row>
                                        ))}

                                        <Row className="profile">
                                            {/* <Row className="profile" style={{ paddingBottom: '1rem', paddingRight: size?.width >= 1400 && '13rem' }}> */}
                                            <h4 className="pt-2 mb-4 fw-bold">SDG MAPPING</h4>

                                            <div className="px-2">
                                                <table className="table table-bordered">
                                                    <thead className="table-head">

                                                    </thead>

                                                    <tbody>
                                                        {indicatorData?.length > 0 && indicatorData?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="ps-3 textColor">{item?.name ? `${item?.name}` : '--'}</td>
                                                                <td className="ps-3">{item?.performance?.description ? `${item?.performance?.description?.replace("SME X", profileData?.company)}` : '--'}</td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </Row>
                                        <Row className="profile">
                                            {/* <Row className="profile" style={{ paddingRight: size?.width >= 1400 && '10rem' }}> */}
                                            <h4 className="pt-2 mt-2 fw-bold">SUBMISSION DETAILS</h4>
                                            {requiredQuestions?.length > 0 && requiredQuestions?.map((optItem, optIndex) => (
                                                <Row className="mt-1">

                                                    {Object.values(optItem?.questons).some(el => el.questions.length > 0) ? <>
                                                        <h4 className="pt-2 mb-4 mt-2 fw-bold">{optItem?.dimension}</h4>
                                                    </> : <></>}
                                                    {optItem?.questons?.map((itemInd, indexInd) => (

                                                        <div className="ps-4 ms-2">
                                                            {itemInd?.questions?.length > 0 ? <h4 className="textColor mt-2" key={indexInd}>{itemInd?.indicater}:</h4> : <></>}

                                                            {itemInd?.questions?.length > 0 && itemInd?.questions?.map((itemQ, indexQ) => (
                                                                <div className="">
                                                                    <p key={indexQ}>{indexQ + 1}. {itemQ?.questionId?.question} </p>
                                                                    <p key={indexQ} className="ps-3"> {itemQ?.answer.join(', ')}</p>
                                                                </div>
                                                            ))}
                                                        </div>

                                                    ))}
                                                </Row>
                                            ))}

                                        </Row>
                                        <div>
                                            {/* <div style={{ paddingRight: size?.width >= 1400 && '13rem' }}> */}

                                            <h4 className="pt-2 mb-4 fw-bold">DISCLAIMER</h4>

                                            <p style={{ fontSize: '.9rem' }}>
                                                The ESG maturity levels and score calculations provided on this website are based on self-declared data submissions by <span className="textColor">{profileData?.company}</span>. The information provided in this report shall not be relied upon as a substitute for professional advice. <span className="textColor">UNGCMYB</span> shall not be held liable for any errors, omissions, or inaccuracies in the data provided by <span className="textColor">{profileData?.company}</span> or for any actions taken in reliance on such information. <span className="textColor">{profileData?.company}</span> is solely responsible for the accuracy and completeness of the information they provide.
                                            </p>
                                        </div>


                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default PdfReportPage3;