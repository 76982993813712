import React, { useState } from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import Selection from '../Selection/Selection';
import { Controller, useFieldArray } from 'react-hook-form';
import Select from 'react-select';
import { AiOutlinePlus } from "react-icons/ai";
import { GrDocumentPdf } from "react-icons/gr";
import { RxCrossCircled } from "react-icons/rx";
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

const SingleUploadDocumentCard = ({ nestIndex, allWatchedValues, control, register, setValue, errors }) => {
    // const { id } = useParams()
    const [isLoading, setLoading] = useState(false)

    const { fields, remove, append } = useFieldArray({
        control,
        name: `inputs[${nestIndex}].uploadPDF`
    });

    /**
     * @function PDFup
     * @param (e, index, status)
     * @description append multiple pdf
     */
    const PDFup = (e, index, status) => {
        setValue(`inputs[${nestIndex}].uploadPDF[${index}].files`, e?.target?.files)
        if (index < 3 && status === 'add') {
            append({ files: null })

        }
    }

    /**
     * @function handleRemoveField
     * @param (index)
     * @description remove pdf
     */
    const handleRemoveField = (index) => {
        remove(index)
        if (allWatchedValues?.inputs[nestIndex]?.uploadPDF?.length === 0) {
            append({ files: null })

        }

    }

    return (
        <>
            <div className="mb-3">
                <p className="">
                    Documents (PDF)
                </p>
                <div className='d-flex flex-wrap align-items-center'>
                    {fields?.length > 0 && fields.map((item, index) => {
                        return <div className="mb-3">
                            {allWatchedValues?.inputs[nestIndex]?.uploadPDF[index]?.files?.length > 0 && <div role='button' className='pdfCross ms-2' onClick={() => handleRemoveField(index)}>
                                <RxCrossCircled size={15} />

                            </div>}
                            <div className={classNames('ms-2', { 'mt-4': !allWatchedValues?.inputs[nestIndex]?.uploadPDF[index]?.files })}>
                                <label htmlFor={`uploadPDF[${nestIndex}][${index}]`} className="p-3 addPdf rounded" >

                                    {allWatchedValues?.inputs[nestIndex]?.uploadPDF[index]?.files?.length > 0 ? <GrDocumentPdf size={65} /> : <div role='button' className=''>
                                        <AiOutlinePlus className='ms-4' />
                                        <p>Add More</p>
                                    </div>}

                                </label>
                                <input
                                    id={`uploadPDF[${nestIndex}][${index}]`}
                                    type="file"
                                    // accept="pdf/*"
                                    accept="application/pdf"
                                    className={`${errors.uploadPDF ? 'is-invalid' : 'd-none'}`}
                                    name={`inputs[${nestIndex}].uploadPDF[${index}].files`}
                                    // {...register(`inputs[${nestIndex}].uploadPDF[${index}].files`, {
                                    //     required: 'PDF is required',
                                    // })}
                                    onChange={(e) => {
                                        if (allWatchedValues?.inputs[nestIndex]?.uploadPDF[index]?.files) {
                                            PDFup(e, index, 'edit')
                                        } else {
                                            PDFup(e, index, 'add')
                                        }

                                    }}

                                />
                            </div>

                            {allWatchedValues?.inputs[nestIndex]?.uploadPDF?.length === 0 && <p className='pt-1 text-danger'>Atleast one pdf is required</p>}
                        </div>
                    })}
                </div>

            </div>
        </>
    )
};

export default SingleUploadDocumentCard