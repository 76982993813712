import React, { useState, useEffect } from 'react'

// ** Reactstrap Imports
import {
    Row,
    Col,
    Card,
    Input,
    Label,
    Button,
    CardTitle,
    CardHeader,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Toast,
    Spinner,
    Badge,
    UncontrolledButtonDropdown
} from 'reactstrap'

import Undertaking from "../../../assets/images/analytics/Undertakin.png"
import Asses from './UndertakingCharts/Asses';
import Learn from './UndertakingCharts/Learn';
import Commit from './UndertakingCharts/Commit';
import Measure from './UndertakingCharts/Measure';
import Report from './UndertakingCharts/Report';
import VerticalStackedBarChartLegends from '../CustomChart/VerticalStackedBarChartLegends';
import Selection from '../Selection/Selection';
import toast from 'react-hot-toast';
import { activeIndicatorListService } from '../../../services/indicatorService';
import { getUnderTakingService } from '../../../services/analyticsServices/advacneAnalyticsService/UnderTaking';
import { getToken } from '../../../utility/AuthService';


const UndertakingActions = () => {
    const [watchIndicatorValue, setIndicatorWatchValue] = useState();
    const [isLoading, setLoading] = useState(false)
    const [parcentOrDigit, setParcentOrDigit] = useState('digit')
    const [indicator, setIndicator] = useState([])
    const [chartData, setchartData] = useState()
    const [asses, setAsses] = useState()
    const [learn, setLearn] = useState()
    const [commit, setCommit] = useState()
    const [measure, setMeasure] = useState()
    const [report, setReport] = useState()
    const token = getToken()

    /**
   * @function handleDigit
   * @param ()
   * @description to show data in digit
   */
    const handleDigit = async () => {
        setParcentOrDigit('digit')
    };

    /**
   * @function handleParcent
   * @param ()
   * @description to show data in parcent
   */
    const handleParcent = async () => {
        // if (environmentData && socialData && governanceData) {
        //   setParcentOrDigit('parcent')
        //   const sumEnv = environmentData.reduce(function (a, b) {
        //     return a + b;
        //   });
        //   const parcentValEnv = environmentData.map(function (a) {
        //     return Math.floor((a / sumEnv) * 100);
        //   });
        //   setEnvironmentParcentVal(parcentValEnv)

        //   const sumSocial = socialData.reduce(function (a, b) {
        //     return a + b;
        //   });
        //   const parcentValSocial = socialData.map(function (a) {
        //     return Math.floor((a / sumSocial) * 100);
        //   });
        //   setSocialParcentVal(parcentValSocial)

        //   const sumGovernance = governanceData.reduce(function (a, b) {
        //     return a + b;
        //   });
        //   const parcentValGovernance = governanceData.map(function (a) {
        //     return Math.floor((a / sumGovernance) * 100);
        //   });
        //   setGovernanceParcentVal(parcentValGovernance)
        // }
    };

    /**
    * @function fetchIndicatorData
    * @param ()
    * @description to get all indistry list
    */
    const fetchIndicatorData = async () => {
        try {
            setLoading(true)
            const result = await activeIndicatorListService({
                token,
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setIndicator(result?.data?.data?.list?.filter(item => item?.name !== 'Demographics').map((item) => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            } else {
                setLoading(false)
                setIndicator([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }

        } catch (error) {
            setLoading(false)
            setIndicator([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }

    };

    useEffect(() => {
        fetchIndicatorData()
    }, []);

    /**
    * @function getUnderTakingData
    * @param ()
    * @description to get under taking data
    */
    const getUnderTakingData = async () => {
        try {
            setLoading(true)
            const result = await getUnderTakingService({
                token,
                id: watchIndicatorValue?.value
            });
            if (result?.data?.statusCode === 200) {
                setchartData(result?.data?.data)
                setAsses(result?.data?.data?.final?.ASSESS)
                setCommit(result?.data?.data?.final?.COMMIT)
                setLearn(result?.data?.data?.final?.LEARN)
                setMeasure(result?.data?.data?.final?.MEASURE)
                setReport(result?.data?.data?.final?.REPORT)
                setLoading(false)
            } else {
                setLoading(false)
                setchartData()
                setAsses()
                setCommit()
                setLearn()
                setMeasure()
                setReport()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            setAsses()
            setCommit()
            setLearn()
            setMeasure()
            setReport()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        if (watchIndicatorValue?.value) {
            getUnderTakingData()
        }
    }, [watchIndicatorValue]);

    return (
        <>
            <Card className='border-0'>
                <div className='p-2'>
                    <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
                        {indicator?.length > 0 ? <div className='ps-2 bg-supply-chain fw-bold'>
                            <Selection className={'w-40'} options={indicator} title={'Indicator'} name={'indicator'} setWatchValue={setIndicatorWatchValue} styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '250px'
                                }),
                            }} />
                        </div> : <div className='m-2'><Spinner></Spinner></div>}

                        {/* {watchIndicatorValue && <div className='text-end d-flex align-items-center rounded-pill bg-btn-analytics px-1'>
              <div className='d-flex align-items-center rounded-pill bg-btn-analytics px-2 py-2'>
                  <Button color='' className={parcentOrDigit === 'parcent' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleParcent}>
                      Percentage
                  </Button>
                  <Button color='' className={parcentOrDigit === 'digit' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleDigit}>
                      Digit number
                  </Button>
              </div>
            </div>} */}
                    </div>

                </div>
                {watchIndicatorValue && <div className='p-2'>
                    <h4>{watchIndicatorValue?.label}</h4>
                    <p>On the Following ESG Indicators</p>
                </div>}

                {!watchIndicatorValue && <div className='h-50 bg-white text-center'>
                    <h4 className='fw-bold my-3'>Select An Indicator Above</h4>
                    <div>
                        <img src={Undertaking} alt='Undertaking' />
                    </div>
                    <p className='my-5'>You haven’t selected any indicator to show details</p>

                </div>}

                {isLoading && <div className='text-center h-100'><Spinner></Spinner></div>}

                {watchIndicatorValue && !isLoading &&
                    <>
                        <div className='row container bg-white ms-0'>
                            <VerticalStackedBarChartLegends />
                            {/* <div className='col-12 col-md-6'>
                <Asses asses={asses}/>
              </div>
              <div className='col-12 col-md-6'>
                <Learn learn={learn}/>
              </div> */}
                        </div>
                        <div className=''>
                            <Asses asses={asses} />
                        </div>
                        <div className=''>
                            <Learn learn={learn} />
                        </div>
                        <div className=''>
                            <Commit commit={commit} />
                        </div>
                        <div className=''>
                            <Measure measure={measure} />
                        </div>
                        <div className=''>
                            <Report report={report} />
                        </div>
                    </>

                }
            </Card>
        </>
    )
};

export default UndertakingActions