import { createSlice } from "@reduxjs/toolkit"

export const submisionSlice = createSlice({
    name: "Submission",
    initialState: {
        submission_id : '',
    },
    reducers: { 
        submissionId: (state, action) => {
            state.submission_id = action.payload
        },
        
    }

})

export const {
    submissionId
} = submisionSlice.actions

export const selectSubmisionID = (state) => state.submission.submission_id

export default submisionSlice.reducer
