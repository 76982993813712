import React, { useEffect, useState } from 'react';
import { Sidenav, Nav } from 'rsuite';
import 'rsuite/styles/index.less';
import { useNavigate, useParams } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

// ** Icons Imports
import { Menu } from "react-feather"

// Images
import CheckImage from '../../../assets/images/check.png'
import UnCheckImage from '../../../assets/images/uncheck.png'
import BlueCheckImage from '../../../assets/images/blueCheck.png'

//API-Service
import { progressDimensionListService } from '../../../services/dimensionService'

// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";

// Custom component
import SubmissionEndModal from '../modal/SubmissionEndModal'
import UploadedDocumentsModal from "../modal/UploadedDocumentsModal";
import ShowPdfModal from "../modal/ShowPdfModal";

// Redux
import { selectLanguageUse } from '../../../redux/slices/language.Slice'

const Sidebar = (props) => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [allDimension, setAllDimension] = useState([
        {
            name: 'Demographics',
            _id: 1
        },
        {
            name: 'Materiality Assessment',
            _id: 2
        },
        {
            name: 'Learning & Capacity Commitments',
            _id: 3
        },
        {
            name: 'Measuring Progress',
            _id: 4
        },
        {
            name: 'Reporting and Communicating',
            _id: 5
        },
    ])
    const [dimensionID, setDimensionID] = useState()
    const [expandDimension, setExpandDimension] = useState()
    const [modalShow, setModalShow] = useState(false);
    const [upDocBtn, setUpDocBtn] = useState(false);
    const [submissionId, setSubmissionId] = useState()
    const [uploadedDocumentsModal, setUploadedDocumentsModal] = useState(false);
    const [pdfItem, setPdfItem] = useState();
    const [showPdfModalShow, setShowPdfModalShow] = useState(false);

    const authToken = getToken()
    const user = getUser()

    const languageUse = useSelector(selectLanguageUse || user?.language)
    const viewPage = location.pathname.includes('view')
    // const decryptedSubId = CryptoJS.AES.decrypt(id, 'mySecretKey').toString(CryptoJS.enc.Utf8);

    const handleModalShow = () => {
        setModalShow(!modalShow);
    }

    const handleShowPdfModalShow = () => setShowPdfModalShow(!showPdfModalShow);

    const UploadedDocumentsModaltoggle = (id) => {
        setSubmissionId(id)
        setUploadedDocumentsModal(!uploadedDocumentsModal)
    };

    /**
        * @function dimensionList
        * @param
        * @description handle API to get the dimensions
    */
    const dimensionList = async () => {
        try {
            const result = await progressDimensionListService({
                authToken,
                submissionId: decryptedSubId,
                filter: 'Active',
                limit: 20,
                offset: 0
            });
            setAllDimension(result?.data?.data?.list)
            if (result?.data?.data?.list?.length > 0 && !expandDimension) {
                setExpandDimension(result?.data?.data?.list[0]?._id)
                props.setDimensionID(result?.data?.data?.list[0]?._id)
                props?.setCurrentDimension(result?.data?.data?.list[0]?.name)
                props.setDimensionName(result?.data?.data?.list[0]?.text)
                props.setDimensionName_Malay(result?.data?.data?.list[0]?.text_Malay)
                setDimensionID(result?.data?.data?.list[0]?._id)
            }
        } catch (error) {
            setAllDimension()
            setExpandDimension()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        // dimensionList()
    }, [props.changeDimensionName])

    /**
        * @function getQuestions
        * @param dimensionID, name, requiredText, requiredText_Malay
        * @description handle API to get the Questions
    */
    const getQuestions = async (dimensionID, name, requiredText, requiredText_Malay) => {
        props.setDimensionID(dimensionID)
        props?.setCurrentDimension(name)
        // props.setDimensionName(requiredText)
        // props.setDimensionName_Malay(requiredText_Malay)
        // setDimensionID(dimensionID)
        // setExpandDimension(dimensionID)


    }

    useEffect(() => {
        if (dimensionID) {
            const nextIndicatorIndex = allDimension?.findIndex(item => item._id === dimensionID);
            if (nextIndicatorIndex + 1 < allDimension?.length) {
                const nextDimension = allDimension[nextIndicatorIndex + 1]?._id;
                const nextDimensionName = allDimension[nextIndicatorIndex + 1]?.name;
                const nextDimensionRequiredText = allDimension[nextIndicatorIndex + 1]?.text;
                const nextDimensionRequiredText_Malay = allDimension[nextIndicatorIndex + 1]?.text_Malay;

                // getQuestions(nextDimension, nextDimensionName, nextDimensionRequiredText, nextDimensionRequiredText_Malay)
            } else {

            }

        }
    }, [props.changeDimensionName])

    useEffect(() => {
        if (allDimension?.length > 0) {
            if (Object.values(allDimension).every(item => item?.isCompleted === 'true')) {
                setUpDocBtn(true)
                if (!viewPage) {
                    setModalShow(true)
                }
                // navigate(`/uploadProgressDocuments/${decryptedSubId}`)
            }
        }
    }, [allDimension]);

    return <div>
        {allDimension?.length > 0 ? <>
            <Sidenav>
                <Sidenav.Body>
                    {allDimension?.length > 0 && allDimension?.map((item, index) => (
                        <Nav activeKey={expandDimension} onSelect={(eventKey, event) => {
                            setExpandDimension(eventKey)
                        }}>
                            {/* <Nav.Item eventKey={item._id} className='fs-16' key={item._id} onClick={() => getQuestions(item._id, item.name, item.text, item.text_Malay)}>
                                <img src={item.isCompleted === 'true' ? CheckImage : props?.currentDimension === item.name ? BlueCheckImage : UnCheckImage} className="check" />{' '}
                                {languageUse === 'English' ? item.name : item.name_Malay}
                            </Nav.Item> */}
                            <Nav.Item eventKey={item?._id} className='fs-16' key={item?._id} onClick={() => getQuestions(item?._id, item.name, item?.text, item?.text_Malay)}>
                                <img src={item?.isCompleted === 'true' ? CheckImage : props?.currentDimension === item?.name ? BlueCheckImage : UnCheckImage} className="check" />{' '}
                                {languageUse === 'English' ? item?.name : item?.name_Malay}
                            </Nav.Item>
                        </Nav>
                    ))}
                </Sidenav.Body>
            </Sidenav>
            {/* {!viewPage && <button className='btn btn-outline-primary' onClick={() => (upDocBtn ? navigate(`/uploadProgressDocuments/${decryptedSubId}`) : toast.error('All Questions need to be answered before upload documents'))}>{languageUse === 'English' ? 'Upload Supporting Documents' : 'Muatnaik dokumen sokongan'}</button>} */}
            {/* {viewPage && <button className='btn btn-outline-primary' onClick={() => UploadedDocumentsModaltoggle(decryptedSubId)}>{languageUse === 'English' ? 'Upload Supporting Documents' : 'Muatnaik dokumen sokongan'}</button>} */}
        </> : <></>}
        {/* <SubmissionEndModal show={modalShow} handleClose={handleModalShow} submission_id={decryptedSubId} progress={true} questionPage={true} decryptedSubId={decryptedSubId} /> */}
        <UploadedDocumentsModal modal={uploadedDocumentsModal} toggle={UploadedDocumentsModaltoggle} handleShowPdfModalShow={handleShowPdfModalShow} submissionId={submissionId} setPdfItem={setPdfItem} progress={true} />
        <ShowPdfModal show={showPdfModalShow} handleClose={handleShowPdfModalShow} pdfItem={pdfItem} />
    </div>
};

export default Sidebar