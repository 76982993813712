import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function getAverageIndicatorData
* @param { authToken }
* @description This function is used to get indicator average data
*/
export const getAverageIndicatorData = async ({ authToken, userId }) => {
    const result = axios.get(`${baseUrl}users/associcated/companies/avergae/indicator?userId=${userId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        }
    })
    return result;
};