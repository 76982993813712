
import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function getProfileService
    * @param ({ authToken })
    * @description api to get profile data.
*/
export const getProfileService = async ({ authToken }) => {
    const result = await axios.get(`${baseUrl}users/get/profile`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}