// ** React Imports
import React, { Fragment, useState, forwardRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  NavbarText,
} from "reactstrap";

// Utility-Service
import ESG2QuestionPage from "../QuestionPage";
// import Home from "../Home";
// import Sidebar from "../../components/layouts/SidebarPage";
import { getToken, getUser } from "../../../../utility/AuthService";
import Sidebar from "../../../components/ESG2/Sidebar";
import Header from "../../../components/layouts/Navbar";
import Footer from '../../../components/layouts/Footer'

//redux
import { selectLanguageUse } from "../../../../redux/slices/language.Slice";

const NavbarPage = (args) => {
  const dispatch = useDispatch();
  const token = getToken();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = getUser()
  const [selectedLanguage, setSelectedLanguage] = useState();

  const [indicatorName, setIndicatorName] = useState();
  const [indicatorName_Malay, setIndicatorName_Malay] = useState();
  const [indicator_ID, setIndicator_ID] = useState([]);
  const [changeIndicatorName, setChangeIndicatorName] = useState(false);
  const [currentIndicator, setCurrentIndicator] = useState('')
  const languageUse = useSelector(selectLanguageUse || user?.language)
  const [currentDimension, setCurrentDimension] = useState("Demographics")
  const [dimensionID, setDimensionID] = useState(1);
  const [changeDimensionName, setChangeDimensionName] = useState(false);

  const indicatorHeading = [
    {
      title: "Materiality assessment and understanding risk and impact: Please select which ESG topics are material to your Company, in the last year",
      name: 'Materiality Assessment',
    },
    {
      title: "Learning and Capacity Building Activities within the Last Year: Assess the learning activities conducted by the company within the last year.",
      name: 'Learning & Capacity Commitments',
    },
    {
      title: "COMMIT (Company commitments): Extent of Company Dedication and Commitment: How committed is the company to this topic, and does it have a formal commitment in place in the last year? (A commitment can be a policy/guideline/procedure/process/manual/code of conduct)",
      name: 'Measuring Progress',
    },
    {
      title: "Reporting Progress on Targets and Goals: Does your company report and communicate progress on targets and goals related to this topic?",
      name: 'Reporting and Communicating',
    },
  ]

  return (
    <>
      <Fragment>
        <Header />
        <Container>
          <Row>
            <Col md="12" sm="12" className="my-4">
              <h5>
                {" "}
                {languageUse === 'English' ? indicatorName : indicatorName_Malay}
                {/* {selectedLanguage === 'English' ? indicatorName : indicatorName_Malay} */}

                {/* {indicatorName_Malay} */}

              </h5>
              {indicatorHeading?.length > 0 && indicatorHeading?.filter(item => item?.name === currentDimension)?.map((item, index) => (
                <h5>{item?.title}</h5>
              ))}
            </Col>
          </Row>
          <Row>
            <Col md="3" sm="12">
              <Sidebar setDimensionID={setDimensionID} currentDimension={currentDimension} setCurrentDimension={setCurrentDimension} />
            </Col>
            <Col md="9" sm="12" className="mt-3 mt-md-0">
              <ESG2QuestionPage currentDimension={currentDimension} dimensionID={dimensionID} changeDimensionName={changeDimensionName} setChangeDimensionName={setChangeDimensionName} />
            </Col>
          </Row>

        </Container>
        <Footer />
      </Fragment>
    </>
  );
};

export default NavbarPage;
