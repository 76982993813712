// ** React Imports
import React, { Fragment, useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";

// ** Reactstrap Imports
import {
    Row,
    Col,
    Spinner,
    Container,
} from "reactstrap";
import { Button } from "react-bootstrap";
import { BiImport } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { BsCheckCircleFill, BsCheckCircle } from "react-icons/bs";
// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";

import NavbarComponent from "../../components/layouts/Navbar";
import Footer from '../../components/layouts/Footer'
import { Bar } from "react-chartjs-2";

import toast, { Toaster } from 'react-hot-toast'
import CryptoJS from 'crypto-js';
import moment from 'moment'

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);
//redux
import { selectLanguageUse } from '../../../redux/slices/language.Slice'
// API-Service
import { getAssesmentSummaryService } from '../../../services/submissionService'
import { getImageIndicatorService } from '../../../services/indicatorService'

import PdfReportPage from '../../pages/pdfReport/PdfReportPage'
import PdfReportPage2 from '../../pages/pdfReport/PdfReportPage2'
import { element } from "prop-types";
import { useSelector } from "react-redux";
import { getSubmittedDocumentServices } from "../../../services/uploadDocumentServices/getSubmittedDocumentServices";
import ShowPdfModal from "../../components/modal/ShowPdfModal";
import { getActiveDimensionListService } from "../../../services/dimensionService";
import classNames from "classnames";

const Dashboard = () => {
    const componentRef = useRef();
    const baseUrl = process.env.REACT_APP_API_BASEURL
    const AWS_Url = process.env.REACT_APP_AWSURL
    const [isLoading, setLoading] = useState(false);
    const [dimensionData, setDimensionData] = useState([]);
    const [indicatorData, setIndicatorData] = useState([]);
    const [selectedIndicatorData, setSelectedIndicatorData] = useState([]);
    const [profileData, setProfileData] = useState();
    const [ESGperformanceData, setESGperformanceData] = useState();
    const [optionalQuesData, setOptionalQuesData] = useState([]);
    const [requiredQuestions, setrequiredQuestions] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const download = searchParams.get('download')
    const [pdfContent, setPdfContent] = useState(false);
    const [overallVal, setOverallVal] = useState();
    const [overallValLebel, setOverallValLebel] = useState();
    const [overallColor, setOverallColor] = useState();
    const [imageList, setImageList] = useState([])
    const [dimensionList, setDimensionList] = useState([])
    const [uploadedDocInfo, setUploadedDocInfo] = useState([])
    const [pdfItem, setPdfItem] = useState();
    const [lastSubmission, setLastSubmission] = useState();

    const [showPdfModalShow, setShowPdfModalShow] = useState(false);
    const handleShowPdfModalShow = () => setShowPdfModalShow(!showPdfModalShow);

    const authToken = getToken()
    const { SId } = useParams()
    const user = getUser()
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const decryptedSubId = CryptoJS.AES.decrypt(SId, 'mySecretKey').toString(CryptoJS.enc.Utf8);

    const colorLeval = [
        {
            leval: "Laggard",
            color_code: "#FF0000"
        },
        {
            leval: "Beginner",
            color_code: "#FF9900"
        },
        {
            leval: "Intermediate",
            color_code: "#B6D7A8"
        },
        {
            leval: "Advanced",
            color_code: "#93C47D"
        },
        {
            leval: "Leader",
            color_code: "#38761D"
        },
    ]

    /**
    * @function getSubmittedDocs
    * @param ()
    * @description get Submitted Documents
    */
    const getSubmittedDocs = async () => {
        let result = []
        try {
            setLoading(true)
            result = await getSubmittedDocumentServices({
                authToken: authToken,
                SubmissionId: decryptedSubId
            });

            if (result?.data?.statusCode === 200) {
                setUploadedDocInfo(result?.data?.data)
                setLoading(false)
            } else {
                setLoading(false)
                setUploadedDocInfo([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setUploadedDocInfo([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    useEffect(() => {
        getSubmittedDocs()
    }, []);

    /**
    * @function downloadDoc
    * @param (data)
    * @description to download Document
    */
    const downloadDoc = (pdfFile) => {
        // using Java Script method to get PDF file
        fetch(pdfFile).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                const alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = pdfFile;
                alink.click();
            })
        })

    };

    /**
    * @function viewDoc
    * @param (data)
    * @description to view Document
    */
    const viewDoc = (item) => {
        setPdfItem(item)
        handleShowPdfModalShow()

    };

    /**
    * @function getImage
    * @param (rowRecord, index)
    * @description update indicator image list
    */
    const getImage = async (rowRecord, index) => {
        if (rowRecord) {
            try {
                const result = await getImageIndicatorService({
                    token: authToken,
                    file: rowRecord
                })
                const temp = imageList;
                temp[index] = `data:image/${rowRecord?.filename?.split(".")[1]};base64, ${Buffer.from(result?.data, "base64").toString("base64")}`
                setImageList(temp)
            } catch (error) {
                toast.error(error?.response?.data?.message || "Too Many Requests" || 'Something went wrong')
            }
        } else {
            const temp = imageList;
            temp[index] = ''
            setImageList(temp)
        }
    }

    const selectESGcolor = (value) => {
        if (value > 0 && value <= 20) {
            return '#FF0000'
        } else if (value > 20 && value <= 40) {
            return '#FF9900'
        } else if (value > 40 && value <= 60) {
            return '#B6D7A8'
        } else if (value > 60 && value <= 80) {
            return '#93C47D'
        } else if (value > 80 && value <= 100) {
            return '#38761D'
        }
    }

    useEffect(() => {
        if (dimensionData?.length > 0) {
            const labels = dimensionData?.length > 0 ? dimensionData?.map(item => languageUse === 'English' ? item.dimension : item.dimension_Malay) : [];
            labels.push('ESG');
            // labels.push('Overall');
            setOverallValLebel(labels)

            const totalValues = dimensionData?.length > 0 ? dimensionData?.map(item => (item.total)) : []
            let totalValuesSum = 0;
            totalValues.map(x => (totalValuesSum += x));

            const correctValues = dimensionData?.length > 0 ? dimensionData?.map(item => (item.correct)) : []
            let correctValuesSum = 0;
            correctValues.map(x => (correctValuesSum += x));

            const overallValue = ((correctValuesSum / totalValuesSum) * 100).toFixed(2)
            const ESGcolor = selectESGcolor(overallValue)

            const allColor = dimensionData?.length > 0 && dimensionData?.map(item => colorLeval.find(element => element.leval === item.leval).color_code)
            allColor.push(ESGcolor)
            setOverallColor(allColor)

            const chartData = dimensionData?.length > 0 && dimensionData?.map(item => ((item.correct / item.total) * 100).toFixed(2));
            chartData.push(overallValue);
            setOverallVal(chartData)
        }
    }, [dimensionData, languageUse]);

    const data = {
        labels: overallValLebel,
        datasets: [
            {
                label: "Score of Category",

                data: overallVal,
                backgroundColor: overallColor,
                // backgroundColor: dimensionData?.length > 0 ? dimensionData?.map(item => colorLeval.find(element => element.leval === item.leval).color_code) : [],

                borderColor: overallColor,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        // maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Score of Category",
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                color: "black",
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: "bold",
                },
                formatter: (value) => {
                    return `${value}%`
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: true,
                },
                position: 'bottom',
            },
            y: {
                max: 150,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: true,
                },
            },
        },
        maintainAspectRatio: true,

    };

    const horizontalData = {
        labels: indicatorData?.length > 0 && indicatorData?.map((item) => languageUse === 'English' ? item.name : item.name_Malay),
        datasets: [
            {
                label: languageUse === 'English' ? "Your Score" : "Markah anda",
                data: indicatorData?.length > 0 && indicatorData?.map((item) => {
                    if (item.correct) {
                        return ((item?.correct / item?.total) * 100).toFixed(2)
                    } else {
                        return 0
                    }
                }),
                backgroundColor: indicatorData?.map((item) => item?.dimension?.color),

                borderColor: indicatorData?.map((item) => item?.dimension?.color),
                borderWidth: 1,
            },
            {
                label: languageUse === 'English' ? "Peer Score" : "Skor Rakan Sebaya",
                data: indicatorData?.length > 0 && indicatorData?.map((item) => {
                    if (item?.industryScore) {
                        return ((item?.industryScore / item?.total) * 100).toFixed(2)
                    } else {
                        return 0
                    }
                }),
                backgroundColor: indicatorData?.map((item) => item?.dimension?.peerColor),

                borderColor: indicatorData?.map((item) => item?.dimension?.peerColor),
                borderWidth: 1,
            },
        ],
    };


    const horoizontalOptions = {
        responsive: true,
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                labels: {
                    usePointStyle: true,
                    boxWidth: 20,
                    padding: 50,

                },

                position: 'bottom',
                align: 'center'
            },

            title: {
                display: true,
                text: "Score of Category",
            },
            datalabels: {

                display: false,

            },
        },
        scales: {
            x: {
                // max: 10,
                ticks: {
                    callback: (value) => {
                        return `${value}%`
                    }
                },
                grid: {
                    display: false,

                },
                border: {
                    display: false,
                },
                position: 'bottom',
            },
            y: {
                // max: 150,
                ticks: {
                    // display: false,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: true,

    };

    /**
     * @function getAssesmentSummary
     * @param ()
     * @description get Assesment Summary List
     */
    const getAssesmentSummary = async () => {
        const submissionID = localStorage.getItem('submissionId');
        try {
            setLoading(true);
            const result = await getAssesmentSummaryService({
                authToken,
                SubmissionId: decryptedSubId
            });

            setDimensionData(result?.data?.data?.dimensions)
            let select = []
            result?.data?.data?.dimensions.map((item) => {
                let temp = []
                result?.data?.data?.indicaters.filter(indicatorItem => indicatorItem.dimension.name === item.dimension).map((filteredItem) => {
                    temp = [...temp, colorLeval.findIndex(element => element.leval === filteredItem.leval)]
                })
                if (temp.every(element => element >= 2)) {
                    select = [...select, result?.data?.data?.indicaters.filter(indicatorItem => indicatorItem.dimension.name === item.dimension).reduce((prev, current) => (((prev?.correct / prev?.total) * 100).toFixed(2) > ((current?.correct / current?.total) * 100).toFixed(2)) ? prev : current)]
                } else {
                    select = [...select, result?.data?.data?.indicaters.filter(indicatorItem => indicatorItem.dimension.name === item.dimension).reduce((prev, current) => (((prev?.correct / prev?.total) * 100).toFixed(2) < ((current?.correct / current?.total) * 100).toFixed(2)) ? prev : current)]
                }
            })
            setSelectedIndicatorData(select)
            setIndicatorData(result?.data?.data?.indicaters)
            setProfileData(result?.data?.data?.profile)
            setESGperformanceData(result?.data?.data?.esgPerformance)
            setOptionalQuesData(result?.data?.data?.optionalQuestions)
            setrequiredQuestions(result?.data?.data?.requiredQuestions)
            setLastSubmission(result?.data?.data?.lastSubmissionDate)

            await Promise.all(result?.data?.data?.indicaters.map(async (item, index) => {
                if (item?.file) {
                    await getImage(item?.file, index)
                } else {
                    await getImage(null, index)
                }
            }))

            setLoading(false);

        } catch (error) {
            setLoading(false);
            setSelectedIndicatorData([])
            setIndicatorData([])
            setProfileData()
            setESGperformanceData()
            setOptionalQuesData([])
            setrequiredQuestions([])
            setLastSubmission()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        getAssesmentSummary()
    }, [])

    /**
     * @function afterPrint
     * @param ()
     * @description set pdf content false after the print
    */
    const afterPrint = () => {
        setPdfContent(false)
    }

    /**
     * @function pdfContenFunc
     * @param ()
     * @description handle pdf content
    */
    // this footer is deafult
    const pdfContenFunc = useReactToPrint({
        pageStyle: `@media print {
                  @page {
                    margin: 20px !important;
                    background: green !important;
                  }
                  @page :footer {
                    display: none !important;
                  }

                  @page :header {
                    display: none !important;
                  }

                  html, body {
                    height: 99%;
                    width: 100% !important;
                    -webkit-print-color-adjust: exact;   
                  }
                }`,

        //     pageStyle: `@page {
        //     size: A4;
        //     margin: 0;
        // }
        // @media print {
        //     html, body {
        //         width: 210mm;
        //         height: 297mm;        
        //     }
        //     .page {
        //         margin: 0;
        //         border: initial;
        //         border-radius: initial;
        //         width: initial;
        //         min-height: initial;
        //         box-shadow: initial;
        //         background: initial;
        //         page-break-after: always;
        //     }
        // }`,
        content: () => componentRef.current,
        documentTitle: `${profileData?.company}_UNCG`,
        onAfterPrint: afterPrint
    });

    /**
     * @function pdfDownloadFunc
     * @param ()
     * @description handle pdf download
    */
    const pdfDownloadFunc = () => {
        setPdfContent(true)
        const timer = setTimeout(() => {
            pdfContenFunc()
        }, 1000);

        return () => clearTimeout(timer);
    };

    useEffect(() => {
        if (download) {
            pdfDownloadFunc()
        }
    }, [selectedIndicatorData])

    // const higherLowerDetector = (item) => {
    //     if (item) {
    //         if (((item?.correct / item?.total) * 100).toFixed(2) >= ((item?.industryScore / item?.total) * 100).toFixed(2)) {
    //             return `${(((item?.correct / item?.total) * 100).toFixed(2) - ((item?.industryScore / item?.total) * 100).toFixed(2)).toFixed(2)}% higher`
    //         } else {
    //             return `${(((item?.industryScore / item?.total) * 100).toFixed(2) - ((item?.correct / item?.total) * 100).toFixed(2)).toFixed(2)}% lower`
    //         }
    //     } else {
    //         return ''
    //     }
    // }
    // languageUse === 'English' ?
    const higherLowerDetector = (item) => {
        if (item) {

            // if (((item?.correct / item?.total) * 100).toFixed(2) >= ((item?.industryScore / item?.total) * 100).toFixed(2)) {
            if (((item?.correct / item?.total)) >= ((item?.industryScore / item?.total))) {
                return `${(((item?.correct / item?.total) * 100).toFixed(2) - ((item?.industryScore / item?.total) * 100).toFixed(2)).toFixed(2)}% ${languageUse === 'English' ? 'higher' : 'yang lebih'}`
            } else {
                return `${(((item?.industryScore / item?.total) * 100).toFixed(2) - ((item?.correct / item?.total) * 100).toFixed(2)).toFixed(2)}% ${languageUse === 'English' ? 'lower' : 'yang kurang'}`
            }
        } else {
            return ''
        }
    }

    return (
        <Fragment>
            <div>
                <Toaster />
            </div>
            <NavbarComponent />

            {isLoading ? <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
                <Spinner animation="grow" />
            </div> : <>
                <Container>
                    <div className="d-flex justify-content-between">
                        <h2 className="mb-4">{languageUse === 'English' ? "Summary ESG Maturity & Action Report" : "Ringkasan Laporan  Kematangan ESG & Tindakan"}</h2>
                        {/* <p>{languageUse}</p> */}
                        <div className="d-none d-lg-block">
                            <Button className="float-end authButton login-btn-min-width mb-4" variant='outline-primary' onClick={pdfDownloadFunc}>
                                {isLoading ? <Spinner variant='dark' size='sm' /> : <><BiImport style={{ fontSize: "20px", marginBottom: '3px' }}></BiImport> {languageUse === 'English' ? ' Report' : ' Laporan'}</>}
                            </Button>
                        </div>
                    </div>
                    <div className="profile">
                        <Row className="profile p-2 mb-0">
                            <Col className="" md="6" sm="12">
                                <p className="mb-0">{languageUse === 'English' ? 'Current Report Dated' : 'Laporan Semasa Bertarikh'} : <span className="bold">{moment(profileData?.updatedAt).format("DD-MM-YYYY")}</span></p>
                                <p className="mb-0">{languageUse === 'English' ? 'Company' : 'Syarikat'} : <span className="bold">{profileData?.company}</span></p>
                                <p className="mb-0">{languageUse === 'English' ? 'Company Registration Number' : 'No. Pendaftaran Syarikat'} : <span className="fw-bold">{profileData?.companyReg}</span></p>
                                <p className="mb-0">{languageUse === 'English' ? 'Username' : 'Nama Pengguna'} : <span className="bold">{profileData?.username}</span></p>
                                <p className="mb-0">
                                    {languageUse === 'English' ? 'State' : 'Negeri'} : <span className="fw-bold">{profileData?.state}</span>
                                </p>
                            </Col>
                            <Col className="" md="6" sm="12">
                                <p className="mb-0">{languageUse === 'English' ? 'Last Submission Date' : 'Tarikh Penyerahan Terakhir'} :{" "}<span className="bold">{lastSubmission ? moment(lastSubmission).format("DD-MM-YYYY") : 'No Previous Submission'}</span></p>
                                <p className="mb-0">{languageUse === 'English' ? 'Industry' : 'Industri'} :{" "}<span className="bold">{languageUse === 'English' ? profileData?.industry?.name : profileData?.industry?.name_Malay}</span></p>
                                <p className="mb-0">
                                    Email : <span className="bold">{profileData?.email}</span>
                                </p>
                                <p className="mb-0">
                                    {languageUse === 'English' ? 'Country' : 'Negara'} : <span className="fw-bold">{profileData?.country}</span>
                                </p>
                                <p className="mb-0">
                                    {languageUse === 'English' ? 'City' : 'Bandar'} : <span className="fw-bold">{profileData?.city}</span>
                                </p>
                            </Col>

                        </Row>
                        <Row className="profile px-2 mb-0">
                            <span><hr></hr></span>
                        </Row>

                        {dimensionData?.length > 0 &&
                            <Row className="profile p-2 mb-4">
                                <Col className="" md="6" sm="12">
                                    <p>
                                        {languageUse === 'English' ? 'ESG Maturity Level' : 'Tahap Kematangan ESG'} : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === ESGperformanceData?.leval)?.color_code }}>{languageUse === 'English' ? ESGperformanceData?.leval : ESGperformanceData?.data?.leval_Malay}</span>
                                    </p>
                                    {languageUse === 'English' ? <p className="mb-0">
                                        {dimensionData[1]?.dimension} Maturity Level : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[1]?.leval)?.color_code }}>{dimensionData[1]?.leval}</span>
                                    </p> : <p className="mb-0">
                                        Tahap Kematangan {dimensionData[1]?.dimension_Malay} : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[1]?.leval)?.color_code }}>{dimensionData[1]?.performance?.leval_Malay}</span>
                                    </p>}
                                </Col>
                                <Col className="" md="6" sm="12">
                                    {languageUse === 'English' ? <p className="mb-0">
                                        {dimensionData[0]?.dimension} Maturity Level : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[0]?.leval)?.color_code }}>{dimensionData[0]?.leval}</span>
                                    </p> : <p className="mb-0">
                                        Tahap Kematangan {dimensionData[0]?.dimension_Malay} : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[0]?.leval)?.color_code }}>{dimensionData[0]?.performance?.leval_Malay}</span>
                                    </p>}
                                    {languageUse === 'English' ? <p className="mb-0">
                                        {dimensionData[2]?.dimension} Maturity Level : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[2]?.leval)?.color_code }}>{dimensionData[2]?.leval}</span>
                                    </p> : <p className="mb-0">
                                        Tahap Kematangan {dimensionData[2]?.dimension_Malay} : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[2]?.leval)?.color_code }}>{dimensionData[2]?.performance?.leval_Malay}</span>
                                    </p>}
                                </Col>
                            </Row>
                        }

                    </div>

                    <Row className="p-0 my-2 d-none d-lg-block">
                        <p className="fw-bold px-0">ESG Maturity Level:</p>
                        <table className="table table-bordered border-0">
                            <tbody>
                                <tr className="border-0">

                                    <td className="ps-3 border-0 text-center fw-bold text-dark bg-Laggard">{languageUse === 'English' ? 'Laggard' : 'Laggard'}</td>

                                    <td className="ps-3 border-0 text-center fw-bold text-dark bg-Beginner">{languageUse === 'English' ? 'Beginner' : 'Pemula'}</td>

                                    <td className="ps-3 border-0 text-center fw-bold text-dark bg-Intermediate">{languageUse === 'English' ? 'Intermediate' : 'Pertengahan'}</td>

                                    <td className="ps-3 border-0 text-center fw-bold text-white bg-Advanced">{languageUse === 'English' ? 'Advanced' : 'Maju'}</td>

                                    <td className="ps-3 border-0 text-center fw-bold text-white bg-Leader">{languageUse === 'English' ? 'Leader' : 'Ketua'} </td>
                                </tr>
                                <tr className="border-0">
                                    <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Laggard': ESGperformanceData?.leval === 'Laggard' || ESGperformanceData?.data?.leval_Malay === 'Laggard', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Laggard' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Laggard' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                    <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Beginner': ESGperformanceData?.leval === 'Beginner' || ESGperformanceData?.data?.leval_Malay === 'Pemula', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Beginner' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Pemula' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                    <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Intermediate': ESGperformanceData?.leval === 'Intermediate' || ESGperformanceData?.data?.leval_Malay === 'Pertengahan', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Intermediate' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Pertengahan' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                    <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Advanced': ESGperformanceData?.leval === 'Advanced' || ESGperformanceData?.data?.leval_Malay === 'Maju', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Advanced' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Maju' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                    <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Leader': ESGperformanceData?.leval === 'Leader' || ESGperformanceData?.data?.leval_Malay === 'Ketua', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Leader' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Ketua' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                    {/* For Small device */}
                    <Row className="p-0 my-2 d-block d-lg-none">
                        <h5 className="fw-bold">ESG Maturity Level:</h5>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td className="ps-3 fw-bold text-dark" style={{ backgroundColor: '#E06666' }}>{languageUse === 'English' ? 'Laggard' : 'Laggard'}</td>
                                    <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? ESGperformanceData?.leval === 'Laggard' ? <BsCheckCircleFill /> : '' : ESGperformanceData?.data?.leval_Malay === 'Laggard' ? <BsCheckCircleFill /> : ''}</td>
                                </tr>

                                <tr>
                                    <td className="ps-3 fw-bold text-dark" style={{ backgroundColor: '#FCE5CD' }}>{languageUse === 'English' ? 'Beginner' : 'Pemula'}</td>
                                    <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? ESGperformanceData?.leval === 'Beginner' ? <BsCheckCircleFill /> : '' : ESGperformanceData?.data?.leval_Malay === 'Pemula' ? <BsCheckCircleFill /> : ''}</td>
                                </tr>

                                <tr>
                                    <td className="ps-3 fw-bold text-dark" style={{ backgroundColor: '#D9EAD3' }}>{languageUse === 'English' ? 'Intermediate' : 'Pertengahan'}</td>
                                    <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? ESGperformanceData?.leval === 'Intermediate' ? <BsCheckCircleFill /> : '' : ESGperformanceData?.data?.leval_Malay === 'Pertengahan' ? <BsCheckCircleFill /> : ''}</td>
                                </tr>

                                <tr>
                                    <td className="ps-3 fw-bold text-white" style={{ backgroundColor: '#B6D7A8' }}>{languageUse === 'English' ? 'Advanced' : 'Maju'}</td>
                                    <td className="ps-3 fw-bold text-center border-0">{languageUse === 'English' ? ESGperformanceData?.leval === 'Advanced' ? <BsCheckCircleFill /> : '' : ESGperformanceData?.data?.leval_Malay === 'Maju' ? <BsCheckCircleFill /> : ''}</td>
                                </tr>

                                <tr>
                                    <td className="ps-3 fw-bold text-white" style={{ backgroundColor: '#93C47D' }}>{languageUse === 'English' ? 'Leader' : 'Ketua'} </td>
                                    <td className="ps-3 fw-bold text-center border-0">{languageUse === 'English' ? ESGperformanceData?.leval === 'Leader' ? <BsCheckCircleFill /> : '' : ESGperformanceData?.data?.leval_Malay === 'Ketua' ? <BsCheckCircleFill /> : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                    <Row className="justify-content-between">
                        <Col
                            className="history-text-color px-0 mb-4 mb-md-0"
                            md="5"
                            sm="12"
                        >
                            <h4 className="ps-3 pt-2 mb-4">{languageUse === 'English' ? 'Score of Category' : 'Skor Kategori'}</h4>

                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <Bar data={data} options={options} height={150}></Bar>
                            </div>
                        </Col>
                        <Col className="history-text-color px-0" md="6" sm="12">
                            <div>
                                <h4 className="ps-3 pt-2 mb-4">{languageUse === 'English' ? "Score of Indicator" : "Skor Penunjuk"}</h4>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-responsive">
                                        <thead className="table-head">
                                            <tr>
                                                <td scope="col">{languageUse === 'English' ? "Dimension" : "Dimensi"}</td>
                                                <td scope="col">{languageUse === 'English' ? "Indicator" : "Penunjuk"}</td>
                                                <td scope="col" className="text-center">
                                                    {languageUse === 'English' ? "Your Score" : "Skor Anda"}
                                                </td>
                                                <td scope="col" className="text-center">
                                                    {languageUse === 'English' ? "Peer Score" : "Skor Rakan Industri"}
                                                </td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {indicatorData?.length > 0 && [...new Set(indicatorData.map(item => item?.dimension?._id))].map((it, ind) => {
                                                return (
                                                    indicatorData?.filter(item => item?.dimension?._id === it).map((item, index) => (
                                                        <tr key={index}>
                                                            {index === 0 && <td rowSpan={indicatorData?.filter(item => item?.dimension?._id === it).length} style={{ backgroundColor: '#f4fbfe' }}>{languageUse === 'English' ? item?.dimension?.name ? `${item?.dimension?.name}` : '--' : item?.dimension?.name_Malay ? `${item?.dimension?.name_Malay}` : '--'}</td>}

                                                            <td className="">{languageUse === 'English' ? item.name ? `${item?.name}` : '--' : item.name_Malay ? `${item?.name_Malay}` : '--'}</td>
                                                            <td className="text-center">{item?.correct ? `${((item?.correct / item?.total) * 100).toFixed(2)}%` : '0%'}</td>
                                                            <td className="text-center">{item?.industryScore ? `${((item?.industryScore / item?.total) * 100).toFixed(2)}%` : '0%'}</td>
                                                        </tr>

                                                    ))
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="border mt-5" >
                        <h4 className="pt-3 px-5">{languageUse === 'English' ? "Peer Comparison Bar" : "Carta Perbandingan Rakan Industri"}</h4>
                        <div className="mt-5">
                            {/* <Bar className="ps-1 pe-4" data={props.horizontalData} options={props.horoizontalOptions}></Bar> */}
                            <Bar style={{ marginRight: '5rem', paddingRight: '5rem', marginBottom: '5px' }} data={horizontalData} options={horoizontalOptions}></Bar>
                        </div>
                    </Row>

                    <Row className="mt-4">
                        <h4 className="fw-bold mb-2">{languageUse === 'English' ? "ESG performance" : "Prestasi ESG"}</h4>

                        {languageUse === 'English' ? <span className="textColor mb-2">{profileData?.company} has an {ESGperformanceData?.leval} level</span> : <span className="textColor mb-2">{profileData?.company} memiliki tahap {ESGperformanceData?.data?.leval_Malay}</span>}

                        {/* <p className="">{ESGperformanceData?.data?.esgDescription.replace(/SME X/g, profileData?.company)}</p> */}

                        <p className="">{languageUse === 'English' ? ESGperformanceData?.data?.esgDescription && ESGperformanceData?.data?.esgDescription.replace(/SME X/g, profileData?.company) : ESGperformanceData?.data?.esgDescription_Malay && ESGperformanceData?.data?.esgDescription_Malay}</p>
                        <span>
                            <hr></hr>
                        </span>
                    </Row>

                    {dimensionData?.length > 0 && dimensionData?.map((dimensionItem, dimensionIndex) => (
                        <Row key={dimensionIndex} className="mt-1">
                            <h4 className="mb-1 fw-bold">{`${languageUse === 'English' ? `${dimensionItem?.dimension} performance` : `Prestasi ${dimensionItem?.dimension_Malay}`}`} </h4>
                            {/* <span className="textColor mb-2">{profileData?.company} has an {dimensionItem?.leval} level</span> */}

                            {languageUse === 'English' ? <>
                                <span className="textColor mb-2">{`SME X shows ${dimensionItem?.leval} level in the ${languageUse === 'English' ? dimensionItem?.dimension : dimensionItem?.dimension_Malay} dimension which is evident for the ${languageUse === 'English' ? selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name : selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name_Malay} indicator which scores ${higherLowerDetector(selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id))} than peers.`.replace(/SME X/g, profileData?.company)}</span>
                            </> : <>
                                <span className="textColor mb-2">{`SME X memiliki tahap ${dimensionItem?.performance?.leval_Malay} dalam ${languageUse === 'English' ? dimensionItem?.dimension : dimensionItem?.dimension_Malay} dimensi Persekitaran selaras dengan skor ${higherLowerDetector(selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id))} rendah berbanding rakan industri dalam kategori ${languageUse === 'English' ? selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name : selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name_Malay}.`.replace(/SME X/g, profileData?.company)}</span>

                            </>}

                            {/* <p className="mb-2">{dimensionItem?.performance?.description?.replace(/SME X/g, profileData?.company)}</p> */}
                            <p className="mb-2">{languageUse === 'English' ? dimensionItem?.performance?.description.replace(/SME X/g, profileData?.company) : dimensionItem?.performance?.description_Malay.replace(/SME X/g, profileData?.company)}</p>
                            <h4 className="fw-bold">{languageUse === 'English' ? 'Recommended action' : 'Tindakan Yang Disarankan'}:</h4>
                            <p className="mb-2">{languageUse === 'English' ? dimensionItem?.performance?.action : dimensionItem?.performance?.action_Malay}</p>

                            <span>
                                <hr></hr>
                            </span>
                        </Row>
                    ))}

                    <Row>
                        <h4 className="pt-2 mb-4 fw-bold">SDG MAPPING</h4>

                        <div className="px-2">
                            <table className="table table-bordered">
                                <thead className="table-head">

                                </thead>

                                <tbody>
                                    {indicatorData?.length > 0 && indicatorData?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="textColor">{imageList[index] && <img src={imageList[index]} width={70} />}</td>
                                            <td className="ps-3 textColor">{languageUse === 'English' ? item?.name ? `${item?.name}` : '--' : item?.name_Malay ? `${item?.name_Malay}` : '--'}</td>
                                            <td className="ps-3">{(item?.performance?.description || item?.performance?.description_Malay) ? `${languageUse === 'English' ? item?.performance?.description?.replace(/SME X/g, profileData?.company) : item?.performance?.description_Malay?.replace(/SME X/g, profileData?.company)}` : '--'}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </Row>

                    <h4 className="pt-2 mb-4 fw-bold">{languageUse === 'English' ? "Uploaded Documents" : "Dokumen Yang Dimuatnaik"}</h4>
                    {uploadedDocInfo?.length > 0 ? <div className="table-responsive">
                        <table className="table table-bordered table-responsive">
                            <thead className="text-color-primary fw-bold">
                                <tr>
                                    <td scope="col">{languageUse === 'English' ? 'Dimension' : 'DImensi'}</td>
                                    <td scope="col">{languageUse === 'English' ? 'Name' : 'Nama'}</td>
                                    <td scope="col">{languageUse === 'English' ? 'Description' : 'Deskripsi'}</td>
                                    <td scope="col">{languageUse === 'English' ? 'Uploaded PDF' : 'pdf. dimuatnaik'}</td>
                                    <td scope="col">Actions</td>

                                </tr>
                            </thead>

                            <tbody>
                                {uploadedDocInfo?.length > 0 && uploadedDocInfo?.map((it, ind) => {
                                    return (
                                        it?.files?.map((item, index) => (
                                            <tr key={index}>
                                                {index === 0 && <td rowSpan={it?.files?.length}>{languageUse === 'English' ? it?.dimension?.name : it?.dimension?.name_Malay}</td>}
                                                {index === 0 && <td rowSpan={it?.files?.length}>{it?.name}</td>}
                                                {index === 0 && <td rowSpan={it?.files?.length}>{it?.description}</td>}
                                                <td className=''>{item ? `Document-${index + 1}` : '--'}</td>
                                                <td className='text-color-primary'>
                                                    {/* <a href={`${baseUrl}${item}`} target="_blank"><AiOutlineEye className='me-3' role='button' /></a> */}
                                                    <AiOutlineEye className='me-2' role='button' onClick={() => viewDoc(item)} />
                                                    <a href={`${AWS_Url}${item}`} target="_blank"><BiImport className='' role='button' /></a>
                                                </td>
                                            </tr>
                                        ))

                                    )
                                })
                                }

                            </tbody>
                        </table>
                    </div> : <div className='w-100 d-flex align-items-center justify-content-center'>
                        <p className=''>No Document Available</p>
                    </div>}

                    <h4 className="pt-2 mb-4 fw-bold">DISCLAIMER</h4>
                    {/* <p className="pt-2 mb-4">The ESG maturity levels and score calculations are based on self-declared data submissions by <span className="textColor">{profileData?.company}</span> and <span className="textColor">UNGCMYB</span> is not liable for the accuracy of these data submissions.</p> */}
                    <p style={{ fontSize: '.9rem' }}>
                        The ESG maturity levels and score calculations provided on this website are based on self-declared data submissions by <span className="textColor">{profileData?.company}</span>. The information provided in this report shall not be relied upon as a substitute for professional advice. <span className="textColor">UNGCMYB</span> shall not be held liable for any errors, omissions, or inaccuracies in the data provided by <span className="textColor">{profileData?.company}</span> or for any actions taken in reliance on such information. <span className="textColor">{profileData?.company}</span> is solely responsible for the accuracy and completeness of the information they provide.
                    </p>
                    {/* <p className="fs-14">For more information & support, reach out to <a className="text-decoration-none" href="mailto:strategicpartnerships@alliancefg.com">strategicpartnerships@alliancefg.com</a></p> */}
                </Container>

                {/* <PdfReportPage ref={componentRef} id={decryptedSubId} pdfContent={pdfContent} data={data} options={options} dimensionData={dimensionData} indicatorData={indicatorData} profileData={profileData} ESGperformanceData={ESGperformanceData} optionalQuesData={optionalQuesData} requiredQuestions={requiredQuestions} overallVal={overallVal} overallValLebel={overallValLebel} horoizontalOptions={horoizontalOptions} horizontalData={horizontalData} /> */}

                <PdfReportPage2 ref={componentRef} id={decryptedSubId} pdfContent={pdfContent} data={data} options={options} dimensionData={dimensionData} indicatorData={indicatorData} selectedIndicatorData={selectedIndicatorData} profileData={profileData} ESGperformanceData={ESGperformanceData} optionalQuesData={optionalQuesData} requiredQuestions={requiredQuestions} overallVal={overallVal} overallValLebel={overallValLebel} horoizontalOptions={horoizontalOptions} horizontalData={horizontalData} uploadedDocInfo={uploadedDocInfo} lastSubmission={lastSubmission} />
            </>
            }

            <Footer />
            <ShowPdfModal show={showPdfModalShow} handleClose={handleShowPdfModalShow} pdfItem={pdfItem} />
        </Fragment>
    );
};

export default Dashboard;