import axios from 'axios'
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function getSDGmappingService
* @param { token }
* @description This function is used to get SDG mapping data
*/
export const getSDGmappingService = async ({ token }) => {
    const result = axios.get(`${baseUrl}users/get/indicator/sdgMapping`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    return result;
};


/**
* @function filterSDGmappingIndustryAndStateService
* @param { token, state, industry, fromDate, toDate}
* @description This function is used to get SDG mapping filter data
*/
export const filterSDGmappingIndustryAndStateService = async ({ token, state, industry, toDate, fromDate }) => {
    const result = axios.get(`${baseUrl}users/get/indicator/sdgMapping?state=${state}&industry=${industry}&toDate=${toDate}&fromDate=${fromDate}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    return result;
};