
import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function userQuestionService
* @param (authToken, indicatorId, submissionId)
* @description call API to get the Question list
*/
export const userQuestionService = async ({ authToken, indicatorId, submissionId, limit }) => {
    const result = await axios.get(`${baseUrl}users/questions/list?indicator=${indicatorId}&submissionId=${submissionId}&limit=${limit}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}

/**
* @function userAnswerService
* @param (authToken, data)
* @description call API to get submit answer
*/

export const userAnswerService = ({ authToken, data }) => axios.post(`${baseUrl}users/questions/answer`,
    { data }
    , {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })

/**
    * @function userProgressAnswerService
    * @param (authToken, data)
    * @description call API to get submit progress answer
*/
export const userProgressAnswerService = ({ authToken, data }) => {
    const result = axios.post(`${baseUrl}ProGress/questions/answer`, { data }, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}