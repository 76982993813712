import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function joinedAccessCodeService
* @param {  authToken, joinedAccessCode }
* @description This function is used to join new access code
*/
export const joinedAccessCodeService = async ({ authToken, joinedAccessCode }) => {
    const result = axios.post(`${baseUrl}users/join/accesCode`, {
        joinedAccessCode
    }, {
        headers: {
            Authorization: `Bearer ${authToken}`,

        }
    })
    return result;
};