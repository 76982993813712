import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import { Form, Button } from 'react-bootstrap';
import { selectLanguageUse } from '../../../../redux/slices/language.Slice'
import { useSelector } from 'react-redux'

const Index = (props) => {
    const [savedSubmitButton, setSavedSubmitButton] = useState(false);
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const question1 = [
        {
            question: "Company revenue in the last financial year:",
            options: ["Less than RM 300,000", "RM 300,000 - 3 mil", "RM 3 mil - 20 mil", "RM 20 mil - 50 mil", "RM 50 mil - 100 mil", "RM 100 mil +"]
        },
        {
            question: "Number of employees:",
            options: ["Less than 5", "5 - 30", "30 - 75", "75 - 200", "200+"]
        },
        {
            question: "Women-owned Company (51% and above of the total equity is held by women OR the MD / CEO is a woman who holds at least 10% equity)",
            options: ["Yes", "No"]
        },
        {
            question: "Does this company export?",
            options: ["Yes", "No"]
        },
        {
            question: "If yes, please indicate the main export market:",
            options: ["Europe", "Asia", "USA", "Latin America", "Africa", "Middle East", "Oceania"]
        },
    ]

    const question2 = [
        {
            question: "Please select the ESG topics on which your company has conducted a risk/impact assessment."
        },
        {
            question: "Which ESG topic(s) are important to your company according to internal stakeholders (management, employees)?"
        },
        {
            question: "Which ESG topic(s) are important to your company according to external stakeholders (clients, suppliers, communities)?"
        },
        {
            question: "Which ESG topic(s) does your company actively address in its day-to-day business operations?"
        },
    ]

    const optionRequiredQues = [
        {
            name: 'Environment',
            options: ["Climate Change", "Waste Management", "Water Management", "Energy Efficiency"]
        },
        {
            name: 'Social',
            options: ["Living Wages", "Human & Labour Rights", "Health & Safety", "Community engagement"]
        },
        {
            name: 'Governance',
            options: ["Anti-Corruption"]
        },
    ]

    const question2Optional = [
        {
            question: "Please select the 3 most important ESG indicators to your Company"
        },
    ]

    const optionOptionalQues = [
        {
            name: 'Environment',
            options: ["Climate Change", "Waste Management", "Water Management", "Energy Efficiency"]
        },
        {
            name: 'Social',
            options: ["Living Wages", "Human & Labour Rights", "Health & Safety", "Community engagement"]
        },
        {
            name: 'Governance',
            options: ["Anti-Corruption"]
        },
    ]

    /**
        * @function backToprevPage
        * @param
        * @description back To previous Page
    */
    const backToprevPage = () => {
        navigate('/')
    }

    /**
        * @function handleReset
        * @param
        * @description reset all form data
    */
    const handleReset = () => {
        reset();
    };

    return (
        <>
            <Row className=''>
                <Col className=''>
                    <div className='left-border'>
                        <Form>
                            <div className='my-question ps-0 ps-md-3'>
                                {props?.dimensionID && props?.dimensionID !== 1 && question2?.length > 0 && question2?.map((item, index) => (
                                    <Row className='p-4 pt-0'>
                                        <p className='fs-18 fw-bold'>{index + 1}. {item?.question}</p>
                                        {optionRequiredQues?.length > 0 && optionRequiredQues?.map((itemOption, indexOptioin) => (
                                            <>
                                                {optionRequiredQues?.length > 0 && optionRequiredQues?.map((itemOption, indexOptioin) => (
                                                    <>
                                                        <p className='fw-bold mt-3'>{itemOption?.name}</p>
                                                        <p className='ms-3 mt-1'>
                                                            {itemOption?.options?.length > 0 && itemOption?.options?.map((itemOptName, indexOptName) => (
                                                                <>
                                                                    <Form.Check // prettier-ignore
                                                                        type={"checkbox"}
                                                                        id={itemOptName}
                                                                        label={itemOptName}
                                                                    />

                                                                </>
                                                            ))}
                                                        </p>
                                                    </>
                                                ))}
                                            </>

                                        ))}
                                    </Row>
                                ))}

                                {/* Optional ques */}
                                {props?.dimensionID && props?.dimensionID !== 1 && question2Optional?.length > 0 && question2Optional?.map((item, index) => (
                                    <Row className='bgColor-Optional p-4 pt-'>
                                        <p className='fs-18 fw-bold'>{index + 1}. {item?.question}</p>
                                        {optionOptionalQues?.length > 0 && optionOptionalQues?.map((itemOption, indexOptioin) => (
                                            <>
                                                {optionRequiredQues?.length > 0 && optionRequiredQues?.map((itemOption, indexOptioin) => (
                                                    <>
                                                        <p className='fw-bold mt-3'>{itemOption?.name}</p>
                                                        <p className='ms-3 mt-1'>
                                                            {itemOption?.options?.length > 0 && itemOption?.options?.map((itemOptName, indexOptName) => (
                                                                <>
                                                                    <Form.Check // prettier-ignore
                                                                        type={"checkbox"}
                                                                        id={itemOptName}
                                                                        label={itemOptName}
                                                                    />

                                                                </>
                                                            ))}
                                                        </p>
                                                    </>
                                                ))}
                                            </>

                                        ))}
                                    </Row>
                                ))}

                                {/* Demographic */}
                                {props?.dimensionID && props?.dimensionID === 1 && question1?.length > 0 && question1?.map((item, index) => (
                                    <Row className='p-4 pt-0'>
                                        <p className='fs-18 fw-bold'>{index + 1}. {item?.question}</p>
                                        <p className='ms-3 mt-1'>
                                            {item?.options?.length > 0 && item?.options?.map((itemOptName, indexOptName) => (
                                                <>
                                                    <Form.Check // prettier-ignore
                                                        type={"radio"}
                                                        id={itemOptName}
                                                        label={itemOptName}
                                                    />

                                                </>
                                            ))}
                                        </p>
                                    </Row>
                                ))}
                                <Row>
                                    <Col>
                                        <div className='d-flex align-items-center p-3 ms-1'>
                                            <Button className="backButton me-4" variant='light' onClick={backToprevPage}>{languageUse === 'English' ? 'Back' : 'Kembali'}</Button>
                                            <Button className="question-submit-button me-4" variant='outline-primary' color="primary" type='submit'>{languageUse === 'English' ? 'Submit' : 'Hantar'}</Button>
                                            <Button className="cancelButton me-4" variant="outline-light" onClick={handleReset}>{languageUse === 'English' ? 'Clear' : 'Padam'}</Button>
                                            {savedSubmitButton && <Button className="text-light me-4" variant="success">{languageUse === 'English' ? 'Saved' : 'Disimpan'}</Button>}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Index