import React, { useState } from 'react'
import { useNavigate, useParam, Link, useLocation } from "react-router-dom";
import OtpInput from 'react-otp-input';
import {
    Row,
    Col,
    Container,
    Label,
    Spinner,
    Toast,
    Input,
    CardBody,
    CardTitle,
    CardHeader,
    FormFeedback,
} from 'reactstrap'

import { Button } from 'react-bootstrap'
import * as Icons from 'react-feather'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast, { Toaster } from 'react-hot-toast'

// API-Service
import { otpVerifyService, forgetPasswordService } from '../../../services/authService'

function OtpVerify() {

    const [isLoading, setLoading] = useState(false)
    const [otp, setOtp] = useState();
    const navigate = useNavigate()
    const { state } = useLocation();
    const Token = state?.tempToken;
    const UserEmail = state?.userEmail;

    // ** Hooks
    const {
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange' })

    /**
    * @function handleOTP
    * @param ()
    * @description handle OTP and pass to /resetPassword.
    */
    const handleOTP = async () => {
        setLoading(true)
        try {
            const result = await otpVerifyService({
                otp: parseInt(otp),
                Token
            });
            if (result?.data.statusCode === 200) {
                reset()
                setLoading(false)
                navigate('/resetPassword', {
                    state: {
                        Token
                    }
                });
            } else {
                // toast.success(result.data.statusCode)
                setLoading(false)
            };
            return false;

        } catch (error) {
            setLoading(false);
            toast.success(error.response.data.message)
        };
    }

    /**
    * @function onSubmit
    * @param (data)
    * @description to submit
    */
    const onSubmit = () => {
        // if (Object.values(data).every(field => field.length > 0)) {
        //     handleOTP(data)
        // }

        handleOTP()
    }

    /**
        * @function resendOTP
        * @params 
        * @description resend otp
    */
    const resendOTP = async () => {
        setLoading(true)
        try {
            const result = await forgetPasswordService({
                email: UserEmail,
            });

            const tempToken = result.data.data.tempToken;

            if (result?.data.statusCode === 200) {
                reset()
                setLoading(false)
            } else {
                toast.success(result.data.statusCode)
                setLoading(false)
            };
            return false;

        } catch (error) {
            setLoading(false);
            toast.error(error.response.data.message || 'Something went wrong')
        };
    }

    /**
        * @function changeEmail
        * @params 
        * @description go to forgotPassword page
    */
    const changeEmail = () => {
        navigate('/forgotPassword')
    }

    /**
        * @function handleOtp
        * @params value
        * @description set the value for otp
    */
    const handleOtp = (value) => {
        setOtp(value);
    }

    return (
        <Container>
            <div>
                <Toaster />
            </div>
            <Row>
                <div className="user-login d-flex flex-column mx-auto p-4 p-lg-5 pb-lg-3 authDiv text-start col-lg-5 col-md-6 col-sm-8">
                    <h1 className="sign">OTP Verification</h1>
                    <span className='login-border' />
                    <h5 className='auth-OTP-heading mt-3'>Enter the 6 digit code that you received on your email</h5>
                    <div>
                        <form className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
                            <div className='my-5'>
                                <OtpInput
                                    containerStyle='verfiction-input-div justify-content-around mb-3'
                                    inputStyle='verfiction-input rounded-2'
                                    focusStyle='border-0'
                                    value={otp}
                                    onChange={handleOtp}
                                    numInputs={6}
                                    isInputNum={true}
                                />

                            </div>
                            <Row className="py-4">
                                <Col xs lg="12" className='text-center'>
                                    <h3 className='auth-heading'><span className='signIn' onClick={changeEmail}>Change Email address</span></h3>
                                </Col>
                            </Row>

                            <div className='text-center'>
                                <Button className=" authButton login-btn-min-width" variant='outline-primary' color="primary" type="submit">
                                    {isLoading ? <Spinner variant='dark' size='sm' /> : 'Verify'}
                                </Button>
                            </div>
                        </form>

                        <Row className="py-4">
                            <Col xs lg="12" className='text-center'>
                                <h3 className='auth-heading'>Didn't received code? <span className='signIn' onClick={resendOTP}>Resend</span></h3>
                            </Col>
                        </Row>

                    </div>
                </div>

            </Row>
        </Container>
    )
}

export default OtpVerify