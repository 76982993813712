
import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function editProfileService
    * @param (authToken, username, email, company, designation, industry, country, state, city, companyReg)
    * @description call API to edit Porfile Service
*/
export const editProfileService = async ({ authToken, id, username, email, company, companyReg, companyLocation, designation, mobile, industry, country, state, city, companySize }) => {
    const result = await axios.patch(`${baseUrl}users/${id}`, {
        username,
        email,
        company,
        companyReg,
        companyLocation,
        designation,
        mobile,
        industry,
        country,
        state,
        city,
        companySize
    }, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}