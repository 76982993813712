// ** React Imports
import React, { Fragment, useState, forwardRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  NavbarText,
} from "reactstrap";

// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";
import QuestionPage from "../QuestionPage";
// import Home from "../Home";
// import Sidebar from "../../components/layouts/SidebarPage";
import Sidebar from "../../components/layouts/Sidebar";
import Header from "../../components/layouts/Navbar";
import Footer from '../../components/layouts/Footer'
//redux
import { selectLanguageUse } from '../../../redux/slices/language.Slice'

const NavbarPage = (args) => {
  const dispatch = useDispatch();
  const token = getToken();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = getUser()
  const [selectedLanguage, setSelectedLanguage] = useState();

  const [indicatorName, setIndicatorName] = useState();
  const [indicatorName_Malay, setIndicatorName_Malay] = useState();
  const [indicator_ID, setIndicator_ID] = useState();
  const [changeIndicatorName, setChangeIndicatorName] = useState(false);
  const [currentIndicator, setCurrentIndicator] = useState('')
  const languageUse = useSelector(selectLanguageUse || user?.language)


  return (
    <>
      <Fragment>
        <Header />
        <Container>
          <Row>
            <Col md="12" sm="12" className="my-4">
              <h5>
                {" "}
                {languageUse === 'English' ? indicatorName : indicatorName_Malay}
                {/* {selectedLanguage === 'English' ? indicatorName : indicatorName_Malay} */}

                {/* {indicatorName_Malay} */}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3" sm="12">
              <Sidebar indicatorName={indicatorName} setIndicatorName={setIndicatorName} setIndicatorName_Malay={setIndicatorName_Malay} setCurrentIndicator={setCurrentIndicator} setIndicator_ID={setIndicator_ID} changeIndicatorName={changeIndicatorName} setSelectedLanguage={setSelectedLanguage} />
            </Col>
            <Col md="9" sm="12" className="mt-3 mt-md-0">
              <QuestionPage indicatorName={indicatorName} currentIndicator={currentIndicator} indicator_ID={indicator_ID} changeIndicatorName={changeIndicatorName} setChangeIndicatorName={setChangeIndicatorName} />
            </Col>
          </Row>
        </Container>
        <Footer />
      </Fragment>
    </>
  );
};

export default NavbarPage;
