import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Spinner } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import ReactPaginate from 'react-paginate';
import { AiOutlineEye } from "react-icons/ai";
import { RxDashboard } from "react-icons/rx";
import { BsSkipEndCircle } from "react-icons/bs";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import moment from 'moment'
import CryptoJS from 'crypto-js';

// Custom Component
import NavbarComponent from "../../components/layouts/Navbar";
import UploadedDocumentsModal from "../../components/modal/UploadedDocumentsModal";
import ShowPdfModal from "../../components/modal/ShowPdfModal";
import Footer from '../../components/layouts/Footer'

// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";

//Redux-Service
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// API-Service
import { startSubmissionService, startProgressSubmissionService, getAssesmentListService, getProgressAssesmentListService } from '../../../services/submissionService';
import { getActiveDimensionListService, getActiveProgressDimensionListService } from "../../../services/dimensionService";

const History = () => {
    const Separation_Url = process.env.REACT_APP_SEPARATION
    const [isLoading, setLoading] = useState(false);
    const [assesmentLoading, setAssesmentLoading] = useState(false);
    const [assesmentList, setAssesmentList] = useState([]);
    const [progressAssesmentList, setProgressAssesmentList] = useState([]);
    const [dimensionList, setDimensionList] = useState([])
    const [progressDimensionList, setProgressDimensionList] = useState([])
    const [totalEntry, setTotalEntry] = useState()
    const [totalProgressEntry, setTotalProgressEntry] = useState()
    const [submissionId, setSubmissionId] = useState()
    const authToken = getToken()
    const user = getUser()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const [uploadedDocumentsModal, setUploadedDocumentsModal] = useState(false);
    const [progress, setProgress] = useState(false);
    const [pdfItem, setPdfItem] = useState();
    const [showPdfModalShow, setShowPdfModalShow] = useState(false);

    const handleShowPdfModalShow = () => setShowPdfModalShow(!showPdfModalShow);

    const UploadedDocumentsModaltoggle = (id, text) => {
        if (text === 'progressAssessment') {
            setProgress(true)
        } else {
            setProgress(false)
        }
        setSubmissionId(id)
        setUploadedDocumentsModal(!uploadedDocumentsModal)
    };

    const [currentPage, setCurrentPage] = useState(0)
    const [progressCurrentPage, setProgressCurrentPage] = useState(0)
    const [offsetCal, setoffsetCal] = useState(0)
    const [progressOffsetCal, setProgressOffsetCal] = useState(0)

    const pageLimit = 10

    /**
        * @function getDimensionList
        * @param
        * @description to get Dimension List
    */
    const getDimensionList = async () => {
        setLoading(true)
        try {
            const result = await getActiveDimensionListService({
                authToken,
            })
            if (result?.data?.statusCode === 200) {
                setDimensionList(result?.data?.data?.list)
                setLoading(false)
            } else {
                setLoading(false)
                setDimensionList([])
                toast.error(result.data.errorMessage || 'Something went wrong!')
            }

        } catch (error) {
            setLoading(false)
            setDimensionList([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    /**
        * @function getProgressDimensionList
        * @param
        * @description to get Progress Dimension List
    */
    const getProgressDimensionList = async () => {
        setLoading(true)
        try {
            const result = await getActiveProgressDimensionListService({
                authToken,
            })
            if (result?.data?.statusCode === 200) {
                setProgressDimensionList(result?.data?.data?.list)
                setLoading(false)
            } else {
                setLoading(false)
                setProgressDimensionList([])
                toast.error(result.data.errorMessage || 'Something went wrong!')
            }
        } catch (error) {
            setLoading(false)
            setProgressDimensionList([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    useEffect(() => {
        getDimensionList()
        getProgressDimensionList()
    }, []);

    /**
        * @function goToDashboard
        * @param S_id
        * @description navigate to Dashboard page
    */
    const goToDashboard = (S_id) => {
        const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
        navigate(`/user/dashboard/${encodeURIComponent(encryptedSubId)}`)
    };

    /**
        * @function goToProgressDashboard
        * @param S_id
        * @description navigate to Dashboard page
    */
    const goToProgressDashboard = (S_id) => {
        const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
        navigate(`/user/progress/dashboard/${encodeURIComponent(encryptedSubId)}`)
    };

    /**
        * @function goToAssociatedCompanies
        * @param
        * @description navigate to associated companines page
    */
    const goToAssociatedCompanies = () => {
        navigate('/associatedCompanies')
    };

    /**
        * @function goToProgressAssociatedCompanies
        * @param
        * @description navigate to progress associated companines page
    */
    const goToProgressAssociatedCompanies = () => {
        navigate('/progress/associatedCompanies')
    };

    /**
        * @function handleEdit
        * @param status, S_id, text
        * @description use encrypted submission id to go to the question page.
    */
    const handleEdit = (status, S_id, text) => {
        const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
        if (text === 'progressAssessment') {
            if (status) {
                navigate(`/progress/${encodeURIComponent(encryptedSubId)}`)
            } else {
                navigate(`/view/progress/${encodeURIComponent(encryptedSubId)}`)
            }
        } else {
            if (status) {
                navigate(`/${encodeURIComponent(encryptedSubId)}`)
            } else {
                navigate(`/view/${encodeURIComponent(encryptedSubId)}`)
            }
        }
    }

    /**
        * @function getDocumentsView
        * @param status, id, text
        * @description use condition to return desired button.
    */
    const getDocumentsView = (status, id, text) => {
        if (!status) {
            return <Button className="login-btn-min-width border rounded-3 px-4" onClick={() => UploadedDocumentsModaltoggle(id, text)} variant='primary'>
                <span>{languageUse === 'English' ? 'View' : 'Lihat'}</span>
            </Button>
        } else {
            return <>--</>
        }
    }

    /**
        * @function getStatus
        * @param status, id, text
        * @description use condition to return desired button.
    */
    const getStatus = (status, id, text) => {
        if (!status) {
            return <Button className="statusComplete login-btn-min-width border rounded-pill" onClick={() => handleEdit(status, id, text)} variant='outline-primary'>
                <span>{languageUse === 'English' ? 'Completed' : 'Selesai'}</span>
            </Button>
        } else {
            return <Button className="statusPending login-btn-min-width border rounded-pill" variant='outline-primary' onClick={() => handleEdit(status, id, text)}>
                <span>{languageUse === 'English' ? 'Pending' : 'Belum selesai'}</span>
            </Button>
        }
    }

    /**
        * @function getStatusIcon
        * @param status, id
        * @description use condition to return desired icon.
    */
    const getStatusIcon = (status, id) => {
        if (!status) {
            return <div className="d-flex flex-row align-items-center">
                <AiOutlineEye style={{ fontSize: "20px" }} cursor={'pointer'} onClick={() => handleEdit(status, id)}></AiOutlineEye>
                <RxDashboard style={{ fontSize: "16px", marginLeft: '2rem' }} cursor={'pointer'} onClick={() => goToDashboard(id)}></RxDashboard>
            </div>
        } else {
            return <BsSkipEndCircle style={{ fontSize: "20px" }} cursor={'pointer'} onClick={() => handleEdit(status, id)} />
        }
    }

    /**
        * @function getViewIcon
        * @param status, id
        * @description use condition to return desired icon.
    */
    const getViewIcon = (status, id, text) => {
        if (!status) {
            return <div className="d-flex justify-content-center align-items-center">
                <AiOutlineEye role='button' size={20} onClick={() => handleEdit(status, id, text)} />
            </div>
        } else {
            return <BsSkipEndCircle style={{ fontSize: "20px" }} cursor={'pointer'} onClick={() => handleEdit(status, id, text)} />
        }
    }

    /**
        * @function getReportIcon
        * @param status, id
        * @description use condition to return desired icon.
    */
    const getReportIcon = (status, id) => {
        if (!status) {
            return <div className="d-flex flex-row align-items-center">
                <RxDashboard style={{ fontSize: "16px", marginLeft: '2rem' }} cursor={'pointer'} onClick={() => goToDashboard(id)}></RxDashboard>
            </div>
        }
    }

    /**
        * @function getReportProgressIcon
        * @param status, id
        * @description use condition to return desired icon.
    */
    const getReportProgressIcon = (status, id) => {
        if (!status) {
            return <div className="d-flex flex-row align-items-center">
                <RxDashboard style={{ fontSize: "16px", marginLeft: '2rem' }} cursor={'pointer'} onClick={() => goToProgressDashboard(id)}></RxDashboard>
            </div>
        }
    }

    /**
        * @function handleAssessmentPagination
        * @param page
        * @description Function to handle Pagination For Assessment
    */
    const handleAssessmentPagination = page => {
        setCurrentPage(page.selected)
        setoffsetCal(page.selected * 10)
    }

    /**
        * @function handleProgressAssessmentPagination
        * @param page
        * @description Function to handle Pagination For Progress Assessment
    */
    const handleProgressAssessmentPagination = page => {
        setProgressCurrentPage(page.selected)
        setProgressOffsetCal(page.selected * 10)
    }

    /**
        * @function getAssesmentList
        * @param
        * @description get Assesment List
    */
    const getAssesmentList = async () => {
        try {
            setAssesmentLoading(true)
            const result = await getAssesmentListService({
                authToken,
                limit: pageLimit,
                offset: offsetCal
            });
            setTotalEntry(result?.data?.data?.count)
            setAssesmentList(result?.data?.data?.list)
            setAssesmentLoading(false)
        } catch (error) {
            setAssesmentLoading(false)
            setAssesmentList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        getAssesmentList()
    }, [offsetCal])

    /**
        * @function getProgressAssesmentList
        * @param
        * @description get Progress Assesment List
    */
    const getProgressAssesmentList = async () => {
        try {
            setAssesmentLoading(true)
            const result = await getProgressAssesmentListService({
                authToken,
                limit: pageLimit,
                offset: progressOffsetCal
            });
            setTotalProgressEntry(result?.data?.data?.count)
            setProgressAssesmentList(result?.data?.data?.list)
            setAssesmentLoading(false)
        } catch (error) {
            setAssesmentLoading(false)
            setProgressAssesmentList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        getProgressAssesmentList()
    }, [progressOffsetCal])

    /**
        * @function startAssesment
        * @param ()
        * @description handle API to start the assesment
    */
    const startAssesment = async () => {
        try {
            const result = await startSubmissionService({
                authToken
            });
            const S_id = result?.data?.data?._id
            localStorage.setItem('submissionId', S_id);

            if (result?.data?.statusCode === 200) {
                const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
                navigate(`/${encodeURIComponent(encryptedSubId)}`)
                setLoading(false)
                dispatch(submissionId(S_id));
            } else {
                toast.success(result?.message)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    /**
        * @function startProgressAssesment
        * @param ()
        * @description handle API to start the progress assesment
    */
    const startProgressAssesment = async () => {
        try {
            const result = await startProgressSubmissionService({
                authToken
            });
            const S_id = result?.data?.data?._id

            if (result?.data?.statusCode === 200) {
                const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
                navigate(`/progress/${encodeURIComponent(encryptedSubId)}`)
                setLoading(false)
                dispatch(submissionId(S_id));
            } else {
                toast.success(result?.message)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    return <>
        <div>
            <Toaster />
        </div>

        <NavbarComponent />

        {assesmentLoading ? <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
            <Spinner animation="grow" />
        </div> : <>
            <div className="container history-text-color">
                {/* ESG */}
                {/* Separation_Url === 'ESG' || Separation_Url === 'PROGRESS' */}
                {(Separation_Url === 'ESG') && <div>
                    <h2 className="mb-4">{languageUse === 'English' ? "ESG START Submission History" : "Sejarah Penyerahan ESG START"}</h2>
                    <div className="d-flex align-items-center justify-content-between">
                        <Button className="float-start authButton login-btn-min-width mb-4 me-2" variant='outline-primary' onClick={startAssesment}>
                            {isLoading ? <Spinner variant='dark' size='sm' /> : `${languageUse === 'English' ? 'Start New Assessment' : 'Mula Penilaian Baru'}`}
                        </Button>
                        <Link to="/esg2/38783u34989lo2">
                            <Button className="float-start authButton login-btn-min-width mb-4 me-2" variant='outline-primary'>
                                {isLoading ? <Spinner variant='dark' size='sm' /> : `${languageUse === 'English' ? 'ESG 2.0' : 'ESG 2.0'}`}
                            </Button>
                        </Link>

                        {user?.isCorporate && <Button className="float-start authButton login-btn-min-width mb-4" variant='outline-primary' onClick={goToAssociatedCompanies}>
                            {isLoading ? <Spinner variant='dark' size='sm' /> : `${languageUse === 'English' ? 'Associated Companies' : 'Syarikat Berkaitan'}`}
                        </Button>}
                    </div>

                    {assesmentList?.length > 0 && dimensionList?.length > 0 ? <>
                        {/* Design for large screen */}
                        <div className="d-none d-md-block">
                            <table className="table border">
                                <thead className="table-head">
                                    <tr className="history-text-color">
                                        <th scope="col">{languageUse === 'English' ? 'Date' : 'Tarik'}</th>
                                        <th scope="col">{languageUse === 'English' ? dimensionList[1]?.name : dimensionList[1]?.name_Malay} Score</th>
                                        <th scope="col">{languageUse === 'English' ? dimensionList[2]?.name : dimensionList[2]?.name_Malay} Score</th>
                                        <th scope="col">{languageUse === 'English' ? dimensionList[3]?.name : dimensionList[3]?.name_Malay} Score</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">{languageUse === 'English' ? 'Document' : 'Dokumen'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'View Submission' : 'Lihat penyerahan'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'View Report' : 'Lihat laporan'}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {assesmentList?.length > 0 && assesmentList?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{moment(item?.updatedAt).format("DD-MM-YYYY")}</td>
                                            <td>{parseInt(item?.results[0]?.score) ? `${item?.results[0]?.score.toFixed(2)}%` : '--'}</td>
                                            <td>{parseInt(item?.results[1]?.score) ? `${item?.results[1]?.score.toFixed(2)}%` : '--'}</td>
                                            <td>{parseInt(item?.results[2]?.score) ? `${item?.results[2]?.score.toFixed(2)}%` : '--'}</td>
                                            <td>{getStatus(item.isDraft, item._id, 'assessment')}</td>
                                            <td>{getDocumentsView(item.isDraft, item._id, 'assessment')}</td>
                                            <td className="text-center">{getViewIcon(item.isDraft, item._id, 'assessment')}</td>
                                            <td>{getReportIcon(item.isDraft, item._id)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Design for small screen */}
                        <div className="d-md-none">
                            <div className="col-12 d-flex flex-column align-items-stretch ">
                                {assesmentList?.length > 0 && assesmentList?.map((item, index) => (
                                    <div className="border p-3 mb-3" key={index}>
                                        <Row className="d-flex mb-0">
                                            <div className="col-6">
                                                <p className="">
                                                    {moment(item?.updatedAt).format("DD-MM-YYYY")}
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <div className="float-end">
                                                    {getStatus(item.isDraft, item._id, 'assessment')}
                                                </div>
                                            </div>

                                        </Row>
                                        <Row>
                                            <div className="col-12">
                                                <div className="float-end">
                                                    {getStatusIcon(item.isDraft, item._id)}
                                                </div>
                                            </div>
                                        </Row>
                                        <Row className="d-flex">
                                            <div className="col-6">
                                                <p className="">
                                                    Environment Score
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <p className="float-end">
                                                    {item?.results[0]?.score ? `${item?.results[0]?.score.toFixed(2)}%` : '--'}
                                                </p>
                                            </div>
                                        </Row>
                                        <Row className="d-flex">
                                            <div className="col-6">
                                                <p className="">
                                                    Social Score
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <p className="float-end">
                                                    {item?.results[1]?.score ? `${item?.results[1]?.score.toFixed(2)}%` : '--'}

                                                </p>
                                            </div>
                                        </Row>
                                        <Row className="d-flex">
                                            <div className="col-6">
                                                <p className="">
                                                    Governance Score
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <p className="float-end">
                                                    {item?.results[2]?.score ? `${item?.results[2]?.score.toFixed(2)}%` : '--'}
                                                </p>
                                            </div>
                                        </Row>
                                        <div className="d-flex justify-content-between">

                                            <p className="me-3">Documents</p>
                                            <div className="ms-3">
                                                {getDocumentsView(item.isDraft, item._id, 'assessment')}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <ReactPaginate
                            nextLabel={<GrFormNext />}
                            breakLabel='...'
                            previousLabel={<GrFormPrevious />}
                            pageRangeDisplayed={2}
                            forcePage={currentPage}
                            marginPagesDisplayed={2}
                            activeClassName='active'
                            pageClassName='page-item rounded-circle me-2'
                            breakClassName='page-item rounded-circle me-2'
                            nextLinkClassName='page-link me-2'
                            pageLinkClassName='page-link rounded-circle me-2'
                            breakLinkClassName='page-lin rounded-circle me-2'
                            previousLinkClassName='page-link me-2'
                            nextClassName='page-item next-item me-2'
                            previousClassName='page-item prev-item me-2'
                            pageCount={Math.ceil(totalEntry / 10) || 1}
                            onPageChange={page => handleAssessmentPagination(page)}
                            containerClassName='pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
                        />
                    </> : <>
                        <div className='pt-5 ps-0'>
                            <div className='w-100 rounded bgColor-noQ height-noQ d-flex align-items-center justify-content-center'>
                                <p>{languageUse === 'English' ? "Please start the assessment." : "Sila mulakan penilaian."}</p>
                            </div>
                        </div>
                    </>
                    }
                </div>}

                {/* Progress */}
                {/* {(Separation_Url === 'ESG') && <div> */}
                {(Separation_Url === 'PROGRESS') && <div>
                    <h2 className="mt-5 mb-4">{languageUse === 'English' ? "PRO-GRESS Assessment History" : "Sejarah Penilaian PRO-GRESS"}</h2>
                    <div className="d-flex align-items-center justify-content-between">
                        <Button className="float-start authButton login-btn-min-width mb-4 me-2" variant='outline-primary' onClick={startProgressAssesment}>
                            {isLoading ? <Spinner variant='dark' size='sm' /> : `${languageUse === 'English' ? 'Start New Assessment' : 'Mula Penilaian Baru'}`}
                        </Button>

                        {user?.isCorporate && <Button className="float-start authButton login-btn-min-width mb-4" variant='outline-primary' onClick={goToProgressAssociatedCompanies}>
                            {isLoading ? <Spinner variant='dark' size='sm' /> : `${languageUse === 'English' ? 'Associated Companies' : 'Syarikat Berkaitan'}`}
                        </Button>}
                    </div>

                    {progressAssesmentList?.length > 0 && progressDimensionList?.length > 0 ? <>
                        {/* Design for large screen */}
                        <div className="d-none d-md-block">
                            <table className="table border">
                                <thead className="table-head">
                                    <tr className="history-text-color">
                                        <th scope="col">{languageUse === 'English' ? 'Date' : 'Tarik'}</th>
                                        <th scope="col" className="text-capitalize">{languageUse === 'English' ? progressDimensionList[0]?.name : progressDimensionList[1]?.name_Malay}</th>
                                        <th scope="col" className="text-capitalize">{languageUse === 'English' ? progressDimensionList[1]?.name : progressDimensionList[1]?.name_Malay}</th>
                                        <th scope="col" className="text-capitalize">{languageUse === 'English' ? progressDimensionList[2]?.name : progressDimensionList[2]?.name_Malay}</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">{languageUse === 'English' ? 'Document' : 'Dokumen'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'View Submission' : 'Lihat penyerahan'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'View Report' : 'Lihat laporan'}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {progressAssesmentList?.length > 0 && progressAssesmentList?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{moment(item?.updatedAt).format("DD-MM-YYYY")}</td>
                                            <td>{item?.dimensionScoring?.length > 0 && parseInt(item?.dimensionScoring[0]?.correct) ? `${((item?.dimensionScoring[0]?.correct / item?.dimensionScoring[0]?.total) * 100).toFixed(2)}%` : '0%'}</td>
                                            <td>{item?.dimensionScoring?.length > 0 && parseInt(item?.dimensionScoring[1]?.correct) ? `${((item?.dimensionScoring[1]?.correct / item?.dimensionScoring[1]?.total) * 100).toFixed(2)}%` : '0%'}</td>
                                            <td>{item?.dimensionScoring?.length > 0 && parseInt(item?.dimensionScoring[2]?.correct) ? `${((item?.dimensionScoring[2]?.correct / item?.dimensionScoring[2]?.total) * 100).toFixed(2)}%` : '0%'}</td>
                                            <td>{getStatus(item.isDraft, item._id, 'progressAssessment')}</td>
                                            <td>{getDocumentsView(item.isDraft, item._id, 'progressAssessment')}</td>
                                            <td className="text-center">{getViewIcon(item.isDraft, item._id, 'progressAssessment')}</td>
                                            <td>{getReportProgressIcon(item.isDraft, item._id)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Design for small screen */}
                        <div className="d-md-none">
                            <div className="col-12 d-flex flex-column align-items-stretch ">
                                {progressAssesmentList?.length > 0 && progressAssesmentList?.map((item, index) => (
                                    <div className="border p-3 mb-3" key={index}>
                                        <Row className="d-flex mb-0">
                                            <div className="col-6">
                                                <p className="">
                                                    {moment(item?.updatedAt).format("DD-MM-YYYY")}
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex align-items-center float-end">
                                                    {getViewIcon(item.isDraft, item._id, 'progressAssessment')}
                                                    {getReportProgressIcon(item.isDraft, item._id)}
                                                </div>
                                            </div>
                                        </Row>
                                        <Row className="d-flex">
                                            <div className="col-6">
                                                <p className="">
                                                    Climate Governance
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <p className="float-end">
                                                    {item?.dimensionScoring?.length > 0 && parseInt(item?.dimensionScoring[0]?.correct) ? `${((item?.dimensionScoring[0]?.correct / item?.dimensionScoring[0]?.total) * 100).toFixed(2)}%` : '0%'}
                                                </p>
                                            </div>
                                        </Row>
                                        <Row className="d-flex">
                                            <div className="col-6">
                                                <p className="">
                                                    GHG Emission Reduction
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <p className="float-end">
                                                    {item?.dimensionScoring?.length > 0 && parseInt(item?.dimensionScoring[1]?.correct) ? `${((item?.dimensionScoring[1]?.correct / item?.dimensionScoring[1]?.total) * 100).toFixed(2)}%` : '0%'}
                                                </p>
                                            </div>
                                        </Row>
                                        <Row className="d-flex">
                                            <div className="col-6">
                                                <p className="">
                                                    Business Strategy Integration
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <p className="float-end">
                                                    {item?.dimensionScoring?.length > 0 && parseInt(item?.dimensionScoring[2]?.correct) ? `${((item?.dimensionScoring[2]?.correct / item?.dimensionScoring[2]?.total) * 100).toFixed(2)}%` : '0%'}
                                                </p>
                                            </div>
                                        </Row>
                                        <div className="d-flex justify-content-between">
                                            {!item.isDraft &&
                                                <div className="d-flex align-items-center">
                                                    <p className="mb-0 me-3">Documents</p>
                                                    <p role='button' className="my-0 me-3 text-primary" onClick={() => UploadedDocumentsModaltoggle(item._id, 'progressAssessment')}>View</p>
                                                </div>
                                            }
                                            <div className="ms-auto">
                                                {getStatus(item.isDraft, item._id, 'progressAssessment')}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <ReactPaginate
                            nextLabel={<GrFormNext />}
                            breakLabel='...'
                            previousLabel={<GrFormPrevious />}
                            pageRangeDisplayed={2}
                            forcePage={progressCurrentPage}
                            marginPagesDisplayed={2}
                            activeClassName='active'
                            pageClassName='page-item rounded-circle me-2'
                            breakClassName='page-item rounded-circle me-2'
                            nextLinkClassName='page-link me-2'
                            pageLinkClassName='page-link rounded-circle me-2'
                            breakLinkClassName='page-lin rounded-circle me-2'
                            previousLinkClassName='page-link me-2'
                            nextClassName='page-item next-item me-2'
                            previousClassName='page-item prev-item me-2'
                            pageCount={Math.ceil(totalProgressEntry / 10) || 1}
                            onPageChange={page => handleProgressAssessmentPagination(page)}
                            containerClassName='pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
                        />
                    </> : <>
                        <div className='pt-5 ps-0'>
                            <div className='w-100 rounded bgColor-noQ height-noQ d-flex align-items-center justify-content-center'>
                                <p>{languageUse === 'English' ? "Please start the progress assessment." : "Sila mulakan penilaian kemajuan."}</p>
                            </div>
                        </div>
                    </>
                    }
                </div>}
            </div>
        </>}

        <Footer />
        <UploadedDocumentsModal modal={uploadedDocumentsModal} toggle={UploadedDocumentsModaltoggle} handleShowPdfModalShow={handleShowPdfModalShow} submissionId={submissionId} setPdfItem={setPdfItem} progress={progress} />
        <ShowPdfModal show={showPdfModalShow} handleClose={handleShowPdfModalShow} pdfItem={pdfItem} />
    </>
};

export default History;
