import { Nav } from 'rsuite';

const AdvancedAnalyticsSidebar = (props) => {
    return <div>
        <Nav className='d-flex flex-wrap' activeKey={props?.activeKey} onSelect={(eventKey, event) => {
            props?.setActiveKey(eventKey)
        }}>
            <Nav.Item eventKey="1">
                ESG Maturity Levels
            </Nav.Item>
            <Nav.Item eventKey="2">ESG Performance</Nav.Item>
            <Nav.Item eventKey="3">Overview of ESG indicators</Nav.Item>
            <Nav.Item eventKey="4">ESG actions</Nav.Item>
            <Nav.Item eventKey="5">SDG Mapping</Nav.Item>
            <Nav.Item eventKey="6">Demographics</Nav.Item>
        </Nav>
    </div>
};

export default AdvancedAnalyticsSidebar