import React from 'react'
// Custom Component
import NavbarComponent from "../../components/layouts/Navbar";
import AssociatedCompanies from '../../components/associatedCompanies/AssociatedCompanies';
import Footer from '../../components/layouts/Footer'

const ProgressAssociatedCompaniesPage = () => {
  return (
    <>
      <NavbarComponent />
      <AssociatedCompanies />
      <Footer />
    </>
  )
}

export default ProgressAssociatedCompaniesPage