import { useState, useEffect } from 'react'
import { Modal, ModalFooter, ModalHeader, ModalBody, Form, Label, Input, Button, FormFeedback, Spinner } from "reactstrap";
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import { useForm, Controller } from 'react-hook-form';
import { Toaster, toast } from 'react-hot-toast'
import { useDispatch, useSelector } from "react-redux";

// Redux-Functions
import { setLoginDetails } from '../../../redux/slices/auth.Slice';
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// Utility Service
import { getToken, getReFreshToken, getUser, setUserSession } from '../../../utility/AuthService';

// Images
import ProfileIcon from '../../../assets/images/profile/ProfileIcon.svg';

// API Service
import { getIndustry } from '../../../services/industryService';
import { uploadProfileImageService } from '../../../services/uploadProfileImageService';
import { editProfileService } from '../../../services/editProfileService';
import { demographicsListService } from '../../../services/demographicsService';

const EditProfileModal = (props) => {
    const token = getToken();
    const reFreshToken = getReFreshToken();
    const user = getUser();

    const dispatch = useDispatch();
    const languageUse = useSelector(selectLanguageUse || user?.language);

    const [profileImage, setProfileImage] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [industryList, setIndustryList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const { control, handleSubmit, watch, setValue, reset, setError, clearErrors, formState: { errors } } = useForm();
    const watchCountry = watch("country")
    const watchState = watch("state")

    const companySizeList = [
        {
            value: "Less than RM 300,000",
            label: "Less than RM 300,000"
        },
        {
            value: "RM 300,000 - 3 mil",
            label: "RM 300,000 - 3 mil"
        },
        {
            value: "RM 3 mil - 20 mil",
            label: "RM 3 mil - 20 mil"
        },
        {
            value: "RM 20 mil - 50 mil",
            label: "RM 20 mil - 50 mil"
        },
        {
            value: "RM 50 mil - 100 mil",
            label: "RM 50 mil - 100 mil"
        },
        {
            value: "RM 100 mil +",
            label: "RM 100 mil +"
        }
    ]

    /**
        * @function getCountryList
        * @param
        * @description get country list using api
    */
    const getCountryList = async () => {
        try {
            const result = await demographicsListService({
                type: 'Country',
                typeId: ""
            });
            if (result?.data?.statusCode === 200) {
                setCountryList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setCountryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function getStateList
        * @param
        * @description get state list using api
    */
    const getStateList = async () => {
        try {
            const result = await demographicsListService({
                type: 'State',
                typeId: watchCountry?.value
            });
            if (result?.data?.statusCode === 200) {
                setStateList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setStateList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function getCityList
        * @param
        * @description get city list using api
    */
    const getCityList = async () => {
        try {
            const result = await demographicsListService({
                type: 'City',
                typeId: watchState?.value
            });
            if (result?.data?.statusCode === 200) {
                setCityList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setCityList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getCountryList()
    }, []);

    useEffect(() => {
        if (watchCountry?.value) {
            getStateList()
        }
    }, [watchCountry]);

    useEffect(() => {
        if (watchState?.value) {
            getCityList()
        }
    }, [watchState]);

    /**
        * @function getIndustryID
        * @param
        * @description get industry list using api
    */
    const getIndustryID = async () => {
        try {
            const result = await getIndustry({
                filter: 'Active',
                offset: 0
            });
            setIndustryList(result?.data?.data?.list.map(item => {
                return {
                    value: item?._id,
                    label: item?.name
                }
            }))
        } catch (error) {
            setIndustryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getIndustryID()
    }, []);

    /**
        * @function handleEditProfile
        * @param data
        * @description to update profile data
    */
    const handleEditProfile = async (data) => {
        try {
            setLoading(true)
            const result = await editProfileService({
                authToken: token,
                id: user?._id,
                username: data?.username,
                email: data?.email,
                company: data?.company,
                companyReg: data?.companyRegNum,
                companyLocation: data?.companyAddress,
                designation: data?.designation,
                mobile: data?.mobile,
                industry: data?.industry?.value,
                country: data?.country?.label,
                state: data?.state?.label,
                city: data?.city?.label,
                companySize: data?.companySize?.value
            });

            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setUserSession(token, result?.data?.data, reFreshToken);
                dispatch(setLoginDetails(result?.data?.data));
                localStorage.setItem('accessToken', token);
                localStorage.setItem('refreshToken', reFreshToken);
                localStorage.setItem('userData', JSON.stringify(result?.data?.data));
                props.toggle()
                toast.success('Profile Updated Successfully')
            } else {
                setLoading(false)
                toast.error(result?.response?.data?.message || 'Something went wrong')
            };
            return false;
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    }

    /**
        * @function handleUploadImage
        * @param
        * @description to upload image
    */
    const handleUploadImage = async () => {
        const data = new FormData();
        data.append('file', profileImage);

        try {
            setLoading(true)
            const result = await uploadProfileImageService({
                authToken: token,
                file: data,
                id: user?._id
            });

            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setUserSession(token, result?.data?.data, reFreshToken);
                dispatch(setLoginDetails(result?.data?.data));
                localStorage.setItem('accessToken', token);
                localStorage.setItem('refreshToken', reFreshToken);
                localStorage.setItem('userData', JSON.stringify(result?.data?.data));
                toast.success('Image Updated Successfully')
                props.toggle()
            } else {
                setLoading(false)
                toast.error(result?.response?.data?.message || 'Something went wrong')
            };
            return false;
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    /**
        * @function onSubmit
        * @param data
        * @description to submit
    */
    const onSubmit = async (data) => {
        if (profileImage instanceof File) {
            await handleUploadImage()
        }
        if (data) {
            handleEditProfile(data)
        }
    }

    useEffect(() => {
        if (industryList?.length > 0 && countryList?.length > 0 && user) {
            const defaultValues = {};
            defaultValues.username = user?.username
            defaultValues.email = user?.email
            defaultValues.company = user?.company
            defaultValues.companyRegNum = user?.companyReg
            defaultValues.companyAddress = user?.companyLocation
            defaultValues.designation = user?.designation
            defaultValues.mobile = user?.mobile
            defaultValues.industry = industryList?.find(item => item?.value === user?.industry?._id)
            defaultValues.country = countryList?.find(item => item?.label === user?.country)
            defaultValues.companySize = companySizeList?.find(item => item?.value === user?.companySize)
            reset({ ...defaultValues })
        }
    }, [industryList, countryList])

    useEffect(() => {
        if (watchCountry?.label !== user?.country) {
            setValue("state", null)
            setValue("city", null)
        }
        // eslint-disable-next-line
    }, [watchCountry])

    useEffect(() => {
        if (watchState?.label !== user?.state) {
            setValue("city", null)
        }
        // eslint-disable-next-line
    }, [watchState])

    useEffect(() => {
        if (user?.state && stateList?.length > 0 && stateList?.some(item => item?.label === user?.state)) {
            setValue("state", stateList?.find(item => item?.label === user?.state))
        }
        // eslint-disable-next-line
    }, [stateList])

    useEffect(() => {
        if (user?.city && cityList?.length > 0 && cityList?.some(item => item?.label === user?.city)) {
            setValue("city", cityList?.find(item => item?.label === user?.city))
        }
        // eslint-disable-next-line
    }, [cityList])

    return <>
        <div>
            <Toaster />
        </div>
        <Modal
            isOpen={props.modal}
            toggle={props.toggle}
            backdropClassName='bg-dark'
        >
            <ModalHeader className='border-0'>{languageUse === 'English' ? 'Edit Profile' : 'Sunting profil'}</ModalHeader>
            <Form className='auth-login-form mt-3' onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <div className='text-center'>
                        <div className='rounded-circle my-1'>
                            {profileImage instanceof File ? <img className='rounded-circle' src={URL.createObjectURL(profileImage)} alt='profileImage222' width={80} height={80} /> : props?.proImage ? <img className='rounded-circle' src={props?.proImage} width={80} height={80} alt='profileImage' /> : <img className='' src={ProfileIcon} alt='profile' width={80} height={80} />}

                            <input className='d-none' type='file' id="profileLogoUpload" accept="image/*" onChange={(e) => {
                                if (e.target.files?.length > 0) {
                                    setProfileImage(e.target.files[0])
                                }
                            }} onClick={(e) => {
                                e.target.value = null
                            }} />
                        </div>
                        <label htmlFor="profileLogoUpload">
                            <div role='button' className='text-primary'>
                                <p>{languageUse === 'English' ? 'Change Profile Picture' : 'Tukar gambar profil'}</p>
                            </div>
                        </label>
                    </div>
                    <div className='mb-4'>
                        <Label className='form-label' for='username'>
                            {languageUse === 'English' ? 'Username' : 'Nama pengguna'}
                        </Label>
                        <Controller
                            id='username'
                            name='username'
                            control={control}
                            render={({ field }) => (
                                <Input {...field} type='text' placeholder={languageUse === 'English' ? 'username' : "nama pengguna"} invalid={errors.username && true} />
                            )}
                        />
                        {errors.username && <FormFeedback>{errors.username.message}</FormFeedback>}
                    </div>
                    <div className='mb-3'>
                        <Label className='form-label' for='email'>
                            {languageUse === 'English' ? 'Email' : 'E-mel'}
                        </Label>
                        <Controller
                            id='email'
                            name='email'
                            control={control}
                            render={({ field }) => (
                                <Input {...field} type='email' placeholder={languageUse === 'English' ? 'Enter registered email' : "Masukkan e-mel berdaftar"} invalid={errors.email && true} />
                            )}
                        />
                        {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
                    </div>

                    <div className='mb-4'>
                        <Label className='custom-label form-label' for='company'>
                            {languageUse === 'English' ? 'Company Name' : 'Nama Syarikat'}
                        </Label>
                        <Controller
                            id='company'
                            name='company'
                            defaultValue=''
                            control={control}
                            render={({ field }) => (
                                <Input {...field} placeholder={languageUse === 'English' ? 'Company Name' : 'Nama Syarikat'} invalid={errors.company && true} />
                            )}
                        />
                        {errors.company && <FormFeedback>{errors.company.message}</FormFeedback>}
                    </div>

                    <div className='mb-4'>
                        <Label className='custom-label form-label' for='companyRegNum'>
                            {languageUse === 'English' ? 'Company Registration Number' : 'Nombor Pendaftaran Syarikat'}
                        </Label>
                        <Controller
                            id='companyRegNum'
                            name='companyRegNum'
                            defaultValue=''
                            control={control}
                            render={({ field }) => (
                                <Input {...field} placeholder={languageUse === 'English' ? 'Company Registration Number' : 'Nombor Pendaftaran Syarikat'} invalid={errors.companyRegNum && true} />
                            )}
                        />
                        {errors.companyRegNum && <FormFeedback>{errors.companyRegNum.message}</FormFeedback>}
                    </div>

                    <div className='mb-4'>
                        <Label className='custom-label form-label' for='companyAddress'>
                            {languageUse === 'English' ? 'Company Address' : 'Lokasi Syarikat'}
                        </Label>
                        <Controller
                            id='companyAddress'
                            name='companyAddress'
                            defaultValue=''
                            control={control}
                            render={({ field }) => (
                                <Input {...field} placeholder={languageUse === 'English' ? 'Company Address' : 'Lokasi Syarikat'} invalid={errors.companyAddress && true} />
                            )}
                        />
                        {errors.companyAddress && <FormFeedback>{errors.companyAddress.message}</FormFeedback>}
                    </div>

                    <div className='mb-4'>
                        <Label className='custom-label form-label' for='designation'>
                            {languageUse === 'English' ? 'Designation' : 'Jawatan'}
                        </Label>
                        <Controller
                            id='designation'
                            name='designation'
                            defaultValue=''
                            control={control}
                            render={({ field }) => (
                                <Input {...field} placeholder={languageUse === 'English' ? 'Designation' : 'Jawatan'} invalid={errors.designation && true} />
                            )}
                            rules={{
                                required: languageUse === 'English' ? 'Designation is required' : 'Jawatan is required'
                            }}
                        />
                        {errors.designation && <FormFeedback>{errors.designation.message}</FormFeedback>}
                    </div>

                    <div className='mb-4'>
                        <Label className='custom-label form-label' for='mobile'>
                            {languageUse === 'English' ? 'Mobile' : 'Mudah alih'}
                        </Label>
                        <Controller
                            id='mobile'
                            name='mobile'
                            defaultValue=''
                            control={control}
                            render={({ field }) => (
                                <PhoneInput
                                    placeholder={languageUse === 'English' ? 'Mobile' : 'Mudah alih'}
                                    defaultCountry="MY"
                                    value={field?.value}
                                    onChange={(e) => {
                                        field.onChange(e)
                                    }}
                                    {...field}
                                />
                            )}
                        />
                        {errors.mobile && <FormFeedback>{errors.mobile.message}</FormFeedback>}
                    </div>

                    <div className='mb-4'>
                        <Label className='custom-label form-label' for='company'>
                            {languageUse === 'English' ? 'Industry' : 'industri'}
                        </Label>
                        <Controller
                            name="industry"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    className='text-secondary'
                                    placeholder={languageUse === 'English' ? "Select Industry" : "Pilih Industri"}
                                    isClearable
                                    options={industryList}
                                    isSearchable={true}
                                    {...field}
                                />
                            )}
                            rules={{
                                required: languageUse === 'English' ? 'Industry is required' : 'Industri is required'
                            }}
                        />
                        {errors?.industry?.message && <span className='text-danger'>{errors?.industry?.message}</span>}
                    </div>

                    <div className='mb-4'>
                        <Label className='custom-label form-label' for='company'>
                            {languageUse === 'English' ? 'Country' : 'Negara'}
                        </Label>
                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    className='text-secondary'
                                    placeholder={languageUse === 'English' ? "Select Country" : "Pilih Negara"}
                                    isClearable
                                    options={countryList}
                                    isSearchable={true}
                                    {...field}
                                />
                            )}
                            rules={{
                                required: languageUse === 'English' ? 'Country is required' : 'Negara is required'
                            }}
                        />
                        {errors?.country?.message && <span className='text-danger'>{errors?.country?.message}</span>}
                    </div>

                    <div className='d-flex flex-row'>
                        <div className='mb-4 col-6 pe-2'>
                            <Label className='custom-label form-label' for='company'>
                                {languageUse === 'English' ? 'State' : 'Negeri'}
                            </Label>
                            <Controller
                                name="state"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        className='text-secondary'
                                        placeholder={languageUse === 'English' ? "Select State" : "Pilih Negeri"}
                                        isClearable
                                        options={stateList}
                                        isSearchable={true}
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: languageUse === 'English' ? 'State is required' : 'Negeri is required'
                                }}
                            />
                            {errors?.state?.message && <span className='text-danger'>{errors?.state?.message}</span>}
                        </div>

                        <div className='mb-4 col-6 ps-2'>
                            <Label className='custom-label form-label' for='company'>
                                {languageUse === 'English' ? 'City' : 'Bandar'}
                            </Label>
                            <Controller
                                name="city"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        className='text-secondary'
                                        placeholder={languageUse === 'English' ? "Select City" : "Pilih Bandar"}
                                        isClearable
                                        options={cityList}
                                        isSearchable={true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className='mb-4'>
                        <Label className='custom-label form-label' for='company'>
                            {languageUse === 'English' ? 'Company Size' : 'Saiz Syarikat'}
                        </Label>
                        <Controller
                            name="companySize"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    className='text-secondary'
                                    placeholder={languageUse === 'English' ? "Select Company Size" : "Pilih Saiz Syarikat"}
                                    isClearable
                                    options={companySizeList}
                                    isSearchable={true}
                                    {...field}
                                />
                            )}
                            rules={{
                                required: languageUse === 'English' ? 'Company Size is required' : 'Saiz Syarikat is required'
                            }}
                        />
                        {errors?.companySize?.message && <span className='text-danger'>{errors?.companySize?.message}</span>}
                    </div>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between'>
                    <Button className=" authButton login-btn-min-width mb-4" variant='outline-primary' onClick={props.toggle}>
                        {languageUse === 'English' ? 'Cancel' : 'Batal'}
                    </Button>
                    <Button type='submit' className=" authButton login-btn-min-width mb-4" variant='outline-primary'>
                        {isLoading ? <Spinner variant='dark' size='sm' /> : languageUse === 'English' ? 'Save' : 'Simpan'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    </>
};

export default EditProfileModal;