
import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function sectionQuestionService
    * @param (authToken, indicatorId, submissionId)
    * @description call API to get the section Question list
*/
export const sectionQuestionService = async ({ authToken, indicatorId, submissionId }) => {
    const result = await axios.get(`${baseUrl}users/sectionQuestions/list?indicator=${indicatorId}&submissionId=${submissionId}&filter=Active`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}

/**
    * @function progressSectionQuestionService
    * @param (authToken, proGress_Dimension, submissionId)
    * @description call API to get the progress section Question list
*/
export const progressSectionQuestionService = async ({ authToken, proGress_Dimension, submissionId }) => {
    const result = await axios.get(`${baseUrl}ProGress/questions/list?proGress_Dimension=${proGress_Dimension}&submissionId=${submissionId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}
