
import axios from "axios"
import Cookies from 'js-cookie';

// Auth-Services
import { removeUserSession } from "../utility/AuthService"

const baseUrl = process.env.REACT_APP_API_BASEURL

// const getToken = localStorage.getItem('auth')
// const myToken = getToken?.token

export const registerService = (input) => axios.post(`${baseUrl}auth/signup`, input)


export const loginService = ({ email, password, repatcheResponse, userType }) => axios.post(`${baseUrl}auth/signin`,
    { email, password, repatcheResponse, userType })

export const forgetPasswordService = ({ email }) => axios.post(`${baseUrl}auth/forgotpassword`,
    { email })

export const otpVerifyService = ({ otp, Token }) => axios.post(`${baseUrl}auth/verifyOtp`,
    { otp }
    , {
        headers: {
            Authorization: `Bearer ${Token}`
        }
    })


export const resetPasswordService = ({ password, resetToken }) => axios.post(`${baseUrl}auth/resetPassword`,
    { password }
    , {
        headers: {
            Authorization: `Bearer ${resetToken}`
        }
    })

export const logoutService = () => {
    // removeUserSession()
    localStorage.clear();
    Cookies.remove('token');
    Cookies.remove('reFreshToken');
    Cookies.remove('user');
}

export const logoutServiceAdmin = ({ token }) => axios.get(`${baseUrl}/admin/auth/logout`,
    {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        }
    })

export const userGetProfile = ({ token }) => axios.get(`${baseUrl}/admin/auth/profile`,
    {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        }
    })

export const userUpdateProfile = ({ token }, formData) => axios.post(`${baseUrl}/admin/auth/update-profile`, formData,

    {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        }
    })

export const userGetCurrentPassword = ({ token }) => axios.get(`${baseUrl}/admin/auth/profile`, {},
    {

        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        }
    }

)

export const userUpdatePassword = ({ old_password, password, password_confirmation, token }) => axios.post(`${baseUrl}/admin/auth/change-password`, {
    old_password,
    password,
    password_confirmation
}, {
    headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
})

export const userListService = ({ countPerPage, page, token }) => axios.get(`${baseUrl}/admin/customer/list?paginate=1&page=${page}&perPage=${countPerPage}`, {
    headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
})

export const userSearchService = ({ search, countPerPage, token }) => axios.get(`${baseUrl}/admin/customer/list?keyword=${search}&paginate=1&&perPage=${countPerPage}`, {
    headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
})

export const userStatusUpdateService = ({ id, token }) => axios.post(
    `${baseUrl}/admin/customer/status/${id}`,
    {},
    {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        }
    }
)

export default { loginService, userListService, userUpdateProfile, userUpdatePassword, userGetProfile, userGetCurrentPassword }