import React from 'react'

// Custom Component
import NavbarComponent from "../../components/layouts/Navbar";
import UploadDocuments from '../../components/UploadProgressDocuments/UploadDocuments';
import Footer from '../../components/layouts/Footer'

const UploadProgressDocumentsPage = () => {
    return (
        <>
            <NavbarComponent />
            <UploadDocuments />
            <Footer />
        </>
    )
};

export default UploadProgressDocumentsPage