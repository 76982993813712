import React from 'react'

const HorizontalStackedBarChartLegends = () => {
    return (
        <>
            <div className='d-flex flex-sm-row flex-column align-items-center mb-2'>
                <div className='pieLegends rounded-circle ms-2 me-1'
                    style={{
                        backgroundColor: '#38761D'
                        // backgroundColor: '#93C47D'
                    }}>
                </div>
                <span>Leader</span>

                <div className='pieLegends rounded-circle ms-2 me-1'
                    style={{
                        backgroundColor: '#93C47D'
                        // backgroundColor: '#B6D7A8'
                    }}>
                </div>
                <span>Advanced</span>

                <div className='pieLegends rounded-circle ms-2 me-1'
                    style={{
                        backgroundColor: '#B6D7A8'
                        // backgroundColor: '#D9EAD3'
                    }}>
                </div>
                <span>Intermediate</span>

                <div className='pieLegends rounded-circle ms-2 me-1'
                    style={{
                        backgroundColor: '#FF9900'
                        // backgroundColor: '#FCE5CD'
                    }}>
                </div>
                <span>Beginner</span>

                <div className='pieLegends rounded-circle ms-2 me-1'
                    style={{
                        backgroundColor: '#FF0000'
                        // backgroundColor: '#FF0000'
                    }}>
                </div>
                <span>Laggard</span>
            </div>
          
        </>
    )
};

export default HorizontalStackedBarChartLegends