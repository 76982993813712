import React, { useEffect, useState } from 'react'

//third party
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend, ChartDataLabels);

// ** Reactstrap Imports
import {
    Row,
    Col,
    CardTitle,
    CardText,
    Form,
    Label,
    Input,
    Button
} from "reactstrap"
import toast, { Toaster } from 'react-hot-toast'
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
//custom
import Selection from '../Selection/Selection';
import PieChart from '../CustomChart/PieChart'
import PieChartLegends from '../CustomChart/PieChartLegends'
//custom
import states from '../../../utility/State'
// Utility-Service
import { getToken, getUser } from '../../../utility/AuthService'
//API
import { getOverallDimensionStateData } from '../../../services/analyticsServices/overAllDimensionStateData';

const StateComparisionAnalytics = (props) => {
    const [isLoading, setLoading] = useState(false)
    const token = getToken()
    const user = getUser()
    const [parcentOrDigit, setParcentOrDigit] = useState('digit')
    const [countryValue, setCountryValue] = useState(null);
    const [watchEnvironmentValue, setEnvironmentWatchValue] = useState('');
    const [state1Data, setState1Data] = useState([]);
    const [state1ParcentData, setState1ParcentData] = useState();
    const [state2Data, setState2Data] = useState([]);
    const [state2ParcentData, setState2ParcentData] = useState();
    const [state3Data, setState3Data] = useState([]);
    const [state3ParcentData, setState3ParcentData] = useState();
    const [state1Select, setState1Select] = useState();
    const [state2Select, setState2Select] = useState();
    const [state3Select, setState3Select] = useState();

    const stateBasedLabelList = [
        {
            value: 'Leader',
            color: '#EC6258'
        },
        {
            value: 'Advanced',
            color: '#69BA6C'
        },
        {
            value: 'Beginner',
            color: '#FBC04F'
        },
        {
            value: 'Laggard',
            color: '#62AADF'
        },
        {
            value: 'Intermediate',
            color: '#ED61AE'
        },
    ];

    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();

    const watchSelect = watch('country')

    /**
     * @function handleParcent
     * @param ()
     * @description to show data in parcent
     */
    const handleParcent = async () => {
        if (state1Data) {
            setParcentOrDigit('parcent')
            const sumEnv = state1Data.reduce(function (a, b) {
                return a + b;
            });
            const parcentValEnv = state1Data.map(function (a) {
                return Math.floor((a / sumEnv) * 100);
            });
            setState1ParcentData(parcentValEnv)

        }
        if (state2Data) {
            setParcentOrDigit('parcent')
            const sumEnv = state2Data.reduce(function (a, b) {
                return a + b;
            });
            const parcentValEnv = state2Data.map(function (a) {
                return Math.floor((a / sumEnv) * 100);
            });
            setState2ParcentData(parcentValEnv)

        }
        if (state3Data) {
            setParcentOrDigit('parcent')
            const sumEnv = state3Data.reduce(function (a, b) {
                return a + b;
            });
            const parcentValEnv = state3Data.map(function (a) {
                return Math.floor((a / sumEnv) * 100);
            });
            setState3ParcentData(parcentValEnv)

        }

    };

    /**
     * @function handleDigit
     * @param ()
     * @description to show data in digit
     */
    const handleDigit = async () => {
        setParcentOrDigit('digit')
    }

    /**
     * @function state1DataList
     * @param ()
     * @description to handle get state data List
     */
    const state1DataList = async () => {
        let result = []
        try {
            result = await getOverallDimensionStateData({
                authToken: token,
                id: props?.dimensionList?.find(item => item?.name === props.watchDimensionValue?.label)?._id,
                state: state1Select.label,
                userId: user?._id
            });

            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setState1Data(stateBasedLabelList.map(item => result?.data?.data[item.value]))
            } else {
                setLoading(false);
                setState1Data([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false);
            setState1Data([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    useEffect(() => {
        if (state1Select && props.watchDimensionValue) {
            state1DataList()
        } else if (state1Select && !props.watchDimensionValue) {
            toast.error('First select Dimension')
        }
    }, [state1Select, props.watchDimensionValue])

    /**
 * @function state2DataList
 * @param ()
 * @description to handle get state data List
 */
    const state2DataList = async () => {
        let result = []
        try {
            result = await getOverallDimensionStateData({
                authToken: token,
                id: props?.dimensionList?.find(item => item?.name === props.watchDimensionValue?.label)?._id,
                state: state2Select.label,
                userId: user?._id
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setState2Data(stateBasedLabelList.map(item => result?.data?.data[item.value]))
            } else {
                setLoading(false);
                setState2Data([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false);
            setState2Data([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    useEffect(() => {
        if (state2Select && props.watchDimensionValue) {
            state2DataList()
        } else if (state2Select && !props.watchDimensionValue) {
            toast.error('First select Dimension')
        }
    }, [state2Select, props.watchDimensionValue])

    /**
 * @function state3DataList
 * @param ()
 * @description to handle get state data List
 */
    const state3DataList = async () => {
        let result = []
        try {
            result = await getOverallDimensionStateData({
                authToken: token,
                id: props?.dimensionList?.find(item => item?.name === props.watchDimensionValue?.label)?._id,
                state: state3Select.label,
                userId: user?._id
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setState3Data(stateBasedLabelList.map(item => result?.data?.data[item.value]))
            } else {
                setLoading(false);
                setState3Data([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false);
            setState3Data([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    useEffect(() => {
        if (state3Select && props.watchDimensionValue) {
            state3DataList()
        } else if (state3Select && !props.watchDimensionValue) {
            toast.error('First select Dimension')
        }
    }, [state3Select, props.watchDimensionValue])

    const state1BasedChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let percentage = 0
                    percentage = parcentOrDigit === 'digit' ? `${value}` : `${value}%`;
                    return percentage;
                },
                color: 'white',
                font: {
                    weight: 'bold',
                    size: 12
                }
            }
        }
    };

    const state2BasedChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let percentage = 0
                    percentage = parcentOrDigit === 'digit' ? `${value}` : `${value}%`;
                    return percentage;
                },
                color: 'white',
                font: {
                    weight: 'bold',
                    size: 12
                }
            }
        }
    };

    const state3BasedChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let percentage = 0
                    percentage = parcentOrDigit === 'digit' ? `${value}` : `${value}%`;
                    return percentage;
                },
                color: 'white',
                font: {
                    weight: 'bold',
                    size: 12
                }
            }
        }
    };

    const state1BasedData = {
        labels: stateBasedLabelList.map(item => item.value),
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? state1Data : state1ParcentData,
                backgroundColor: stateBasedLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    const state2BasedData = {
        labels: stateBasedLabelList.map(item => item.value),
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? state2Data : state2ParcentData,
                backgroundColor: stateBasedLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    const state3BasedData = {
        labels: stateBasedLabelList.map(item => item.value),
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? state3Data : state3ParcentData,
                backgroundColor: stateBasedLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    const stateBasedData = {
        labels: stateBasedLabelList.map(item => item.value),
        datasets: [
            {
                data: [45, 40, 15, 20, 10],
                backgroundColor: stateBasedLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    return (

        <>
            {/* <div>
                <Toaster />
            </div> */}
            <div className='d-flex justify-content-between align-items-center py-2 flex-column flex-md-row'>
                <h4 className=''>State</h4>
                <div className='d-flex align-items-center rounded-pill bg-btn-analytics px-2 py-2'>
                    <Button color='' className={parcentOrDigit === 'parcent' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleParcent}>
                        Percentage
                    </Button>
                    <Button color='' className={parcentOrDigit === 'digit' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleDigit}>
                        Digit number
                    </Button>
                </div>

            </div>
            <Row className='border mb-5'>

                <Row className='d-flex flex-row justify-content-between px-5 py-2'>
                    <Col md={3} lg={3} sm={12}>
                        <div className=''>
                            <Selection className={'w-100'} options={states} title={'State'} name={'Environment'} setWatchValue={setState1Select} />
                        </div>

                        {state1Data?.every(item => item === 0) ? <>
                            <p className='my-5 text-danger'>No Data available</p>
                        </> : <>
                            <div className='d-flex flex-column  align-items-center'>
                                <PieChart options={state1BasedChartOptions} data={state1BasedData} width={220} height={220} />
                            </div>
                        </>}
                    </Col>
                    <Col md={3} lg={3} sm={12}>
                        <div className=''>
                            <Selection className={'w-100'} options={states} title={'State'} name={'Social'} setWatchValue={setState2Select} />
                        </div>
                        {state2Data?.every(item => item === 0) ? <>
                            <p className='my-5 text-danger'>No Data available</p>
                        </> : <>
                            <div className='d-flex flex-column  align-items-center'>
                                <PieChart options={state2BasedChartOptions} data={state2BasedData} width={220} height={220} />
                            </div>
                        </>}

                    </Col>
                    <Col md={3} lg={3} sm={12}>
                        <div className=''>
                            <Selection className={'w-100'} options={states} title={'State'} name={'Governance'} setWatchValue={setState3Select} />
                        </div>

                        {state3Data?.every(item => item === 0) ? <>
                            <p className='my-5 text-danger'>No Data available</p>
                        </> : <>
                            <div className='d-flex flex-column  align-items-center'>
                                <PieChart options={state3BasedChartOptions} data={state3BasedData} width={220} height={220} />
                            </div>
                        </>}


                    </Col>
                </Row>
                <div>
                    <PieChartLegends />
                </div>
            </Row>
        </>

    )
};

export default StateComparisionAnalytics