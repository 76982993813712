import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Spinner, Form, InputGroup } from 'react-bootstrap'

import toast, { Toaster } from 'react-hot-toast'
import ReactPaginate from 'react-paginate'
import { AiOutlineEye } from "react-icons/ai";
import { RxDashboard } from "react-icons/rx";
import { BsSkipEndCircle } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { ChevronDown } from "react-feather";
import { CSVLink } from "react-csv";
import moment from 'moment'
import CryptoJS from 'crypto-js';
import Select from 'react-select'
// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";
import { useForm, Controller } from 'react-hook-form'
//API
import { associatedIndustryFilterListService, associatedSearchListService, getAssociatedCompanies } from "../../../services/associatedCompaniesService";
//Custom
import UploadedDocumentsModal from "../../components/modal/UploadedDocumentsModal";
import ShowPdfModal from "../../components/modal/ShowPdfModal";
import PdfReportPage2 from '../../pages/pdfReport/PdfReportPage2'
import { getAllIndustryListService } from "../../../services/industryService";
import { getAssesmentSummaryService } from '../../../services/submissionService';
import { getImageIndicatorService } from '../../../services/indicatorService';
import { selectLanguageUse } from "../../../redux/slices/language.Slice";
import DataTable from "react-data-table-component";
import { getAssesmentSummaryQuestionService } from "../../../services/associatedCompamiesSubmissionDetalisService";

const AssociatedCompanies = () => {
    const componentRef = useRef();

    const [isLoading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')
    const [assesmentLoading, setAssesmentLoading] = useState(false);
    const [assesmentList, setAssesmentList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalEntry, setTotalEntry] = useState()
    const authToken = getToken()
    const user = getUser()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [submissionId, setSubmissionId] = useState()
    const [uploadedDocumentsModal, setUploadedDocumentsModal] = useState(false);
    const [pdfItem, setPdfItem] = useState();
    const [showPdfModalShow, setShowPdfModalShow] = useState(false);
    const [allIndustry, setallIndustry] = useState([])
    const [selectedIndustry, setIndustry] = useState()
    const [csvData, setCSVData] = useState([]);
    const [multipleCSVData, setMultipleCSVData] = useState([]);
    const [multipleCSVLoading, setMultipleCSVLoading] = useState(false)
    const [csvDownloadIndex, setCSVDownloadIndex] = useState();
    const [pdfContent, setPdfContent] = useState(true);
    const [overallVal, setOverallVal] = useState();
    const [overallValLebel, setOverallValLebel] = useState();
    const [overallColor, setOverallColor] = useState();
    const [dimensionData, setDimensionData] = useState([]);
    const [indicatorData, setIndicatorData] = useState([]);
    const [selectedIndicatorData, setSelectedIndicatorData] = useState([]);
    const [profileData, setProfileData] = useState([]);
    const [ESGperformanceData, setESGperformanceData] = useState([]);
    const [optionalQuesData, setOptionalQuesData] = useState([]);
    const [requiredQuestions, setrequiredQuestions] = useState([]);
    const [uploadedDocInfo, setUploadedDocInfo] = useState([])
    const [lastSubmission, setLastSubmission] = useState();
    const [imageList, setImageList] = useState([])

    const languageUse = useSelector(selectLanguageUse || user?.language)

    const colorLeval = [
        {
            leval: "Laggard",
            color_code: "#FF0000"
        },
        {
            leval: "Beginner",
            color_code: "#FF9900"
        },
        {
            leval: "Intermediate",
            color_code: "#B6D7A8"
        },
        {
            leval: "Advanced",
            color_code: "#93C47D"
        },
        {
            leval: "Leader",
            color_code: "#38761D"
        },
    ]

    const selectESGcolor = (value) => {
        if (value > 0 && value <= 20) {
            return '#FF0000'
        } else if (value > 20 && value <= 40) {
            return '#FF9900'
        } else if (value > 40 && value <= 60) {
            return '#B6D7A8'
        } else if (value > 60 && value <= 80) {
            return '#93C47D'
        } else if (value > 80 && value <= 100) {
            return '#38761D'
        }
    }

    useEffect(() => {
        if (dimensionData?.length > 0) {
            const labels = dimensionData?.length > 0 ? dimensionData?.map(item => languageUse === 'English' ? item.dimension : item.dimension_Malay) : [];
            labels.push('ESG');
            // labels.push('Overall');
            setOverallValLebel(labels)

            const totalValues = dimensionData?.length > 0 ? dimensionData?.map(item => (item.total)) : []
            let totalValuesSum = 0;
            totalValues.map(x => (totalValuesSum += x));

            const correctValues = dimensionData?.length > 0 ? dimensionData?.map(item => (item.correct)) : []
            let correctValuesSum = 0;
            correctValues.map(x => (correctValuesSum += x));

            const overallValue = ((correctValuesSum / totalValuesSum) * 100).toFixed(2)
            const ESGcolor = selectESGcolor(overallValue)

            const allColor = dimensionData?.length > 0 && dimensionData?.map(item => colorLeval.find(element => element.leval === item.leval).color_code)
            allColor.push(ESGcolor)
            setOverallColor(allColor)

            const chartData = dimensionData?.length > 0 && dimensionData?.map(item => ((item.correct / item.total) * 100).toFixed(2));
            chartData.push(overallValue);
            setOverallVal(chartData)
        }
    }, [dimensionData, languageUse]);

    const data = {
        labels: overallValLebel,
        datasets: [
            {
                label: "Score of Category",
                data: overallVal,
                backgroundColor: overallColor,
                borderColor: overallColor,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        // maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Score of Category",
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                color: "black",
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: "bold",
                },
                formatter: (value) => {
                    return `${value}%`
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: true,
                },
                position: 'bottom',
            },
            y: {
                max: 150,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: true,
                },
            },
        },
        maintainAspectRatio: true,

    };

    const horizontalData = {
        labels: indicatorData?.length > 0 && indicatorData?.map((item) => languageUse === 'English' ? item.name : item.name_Malay),
        datasets: [
            {
                label: languageUse === 'English' ? "Your Score" : "Markah anda",
                data: indicatorData?.length > 0 && indicatorData?.map((item) => {
                    if (item.correct) {
                        return ((item?.correct / item?.total) * 100).toFixed(2)
                    } else {
                        return 0
                    }
                }),
                backgroundColor: indicatorData?.map((item) => item?.dimension?.color),

                borderColor: indicatorData?.map((item) => item?.dimension?.color),
                borderWidth: 1,
            },
            {
                label: languageUse === 'English' ? "Peer Score" : "Skor Rakan Sebaya",
                data: indicatorData?.length > 0 && indicatorData?.map((item) => {
                    if (item?.industryScore) {
                        return ((item?.industryScore / item?.total) * 100).toFixed(2)
                    } else {
                        return 0
                    }
                }),
                backgroundColor: indicatorData?.map((item) => item?.dimension?.peerColor),

                borderColor: indicatorData?.map((item) => item?.dimension?.peerColor),
                borderWidth: 1,
            },
        ],
    };

    const horoizontalOptions = {
        responsive: true,
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                labels: {
                    usePointStyle: true,
                    boxWidth: 20,
                    padding: 50,

                },

                position: 'bottom',
                align: 'center'
            },

            title: {
                display: true,
                text: "Score of Category",
            },
            datalabels: {

                display: false,

            },
        },
        scales: {
            x: {
                // max: 10,
                ticks: {
                    callback: (value) => {
                        return `${value}%`
                    }
                },
                grid: {
                    display: false,

                },
                border: {
                    display: false,
                },
                position: 'bottom',
            },
            y: {
                // max: 150,
                ticks: {
                    // display: false,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: true,

    };

    /**
    * @function getImage
    * @param (rowRecord, index)
    * @description update indicator image list
    */
    const getImage = async (rowRecord, index) => {
        if (rowRecord) {
            try {
                const result = await getImageIndicatorService({
                    token: authToken,
                    file: rowRecord
                })
                const temp = imageList;
                temp[index] = `data:image/${rowRecord?.filename?.split(".")[1]};base64, ${Buffer.from(result?.data, "base64").toString("base64")}`
                setImageList(temp)
            } catch (error) {
                toast.error(error?.response?.data?.message || 'Something went wrong')
            }
        } else {
            const temp = imageList;
            temp[index] = ''
            setImageList(temp)
        }
    }

    /**
     * @function getAssesmentSummary
     * @param ()
     * @description get Assesment Summary List
     */
    const getAssesmentSummary = async () => {
        const submissionID = localStorage.getItem('submissionId');
        try {
            setLoading(true);
            const result = await getAssesmentSummaryService({
                authToken,
                SubmissionId: "65dc2d810eb6aa2b8f586e55"
            });
            setDimensionData(result?.data?.data?.dimensions)
            let select = []
            result?.data?.data?.dimensions.map((item) => {
                let temp = []
                result?.data?.data?.indicaters.filter(indicatorItem => indicatorItem.dimension.name === item.dimension).map((filteredItem) => {
                    temp = [...temp, colorLeval.findIndex(element => element.leval === filteredItem.leval)]
                })
                if (temp.every(element => element >= 2)) {
                    select = [...select, result?.data?.data?.indicaters.filter(indicatorItem => indicatorItem.dimension.name === item.dimension).reduce((prev, current) => (((prev?.correct / prev?.total) * 100).toFixed(2) > ((current?.correct / current?.total) * 100).toFixed(2)) ? prev : current)]
                } else {
                    select = [...select, result?.data?.data?.indicaters.filter(indicatorItem => indicatorItem.dimension.name === item.dimension).reduce((prev, current) => (((prev?.correct / prev?.total) * 100).toFixed(2) < ((current?.correct / current?.total) * 100).toFixed(2)) ? prev : current)]
                }
            })
            setSelectedIndicatorData(select)
            setIndicatorData(result?.data?.data?.indicaters)
            setProfileData(result?.data?.data?.profile)
            setESGperformanceData(result?.data?.data?.esgPerformance)
            setOptionalQuesData(result?.data?.data?.optionalQuestions)
            setrequiredQuestions(result?.data?.data?.requiredQuestions)
            setLastSubmission(result?.data?.data?.lastSubmissionDate)
            await Promise.all(result?.data?.data?.indicaters.map(async (item, index) => {
                if (item?.file) {
                    await getImage(item?.file, index)
                } else {
                    await getImage(null, index)
                }
            }))

            setLoading(false);

        } catch (error) {
            setLoading(true);
            toast.error(error?.response?.data?.message || "Something went wrong")
            setLoading(false);
        }
    }

    useEffect(() => {
        // getAssesmentSummary()
    }, [])

    const handleShowPdfModalShow = () => setShowPdfModalShow(!showPdfModalShow);

    const UploadedDocumentsModaltoggle = (id) => {
        setSubmissionId(id)
        setUploadedDocumentsModal(!uploadedDocumentsModal)
    };

    const [currentPage, setCurrentPage] = useState(0)
    const [offsetCal, setoffsetCal] = useState(0)

    const pageLimit = 10

    // ** Hooks
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        resetField,
        formState: { errors },
    } = useForm({
        defaultValues: {
            paginationVal: 10
        }
    });

    const watchPaginationVal = watch('paginationVal')

    const csvHeaders = [
        { label: 'No.', key: 'no' },
        { label: 'Company Name', key: 'companyName' },
        { label: 'Submission By', key: 'submissionBy' },
        { label: 'Date', key: 'date' },
        { label: 'ESG Maturity Level', key: 'level' },

    ];

    const [mutipleCSVHeaders, setMultipleCSVHeaders] = useState([
        { label: 'Submission Time', key: 'submission_time' },
        { label: 'Started On', key: 'started_on' },
        { label: 'Username', key: 'username' },
        { label: 'Company Name', key: 'company_name' },
        { label: 'Industry', key: 'industry' },
        { label: 'Parent access code', key: 'parent_access_code' },
        { label: 'User Email', key: 'user_email' },
        { label: 'Phone number', key: 'phone_number' },
        { label: 'Location', key: 'location' },
        { label: 'Company export', key: 'company_export' },
        { label: 'ESG Maturity Level', key: 'esg_maturity_level' },
        { label: 'Environment Maturity Level', key: 'environment_maturity_level' },
        { label: 'Social Maturity Level', key: 'social_maturity_level' },
        { label: 'Governance Maturity Level', key: 'governance_maturity_level' }
    ]);

    /**
    * @function getLevel
    * @param scoreleval
    * @description used to get leval
*/
    const getLevel = (scoreleval) => {
        const allLeavls = ["Laggard", "Beginner", "Intermediate", "Advanced", "Leader"];
        let leval = allLeavls[0];
        if (scoreleval > 0 && scoreleval <= 20) {
            leval = allLeavls[0];
        } else if (scoreleval > 20 && scoreleval <= 40) {
            leval = allLeavls[1];
        } else if (scoreleval > 40 && scoreleval <= 60) {
            leval = allLeavls[2];
        } else if (scoreleval > 60 && scoreleval <= 80) {
            leval = allLeavls[3];
        } else if (scoreleval > 80 && scoreleval <= 100) {
            leval = allLeavls[4];
        } else {
            leval = allLeavls[0];
        }
        return leval;
    }

    const ExportButton = () => {
        return (
            <CSVLink data={csvData} headers={csvHeaders} filename="Associated Companies.csv">
                <button className="btn btn-primary">{languageUse === 'English' ? 'Export CSV' : 'eksport CSV'}</button>
            </CSVLink>
        );
    };

    /**
   * @function goToAnalytics
   * @param (data)
   * @description to go to analytics page
   */
    const goToAnalytics = async (data) => {
        navigate("/analytics");
    };

    /**
* @function goToAnalytics
* @param (data)
* @description to go to analytics page
*/
    const goToAdvanceAnalytics = async (data) => {
        navigate("/advanceAnalytics");
    };

    /**
    * @function goToDashboard
    * @param ()
    * @description navigate to Dashboard page
    */
    const goToDashboard = (S_id) => {
        const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
        navigate(`/user/dashboard/${encodeURIComponent(encryptedSubId)}`)
    };

    /**
   * @function goToDirectDownload
   * @param ()
   * @description navigate to Dashboard page
   */
    const goToDirectDownload = (S_id) => {
        const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
        navigate(`/user/dashboard/${encodeURIComponent(encryptedSubId)}?download=true`)
    };

    /**
        * @function handleEdit
        * @param (S_id)
        * @description use encrypted submission id to go to the question page.
    */
    const handleEdit = (status, S_id, userId) => {
        const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
        if (status) {
            navigate(`/${encodeURIComponent(encryptedSubId)}?userId=${userId}`)
        } else {
            navigate(`/view/${encodeURIComponent(encryptedSubId)}?userId=${userId}`)
        }
    };

    /**
     * @function getDocumentsView
     * @param (status)
     * @description use condition to return desired button.
     */
    const getDocumentsView = (status, id) => {
        if (!status) {
            return (<Button role="button" className="login-btn-min-width border rounded p-2" onClick={() => UploadedDocumentsModaltoggle(id)} variant='primary'>
                <span className="">View</span>
            </Button>)
        } else {
            return (<>-</>)
        };
    };

    /**
     * @function getStatus
     * @param (status)
     * @description use condition to return desired button.
    */
    const getStatus = (status, id, userId) => {
        if (!status) {
            return (<Button className="statusComplete login-btn-min-width border rounded-pill p-2" onClick={() => handleEdit(status, id, userId)} variant='outline-primary'>
                <span className="text-uppercase">complete</span>
            </Button>)
        } else {
            return (<Button className="statusPending login-btn-min-width border rounded-pill" variant='outline-primary' onClick={() => handleEdit(status, id, userId)}>
                <span className="text-uppercase">pending</span>
            </Button>)
        };
    }

    /**
    * @function getStatusIcon
    * @param (status)
    * @description use condition to return desired icon.
    */
    const getStatusIcon = (status, id, userId) => {
        if (!status) {
            return (
                <div className="d-flex flex-row align-items-center">
                    <AiOutlineEye style={{ fontSize: "20px" }} cursor={'pointer'} onClick={() => handleEdit(status, id, userId)}></AiOutlineEye>
                    <RxDashboard style={{ fontSize: "16px", marginLeft: '2rem' }} cursor={'pointer'} onClick={() => goToDashboard(id)}></RxDashboard>
                </div>
            )
        } else {
            return <BsSkipEndCircle style={{ fontSize: "20px" }} cursor={'pointer'} onClick={() => handleEdit(status, id, userId)} />
        };
    }

    /**
    * @function getIndustryID
    * @param ()
    * @description get industry list using api
    */
    const getIndustryID = async () => {
        try {
            const result = await getAllIndustryListService({
                authToken,
            });
            if (result?.data?.statusCode === 200) {
                const industries = []
                result?.data?.data?.list.map(number => {
                    industries.push({ value: number._id, label: number.name })
                });
                setallIndustry(industries)
            } else {
                setallIndustry([])
                toast.error(result?.response?.data?.message || 'Something went wrong!')
            }
        } catch (error) {
            setallIndustry([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getIndustryID()

    }, []);

    const searchData = async (searchKey) => {
        const result = await associatedSearchListService({
            authToken,
            userId: user?._id,
            offset: offsetCal,
            limit: watchPaginationVal || pageLimit,
            search: searchKey
        });
        if (result?.data?.statusCode) {
            setAssesmentLoading(false)
            setCSVData(result?.data?.data?.list?.map((item, index) => {
                return {
                    no: index + 1,
                    companyName: item?.user?.company,
                    submissionBy: item?.user?.username,
                    date: moment(item?.submission?.updatedAt).format("DD/MM/YYYY"),
                    level: item?.submission?.leval
                }
            }))
            setTotalEntry(result?.data?.data?.count)
            setAssesmentList(result?.data?.data?.list)
        } else {
            setAssesmentLoading(false)
            setCSVData([])
            setAssesmentList([])
            toast.success('Something went wrong!')
        }
    }

    const industryFilter = async () => {
        const result = await associatedIndustryFilterListService({
            authToken,
            userId: user?._id,
            offset: offsetCal,
            limit: watchPaginationVal || pageLimit,
            q: selectedIndustry?.value
        });
        if (result?.data?.statusCode) {
            setAssesmentLoading(false)
            setCSVData(result?.data?.data?.list?.map((item, index) => {
                return {
                    no: index + 1,
                    companyName: item?.user?.company,
                    submissionBy: item?.user?.username,
                    date: moment(item?.submission?.updatedAt).format("DD/MM/YYYY"),
                    level: item?.submission?.leval
                }
            }))
            setTotalEntry(result?.data?.data?.count)
            setAssesmentList(result?.data?.data?.list)
        } else {
            setAssesmentLoading(false)
            setCSVData([])
            setAssesmentList([])
            toast.success('Something went wrong!')
        }
    }

    /**
      * @function handlePagination
      * @param (page)
      * @description Function to handle Pagination
      */
    const handlePagination = page => {
        setCurrentPage(page.selected)
        setoffsetCal(page.selected * watchPaginationVal)
    }

    /**
* @function getAssesmentList
* @param ()
* @description get Assesment List
*/
    const getAssociatedCompaniesList = async () => {
        try {
            setAssesmentLoading(true)
            const result = await getAssociatedCompanies({
                authToken,
                userId: user?._id,
                offset: offsetCal,
                limit: watchPaginationVal || pageLimit,
            });

            setCSVData(result?.data?.data?.list?.map((item, index) => {
                return {
                    no: index + 1,
                    companyName: item?.user?.company,
                    submissionBy: item?.user?.username,
                    date: moment(item?.submission?.updatedAt).format("DD/MM/YYYY"),
                    level: item?.submission?.leval
                }
            }))

            setTotalEntry(result?.data?.data?.count)
            setAssesmentList(result?.data?.data?.list)
            setAssesmentLoading(false)
        } catch (error) {
            setAssesmentLoading(false)
            setCSVData([])
            setAssesmentList([])
            toast.error(error?.response?.data?.message || "Something went wrong")
        }
    };

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows?.map(item => item?.submission?._id))
    });

    const handleCSVDownloadSelected = async () => {
        if (selectedRows?.length > 0) {
            setCSVDownloadIndex(0)
        }
    };

    // useEffect(() => {
    //     getAssociatedCompaniesList()
    // }, [offsetCal]);

    /**
        * @function handleIndividualDownload
        * @param id
        * @description to handle the download
    */
    const handleIndividualDownload = async (id) => {
        if (id) {
            setMultipleCSVLoading(true)
            const result = await getAssesmentSummaryQuestionService({
                token: authToken,
                SubmissionId: id
            });
            if (csvDownloadIndex === 0) {
                const tempHeader = mutipleCSVHeaders
                result?.data?.data?.questions?.forEach((item, index) => {
                    item?.allQuestions?.forEach((dimensionItem, dimensionIndex) => {
                        dimensionItem?.questions?.forEach((indicatorItem, indicatorIndex) => {
                            indicatorItem?.sectionQuestions?.forEach((sectionItem, sectionIndex) => {
                                tempHeader?.push({
                                    label: `Dimension: ${item?.dimension} Indicator: ${dimensionItem?.indicater} Question: ${sectionItem?.questionId?.question}`?.replace(/"/g, '""'),
                                    key: `question_${index}_${dimensionIndex}_${indicatorIndex}_${sectionIndex}`
                                })
                            })
                        })
                    })
                })
                setMultipleCSVHeaders(tempHeader)
            }
            if (Number.isInteger(csvDownloadIndex)) {
                const temp = multipleCSVData;
                const tempObject = {
                    submission_time: moment(result?.data?.data?.submission?.updatedAt).format("DD-MM-YYYY, hh:mma"),
                    started_on: moment(result?.data?.data?.submission?.createdAt).format("DD-MM-YYYY, hh:mma"),
                    username: result?.data?.data?.profile?.username,
                    company_name: result?.data?.data?.profile?.company,
                    industry: result?.data?.data?.profile?.industry?.name,
                    parent_access_code: result?.data?.data?.profile?.joinedAccessCode?.length > 0 ? result?.data?.data?.profile?.joinedAccessCode[0] : '--',
                    user_email: result?.data?.data?.profile?.email,
                    phone_number: result?.data?.data?.profile?.mobileNo,
                    location: result?.data?.data?.profile?.companyLocation,
                    company_export: `${Array.isArray(result?.data?.data?.exportQuestion?.answer) ? result?.data?.data?.exportQuestion?.answer?.join(', ') : result?.data?.data?.exportQuestion?.answer || ""}`?.replace(/"/g, '""'),

                    esg_maturity_level: result?.data?.data?.submission?.dimensionsScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "esg") ? result?.data?.data?.submission?.dimensionsScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "esg")?.leval : "--",

                    environment_maturity_level: result?.data?.data?.submission?.dimensionsScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "environment") ? result?.data?.data?.submission?.dimensionsScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "environment")?.leval : "--",

                    social_maturity_level: result?.data?.data?.submission?.dimensionsScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "social") ? result?.data?.data?.submission?.dimensionsScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "social")?.leval : "--",

                    governance_maturity_level: result?.data?.data?.submission?.dimensionsScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "governance") ? result?.data?.data?.submission?.dimensionsScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "governance")?.leval : "--",
                }
                result?.data?.data?.questions?.forEach((item, index) => {
                    item?.allQuestions?.forEach((dimensionItem, dimensionIndex) => {
                        dimensionItem?.questions?.forEach((indicatorItem, indicatorIndex) => {
                            indicatorItem?.sectionQuestions?.forEach((sectionItem, sectionIndex) => {
                                tempObject[`question_${index}_${dimensionIndex}_${indicatorIndex}_${sectionIndex}`] = `${sectionItem?.answer.join(', ')}`?.replace(/"/g, '""')
                            })
                        })
                    })
                })
                temp.push(tempObject)
                setMultipleCSVData(temp)
            }
            setTimeout(() => {

                if (selectedRows?.length - 1 > csvDownloadIndex) {
                    setCSVDownloadIndex(csvDownloadIndex + 1)
                } else {
                    setCSVDownloadIndex()
                    setMultipleCSVLoading(false)
                    setTimeout(() => {
                        document.getElementById("multiple-export-csv").click();
                        setMultipleCSVData([])
                    }, 100)
                }
            }, 100)
        }
    };

    useEffect(() => {
        if (csvDownloadIndex) {
            handleIndividualDownload(selectedRows[csvDownloadIndex])
        } else if (csvDownloadIndex === 0) {
            handleIndividualDownload(selectedRows[csvDownloadIndex])
        }
    }, [csvDownloadIndex])

    const columns = [
        {
            name: languageUse === 'English' ? 'No.' : 'Tidak.',
            cell: (row, index) => (currentPage + 1) > 1 ? (((currentPage + 1) - 1) * watchPaginationVal) + index + 1 : index + 1,
            width: "80px",
        },

        {
            name: languageUse === 'English' ? 'Company Name' : 'Nama Syarikat',
            selector: (row) => row?.user?.company ? row?.user?.company : '--',
            sortable: true,
            minWidth: '190px',
        },
        {
            name: languageUse === 'English' ? 'Social Score' : 'Skor Sosial',
            selector: (row) => row?.submission?.dimensionsScoring?.find(item => item?.name.toLowerCase() === 'Social'.toLowerCase()).scorePercent ? parseFloat(row?.submission?.dimensionsScoring.find(item => item?.name.toLowerCase() === 'Social'.toLowerCase())?.scorePercent.toFixed(2)) : 0,
            // selector: (row) => row?.submission?.dimensionsScoring[0]?.scorePercent ? `${row?.submission?.dimensionsScoring[0]?.scorePercent?.toFixed(2)}` : 0,
            sortable: true,
            minWidth: '180px',
            center: true,
        },
        {
            name: languageUse === 'English' ? 'Environment Score' : 'Skor Persekitaran',
            selector: (row) => row?.submission?.dimensionsScoring?.find(item => item?.name.toLowerCase() === 'Environment'.toLowerCase()).scorePercent ? parseFloat(row?.submission?.dimensionsScoring.find(item => item?.name.toLowerCase() === 'Environment'.toLowerCase())?.scorePercent.toFixed(2)) : 0,
            // selector: (row) => row?.submission?.dimensionsScoring[2]?.scorePercent ? `${row?.submission?.dimensionsScoring[2]?.scorePercent?.toFixed(2)}` : 0,
            sortable: true,
            minWidth: '200px',
            center: true,
            // sortFunction: (rowA, rowB) => {
            //     if (isNaN(rowA?.submission?.dimensionsScoring[2]?.scorePercent) || (rowA?.submission?.dimensionsScoring[2]?.scorePercent > rowB?.submission?.dimensionsScoring[2]?.scorePercent)) {
            //         return 1
            //     }
            //     if ((isNaN(rowB?.submission?.dimensionsScoring[2]?.scorePercent)) || (rowA?.submission?.dimensionsScoring[2]?.scorePercent < rowB?.submission?.dimensionsScoring[2]?.scorePercent)) {
            //         return -1
            //     }
            //     return 0
            // }
        },

        {
            name: languageUse === 'English' ? 'Governance Score' : 'Skor Tadbir Urus',
            selector: (row) => row?.submission?.dimensionsScoring?.find(item => item?.name.toLowerCase() === 'Governance'.toLowerCase()).scorePercent ? parseFloat(row?.submission?.dimensionsScoring.find(item => item?.name.toLowerCase() === 'Governance'.toLowerCase())?.scorePercent.toFixed(2)) : 0,
            // selector: (row) => row?.submission?.dimensionsScoring[1]?.scorePercent ? `${row?.submission?.dimensionsScoring[1]?.scorePercent?.toFixed(2)}` : 0,
            sortable: true,
            minWidth: '200px',
            center: true,

        },

        {
            name: languageUse === 'English' ? 'Date' : 'Tarikh',
            selector: (row) => row?.submission?.updatedAt ? moment(row?.submission?.updatedAt).format("DD/MM/YYYY") : '--',
            minWidth: '150px',
            sortable: true,
            sortFunction: (rowA, rowB) => {
                if (rowA?.submission?.updatedAt > rowB?.submission?.updatedAt) {
                    return 1
                }
                if (rowA?.submission?.updatedAt < rowB?.submission?.updatedAt) {
                    return -1
                }
                return 0
            }
        },
        {
            name: languageUse === 'English' ? 'ESG Maturity Level' : 'Tahap Kematangan ESG',
            selector: (row) => row?.submission?.leval ? row?.submission?.leval : '--',
            sortable: true,
            minWidth: '225px',
            center: true
        },

        {
            name: 'Action',
            selector: (row) => row?.submission ? <button className="btn btn-primary downloadButton" onClick={() => goToDirectDownload(row?.submission?._id)}>Download</button> : '----',
            minWidth: '150px',
            sortable: true,
            sortFunction: (rowA) => {
                if (rowA?.submission) {
                    return 1
                }
                if (!rowA?.submission) {
                    return -1
                }
                return 0
            }
        },

        {
            name: languageUse === 'English' ? 'Documents' : 'Dokumen',
            selector: (row) => row?.submission ? getDocumentsView(row?.submission?.isDraft, row?.submission?._id) : '----',
            minWidth: '150px'

        },
        {
            name: '',
            selector: (row) => row?.submission ? getStatusIcon(row?.submission?.isDraft, row?.submission?._id, row?.submission?.userId) : '---',
            minWidth: '100px'

        },

    ];

    // to disable row selection
    const rowDisabledCriteria = row => !row?.submission;

    useEffect(() => {
        const getData = setTimeout(() => {
            if (searchTerm.length > 0) {
                searchData(searchTerm)
            } else if (selectedIndustry) {
                industryFilter()
            } else {
                getAssociatedCompaniesList()
            }
        }, 1000)

        return () => clearTimeout(getData)
    }, [searchTerm, offsetCal, pageLimit, selectedIndustry, watchPaginationVal]);

    useEffect(() => {
        setCurrentPage(0)
        setoffsetCal(0)
    }, [watchPaginationVal])

    return (
        <>
            {assesmentLoading ? <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
                <Spinner animation="grow" />
            </div> : <>
                <div className="container history-text-color">
                    <div className="d-flex flex-wrap align-items-center flex-wrap">
                        <h2 className="mb-0 me-3">{languageUse === 'English' ? 'Associated Companies' : 'Syarikat Bersekutu'}</h2>
                        <InputGroup className="border rounded-3 w-25 me-3 flex-grow-1">
                            <InputGroup.Text className='bg-transparent border-0'><CiSearch /></InputGroup.Text>
                            <Form.Control
                                className='bg-transparent border-0 rounded-3 px-0 search-input'
                                placeholder='Search'
                                onChange={(e) => {
                                    setSearchTerm(e.target.value)
                                }}
                            />
                        </InputGroup>
                        <div className="me-3">
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: "180px"
                                    })
                                }}
                                placeholder="Select Industry"
                                isClearable
                                options={allIndustry}
                                defaultValue={selectedIndustry}
                                onChange={setIndustry}
                            />
                        </div>


                        {/* {assesmentList?.length > 0 ? <>
                            <button className="btn btn-primary mb-1" onClick={goToAnalytics}>
                                Analytics
                            </button>
                        </> : <></>} */}
                        {(multipleCSVData.length === 0 && !multipleCSVLoading && selectedRows?.length > 0) ? <div className='text-end px-1 me-3'>
                            <button className='btn btn-primary' onClick={() => handleCSVDownloadSelected()}>Export Submission</button>
                        </div> : (multipleCSVData.length > 0 && !multipleCSVLoading && selectedRows?.length > 0) ? <div className='text-end px-1 me-3'>
                            <CSVLink data={multipleCSVData} headers={mutipleCSVHeaders} filename="ESGsubmissionAssociatedComp.csv">
                                <button id='multiple-export-csv' className="btn btn-primary">Export Submission</button>
                            </CSVLink>
                        </div> : multipleCSVLoading ? <div className='text-end p-1 me-3'><Spinner color='primary' /></div> : <></>
                        }

                        {assesmentList?.length > 0 ? <>
                            <button className="btn btn-primary me-3" onClick={goToAdvanceAnalytics}>
                                {languageUse === 'English' ? 'Advanced Analytics' : 'Analisis lanjutan'}
                            </button>
                        </> : <></>}

                        <ExportButton />
                    </div>

                    {assesmentList?.length > 0 ? <>
                        {/* Design for large screen */}
                        {/* <div className="table-responsive">
                            <table className="table border">
                                <thead className="table-head">
                                    <tr className="history-text-color">
                                        <th scope="col">{languageUse === 'English' ? 'No.' : 'Tidak.'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'Company Name' : 'Nama Syarikat'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'Date' : 'Tarikh'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'ESG Maturity Level' : 'Tahap Kematangan ESG'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'Environment Score' : 'Skor Persekitaran'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'Social Score' : 'Skor Sosial'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'Governance Score' : 'Skor Tadbir Urus'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'Action' : 'Action'}</th>
                                        <th scope="col">{languageUse === 'English' ? 'Documents' : 'Dokumen'}</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
              
                                <tbody>
                                    {assesmentList?.length > 0 && assesmentList?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{(currentPage + 1) > 1 ? (((currentPage + 1) - 1) * watchPaginationVal) + index + 1 : index + 1}.</td>
                                            
                                            <td>{item?.user?.company}</td>
                                            <td>{moment(item?.submission?.updatedAt).format("DD/MM/YYYY")}</td>
                                            <td>{item?.submission?.leval}</td>
                                            <td>{item?.submission?.dimensionsScoring[2]?.scorePercent ? item?.submission?.dimensionsScoring[2]?.scorePercent?.toFixed(2) : 0}%</td>
                                            <td>{item?.submission?.dimensionsScoring[0]?.scorePercent ? item?.submission?.dimensionsScoring[0]?.scorePercent?.toFixed(2) : 0}%</td>
                                            <td>{item?.submission?.dimensionsScoring[1]?.scorePercent ? item?.submission?.dimensionsScoring[1]?.scorePercent?.toFixed(2) : 0}%</td>
                                            <td> {item?.submission ? <button className="btn  downloadButton" onClick={() => goToDirectDownload(item?.submission?._id)}>Download</button> : '----'} </td>
                                            <td>{getDocumentsView(item?.submission?.isDraft, item?.submission?._id)}</td>
                                           
                                            <td>{item?.submission ? getStatusIcon(item?.submission?.isDraft, item?.submission?._id, item?.submission?.userId) : '---'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> */}

                        <div className='react-dataTable react-dataTable-selectable-rows'>
                            <DataTable
                                customStyles={{
                                    headCells: {
                                        style: {
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            backgroundColor: '#F4FBFE'
                                        }
                                    },
                                    rows: {
                                        style: {
                                            fontSize: '1rem'
                                        }
                                    }
                                }}
                                noHeader
                                // title="userTable
                                columns={columns}
                                className='react-dataTable mt-3'
                                sortIcon={<ChevronDown size={10} />}
                                data={assesmentList}
                                // pagination
                                paginationServer
                                paginationTotalRows={totalEntry}
                                // paginationPerPage={countPerPage}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                // onChangePage={(page) => {
                                //     setPageCount(page)
                                // }}
                                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                // paginationComponent={CustomPagination}
                                // paginationComponentOptions={CustomPagination}
                                paginationDefaultPage={currentPage + 1}
                                selectableRows
                                onSelectedRowsChange={handleRowSelected}
                                selectableRowDisabled={rowDisabledCriteria}

                            />
                        </div>

                        <div className='d-flex align-items-center justify-content-end w-100 mt-3'>
                            <div className='px-2 fs-12'>
                                <span className='me-1'>{languageUse === 'English' ? 'Show' : 'tunjuk'}</span>
                                <select name="paginationData" id="paginationDataShow" className='rounded-pill border bg-light' {...register("paginationVal")} >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <span className='ms-1'>{languageUse === 'English' ? 'entries' : 'entri'}</span>
                                <span className='ms-2'>{languageUse === 'English' ? 'Showing' : 'menunjukkan'} {currentPage === 0 ? `${1}` : (currentPage * watchPaginationVal) + 1} - {(watchPaginationVal * (currentPage + 1)) > totalEntry ? totalEntry : (watchPaginationVal * (currentPage + 1)) || 10} of {totalEntry || 0} {languageUse === 'English' ? 'entries' : 'entri'}</span>

                            </div>

                            <ReactPaginate
                                // nextLabel=''
                                breakLabel='...'
                                // previousLabel=''
                                pageRangeDisplayed={2}
                                forcePage={currentPage}
                                marginPagesDisplayed={2}
                                activeClassName='active'
                                pageClassName='page-item rounded-circle me-2'
                                breakClassName='page-item rounded-circle me-2'
                                nextLinkClassName='page-link me-2'
                                pageLinkClassName='page-link rounded-circle me-2'
                                breakLinkClassName='page-lin rounded-circle me-2'
                                previousLinkClassName='page-link me-2'
                                nextClassName='page-item next-item me-2'
                                previousClassName='page-item prev-item me-2'
                                pageCount={Math.ceil(totalEntry / (watchPaginationVal || 10)) || 1}
                                // pageCount={totalEntry || 1}
                                onPageChange={page => handlePagination(page)}
                                containerClassName='pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mb-0'
                            // paginationComponent={CustomPagination}
                            />
                        </div>


                    </> : <>
                        <div className='pt-5 ps-0'>
                            <div className='w-100 rounded bgColor-noQ height-noQ d-flex align-items-center justify-content-center'>
                                <p className=''>No Associated Companies Available</p>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </>}

            <UploadedDocumentsModal modal={uploadedDocumentsModal} toggle={UploadedDocumentsModaltoggle} handleShowPdfModalShow={handleShowPdfModalShow} submissionId={submissionId} setPdfItem={setPdfItem} progress={false} />
            <ShowPdfModal show={showPdfModalShow} handleClose={handleShowPdfModalShow} pdfItem={pdfItem} />

            {/* <PdfReportPage2 ref={componentRef} id={"65dc2d810eb6aa2b8f586e55"} pdfContent={pdfContent} data={data} options={options} dimensionData={dimensionData} indicatorData={indicatorData} selectedIndicatorData={selectedIndicatorData} profileData={profileData} ESGperformanceData={ESGperformanceData} optionalQuesData={optionalQuesData} requiredQuestions={requiredQuestions} overallVal={overallVal} overallValLebel={overallValLebel} horoizontalOptions={horoizontalOptions} horizontalData={horizontalData} uploadedDocInfo={uploadedDocInfo} lastSubmission={lastSubmission} /> */}

        </>
    )
};

export default AssociatedCompanies