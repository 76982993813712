import React, { useState, useEffect } from 'react'

//third party
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend, ChartDataLabels);

// ** Reactstrap Imports
import {
    Row,
    Col,
    CardTitle,
    CardText,
    Form,
    Label,
    Input,
    Button
} from "reactstrap"
import toast, { Toaster } from 'react-hot-toast'
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

const Selection = (props) => {

    const [countryValue, setCountryValue] = useState(null);

    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();

    const watchSelect = watch(props.name)

    useEffect(() => {
        if (watchSelect) {
            props.setWatchValue(watchSelect)
        } else {
            props.setWatchValue()
        }
    }, [watchSelect])


    return (
        <>
            <div className='d-flex align-items-center mt-2 mb-2'>
                <label htmlFor="Location" className="form-label">
                    {props.title}&nbsp;
                </label>
                <Controller
                    name={props.name}
                    control={props?.control || control}
                    render={({ field }) => (
                        <Select
                            isClearable
                            className={props.className}
                            styles={props?.styles}
                            options={props.options}
                            {...field}
                        />
                    )}
                    rules={{ required: true }}
                />

                {errors.country && <div>Field is rquired</div>}

            </div>
        </>
    )
};

export default Selection