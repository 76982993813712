import React from 'react'

//third party
import {
    Row,
    Col,
    Container,
    Label,
    Spinner,
    Toast,
    Input,
    CardBody,
    CardTitle,
    CardHeader,
    FormFeedback,
} from 'reactstrap'

const TermsAndCondition = () => {
    return (
        <Container>
            <p>
                Welcome to the Assessment Tool ("the Tool") provided by the UN Global Compact Network Malaysia & Brunei ("UNGCMYB"). By accessing and using Assessment Tool, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, please do not use Assessment Tool.
            </p>
            

            {/* <div>
                <ul className=''>
                    <li>
                        <h5 className='text-primary'>
                            Ownership and License:
                        </h5>
                        <ol>
                            <li>Coffee</li>
                            <li>Tea</li>
                            <li>Milk</li>
                        </ol>

                    </li>

                    <li>
                        <h5 className='text-primary'>
                            Data Collection and Privacy:
                        </h5>
                        <ol>
                            <li>Coffee</li>
                            <li>Tea</li>
                            <li>Milk</li>
                        </ol>

                    </li>

                </ul>
                
            </div> */}

            <div>
                <h5 className='text-primary'>
                    Ownership and License:
                </h5>
                <p className='ms-3'>
                    1.1 The Assessment Tool, including all associated intellectual property rights, is owned and operated by UNGCMYB.
                </p>
                <p className='ms-3'>
                    1.2 UNGCMYB grants you a limited, non-exclusive, non-transferable license to access and use Assessment Tool for the purpose of completing the assessment and evaluating your level of commitment, maturity, and actions taken for the United Nations Sustainable Development Goals (SDGs).
                </p>
                <p className='ms-3'>
                    1.3 You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from Assessment Tool without the prior written consent of UNGCMYB.
                </p>
                
            </div>

            <div>
                <h5 className='text-primary'>
                    Data Collection and Privacy:
                </h5>
                <p className='ms-3'>
                    2.1 By using the Assessment Tool, you acknowledge and agree that the information you provide will be collected, stored, and processed by UNGCMYB for the purpose of analyzing and evaluating your assessment responses.
                </p>
                <p className='ms-3'>
                    2.2 UNGCMYB may also share the collected data with third parties, provided that such sharing is in compliance with applicable data protection laws and regulations.
                </p>
                <p className='ms-3'>
                    2.3  UNGCMYB will take reasonable measures designed to maintain the confidentiality and security of data acquired. Notwithstanding these efforts, by accessing and/or using the Assessment Tool you are deemed to accept that the transmission of data over the internet or any form of electronic storage system is not completely invulnerable to breach or security compromise.  Consequently UNGCMYB shall bear no liability, whether direct, indirect, special, consequential, punitive or incidental, arising out of or in relation to any unauthorised access, disclosure, alteration, loss, damage or misuse of data.
                </p>
                
            </div>

            <div>
                <h5 className='text-primary'>
                    Accuracy of Information:
                </h5>
                <p className='ms-3'>
                    3.1 You are responsible for providing accurate and truthful information in the assessment. UNGCMYB shall not be liable for any inaccuracies or consequences resulting from the use of inaccurate information provided by you.
                </p>
                <p className='ms-3'>
                    3.2 You acknowledge that the assessment results are based on the information you provide and may not reflect the actual commitment, maturity, or actions taken by your organization.
                </p>
            </div>

            <div>
                <h5 className='text-primary'>
                    Disclaimer of Warranties:
                </h5>
                <p className='ms-3'>
                    4.1 The Assessment Tool is provided on an "as-is" and "as-available" basis, without any warranties or representations, express or implied.
                </p>
                <p className='ms-3'>
                    4.2 UNGCMYB does not warrant that Assessment Tool will be error-free, uninterrupted, or free from viruses or other harmful components.
                </p>
                <p className='ms-3'>
                    4.3 UNGCMYB disclaims any and all warranties, including but not limited to the accuracy, reliability, completeness, merchantability, or fitness for a particular purpose of Assessment Tool.
                </p>
            </div>

            <div>
                <h5 className='text-primary'>
                    Limitation of Liability:
                </h5>
                <p className='ms-3'>
                    5.1 To the maximum extent permitted by applicable law, UNGCMYB shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the use or inability to use the Assessment Tool.
                </p>
                <p className='ms-3'>
                    5.2 You agree to indemnify and hold UNGCMYB harmless from any claims, losses, damages, liabilities, and expenses (including attorneys' fees) arising out of or in connection with your use of Assessment Tool or any violation of these Terms and Conditions.
                </p>
                <p className='ms-3'>
                    5.3. UNGCMYB is under no obligation to indemnify, compensate, make good or reimburse any party for any tangible or intangible losses, damages or harms suffered resulting directly or indirectly from usage of the Assessment Tool.
                </p>
            </div>

            <div>
                <h5 className='text-primary'>
                    Modification and Termination:
                </h5>
                <p className='ms-3'>
                    6.1 UNGCMYB reserves the right to modify or discontinue the Assessment Tool, either temporarily or permanently, with or without notice.
                </p>
                <p className='ms-3'>
                    6.2 UNGCMYB may also modify these Terms and Conditions at any time. Your continued use of Assessment Tool after any modifications shall constitute your acceptance of the modified Terms and Conditions.
                </p>
            </div>

            <div>
                <h5 className='text-primary'>
                    Governing Law and Jurisdiction:
                </h5>
                <p className='ms-3'>
                    7.1 These Terms and Conditions shall be governed by and construed in accordance with the laws of Malaysia.
                </p>
                <p className='ms-3'>
                    7.2 Any disputes arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of Malaysia.
                </p>
            </div>

            <div>
                <p className='fw-bold'>
                    By using the Assessment Tool, you agree to these Terms and Conditions. If you do not agree, please refrain from using Assessment Tool.
                </p>
            </div>
        </Container>
    )
};

export default TermsAndCondition