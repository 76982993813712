import React from 'react'
import { Bar } from "react-chartjs-2";

import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,

    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,

} from "chart.js";
ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ChartDataLabels,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);
const HorizontalBarChartStacked = (props) => {
    return (
        <>
            {props?.data &&
                <Bar data={props?.data} options={props?.options}></Bar>
            }
        </>
    )
};

export default HorizontalBarChartStacked