import { Nav } from 'rsuite';

const MaturityLevelsTabs = (props) => {
    return <>
        <Nav className='d-flex flex-wrap' activeKey={props?.activeKey} onSelect={(eventKey, event) => {
            props?.setActiveKey(eventKey)
        }}>
            <Nav.Item eventKey="1">
                Maturity Level
            </Nav.Item>
            <Nav.Item eventKey="2">Average Climate Action Maturity Scores Across Companies</Nav.Item>
        </Nav>
    </>
};

export default MaturityLevelsTabs